import { useCallback, useMemo, useState } from "react";
import { CommonDialog } from "./CommonDialog";
import { Typography } from "@mui/material";
import { format, parseISO } from "date-fns";
import { useGetExcretionResultQuery } from "src/store/helppadApi";
import {
  ExcretionSupportType,
  isFecesCategory,
  isUrineCategory,
  toExcretionSupportTypeName,
} from "src/types/ExcretionSupportType";
import { Amount, AmountType, parseAmountNumber, toAmountName } from "src/types/amount";
import {
  FecesFirmness,
  FecesFirmnessType,
  parseFecesFirmnessNumber,
  toFecesFirmnessName,
} from "src/types/fecesFirmness";
import { SpDataTable } from "./parts/SpDataTable";
import {
  ExcretionSupportedPlace,
  ExcretionSupportedPlaceTypes,
  parseExcretionSupportedPlaceTypeNumber,
  toExcretionSupportedPlaceTypeName,
} from "src/types/excretionSupportedPlaceType";
import { KarteTextare } from "./parts/KarteTextarea";
import { useTranslation } from "react-i18next";
import { FetchError } from "src/types/FetchError";

type Props = {
  open: boolean;
  processing?: boolean; // 処理中か
  onCancel: () => void;
  description?: string;
  careSubjectName: string; // 入居者名
  excretionSupportType: ExcretionSupportType | undefined; // 対応内容
  isLeaked: boolean | undefined; // 漏れの有無
  urineAmount: AmountType | undefined; // 尿量
  fecesAmount: AmountType | undefined; // 便量
  fecesFirmness: FecesFirmnessType | undefined; // 便の性状
  supportedPlace: ExcretionSupportedPlace | undefined; // 対応方法
  karte: string | undefined; // メモ
  supportedAt: Date | undefined; // 対応日時
  supportStaffName: string | undefined; // 対応スタッフ名
};

export const useConfirmExcretionSupportDialog = () => {
  const [open, setOpen] = useState(false);
  const showDialog = useCallback(() => setOpen(true), []);
  const hideDialog = useCallback(() => setOpen(false), []);

  return {
    open,
    showDialog,
    hideDialog,
  };
};

const DataTableDialog = (
  props: Props & {
    title: string;
    cancelButtonName?: string;
    acceptButtonName?: string;
    error?: FetchError;
    onAccept?: () => void;
  }
) => {
  const { t } = useTranslation();
  const { error } = props;

  const alertMessages = useMemo(() => {
    const messages: string[] = [];

    if (error == null) {
      return messages;
    }

    if ("status" in error && error.status === 404) {
      messages.push(t("module.excretion_support.already_deleted"));
    } else {
      messages.push(t("module.excretion_support.error_occured"));
    }

    return messages;
  }, [error, t]);

  return (
    <CommonDialog
      dialogOpen={props.open}
      title={props.title}
      acceptButtonName={props.acceptButtonName}
      onAccept={props.onAccept}
      cancelButtonName={props.cancelButtonName ?? t("common.button.cancel")}
      onCancel={props.onCancel}
      isProcessed={props.processing === true}
      isChecked={true}
      isCompleted={false}
      alertMessages={alertMessages}
    >
      {props.description != null && <Typography pb={2}>{props.description}</Typography>}
      {props.error == null && (
        <DataTable
          careSubjectName={props.careSubjectName}
          supportType={props.excretionSupportType}
          isLeaked={props.isLeaked}
          urineAmount={props.urineAmount}
          fecesAmount={props.fecesAmount}
          fecesFirmness={props.fecesFirmness}
          supportedPlace={props.supportedPlace}
          karte={props.karte}
          supportedAt={props.supportedAt}
          supportStaffName={props.supportStaffName}
        />
      )}
    </CommonDialog>
  );
};

/**
 * 対応登録確認ダイアログ
 *
 * 今のところSP表示時のみ出番あり
 */
export const ConfirmRegisterExcretionSupportDialog = (
  props: Props & { processing: boolean; onRegister: () => void }
) => {
  const { t } = useTranslation();

  return (
    <DataTableDialog
      open={props.open}
      processing={props.processing}
      onCancel={props.onCancel}
      title={t("module.excretion_support.confirm_registration")}
      acceptButtonName={t("common.button.register_excretion_result_sp")}
      onAccept={props.onRegister}
      careSubjectName={props.careSubjectName}
      excretionSupportType={props.excretionSupportType}
      isLeaked={props.isLeaked}
      urineAmount={props.urineAmount}
      fecesAmount={props.fecesAmount}
      fecesFirmness={props.fecesFirmness}
      supportedPlace={props.supportedPlace}
      karte={props.karte}
      supportedAt={props.supportedAt}
      supportStaffName={props.supportStaffName}
    />
  );
};

/**
 * 対応登録内容の確認ダイアログ
 *
 * 今のところSP表示時のみ出番あり
 */
export const CheckExcretionSupportDialog = (props: {
  open: boolean;
  onClose: () => void;
  careSubjectName: string; // 入居者名
  excretionSupportId: number; // 対応登録ID(entity_id)
}) => {
  const { t } = useTranslation();
  const { result, error } = useExcretionResult(props.excretionSupportId);

  const {
    supportType,
    isLeaked,
    urineAmount,
    fecesAmount,
    fecesFirmness,
    supportedPlace,
    karte,
    supportedAt,
    supportStaffName,
  } = result;

  return (
    <DataTableDialog
      open={props.open}
      error={error}
      processing={false}
      onCancel={props.onClose}
      title={t("module.excretion_support.confirm_title")}
      cancelButtonName={t("common.button.close")}
      careSubjectName={props.careSubjectName}
      excretionSupportType={supportType}
      isLeaked={isLeaked}
      urineAmount={urineAmount}
      fecesAmount={fecesAmount}
      fecesFirmness={fecesFirmness}
      supportedPlace={supportedPlace}
      karte={karte}
      supportedAt={supportedAt}
      supportStaffName={supportStaffName}
    />
  );
};

/**
 * 対応登録の削除確認ダイアログ
 *
 * 今のところSP表示時のみ出番あり
 */
export const ConfirmDeleteExcretionSupportDialog = (props: {
  open: boolean;
  processing: boolean;
  onDelete: () => void;
  onClose: () => void;
  careSubjectName: string; // 入居者名
  excretionSupportId: number; // 対応登録ID(entity_id)
}) => {
  const { t } = useTranslation();
  const { result, error } = useExcretionResult(props.excretionSupportId);

  const {
    supportType,
    isLeaked,
    urineAmount,
    fecesAmount,
    fecesFirmness,
    supportedPlace,
    karte,
    supportedAt,
    supportStaffName,
  } = result;

  return (
    <DataTableDialog
      open={props.open}
      error={error}
      processing={props.processing}
      onCancel={props.onClose}
      title={t("module.excretion_support.confirm_deletion_title")}
      description={t("module.excretion_support.warning_deletion")}
      acceptButtonName={t("common.button.delete")}
      onAccept={props.onDelete}
      careSubjectName={props.careSubjectName}
      excretionSupportType={supportType}
      isLeaked={isLeaked}
      urineAmount={urineAmount}
      fecesAmount={fecesAmount}
      fecesFirmness={fecesFirmness}
      supportedPlace={supportedPlace}
      karte={karte}
      supportedAt={supportedAt}
      supportStaffName={supportStaffName}
    />
  );
};

const useExcretionResult = (
  excretionSupportId: number
): {
  result: {
    supportType?: ExcretionSupportType | undefined;
    isLeaked?: boolean | undefined;
    urineAmount?: AmountType | undefined;
    fecesAmount?: AmountType | undefined;
    fecesFirmness?: FecesFirmnessType | undefined;
    supportedPlace?: ExcretionSupportedPlace | undefined;
    karte?: string | undefined;
    supportedAt?: Date | undefined;
    supportStaffName?: string | undefined;
  };
  error?: FetchError;
} => {
  const excretionResult = useGetExcretionResultQuery({ id: excretionSupportId });
  const { data, error } = excretionResult;

  const result = useMemo(() => {
    if (data == null) {
      return {};
    }
    const supportType = data.excretion_type as ExcretionSupportType;
    const isLeaked = data.is_leaked;
    const urineAmount = parseAmountNumber(data.urine_amount);
    const fecesAmount = parseAmountNumber(data.feces_amount);
    const fecesFirmness = parseFecesFirmnessNumber(data.feces_firmness);
    const supportedPlace = parseExcretionSupportedPlaceTypeNumber(data.supported_place);
    const karte = data.karte;
    const supportedAt = parseISO(data.supported_at as string);
    const supportStaffName = data.input_person as string;
    return {
      supportType,
      isLeaked,
      urineAmount,
      fecesAmount,
      fecesFirmness,
      supportedPlace,
      karte,
      supportedAt,
      supportStaffName,
    };
  }, [data]);

  return useMemo(() => ({ result, error }), [result, error]);
};

const DataTable = ({
  careSubjectName,
  supportType,
  isLeaked,
  urineAmount,
  fecesAmount,
  fecesFirmness,
  supportedPlace,
  karte,
  supportedAt,
  supportStaffName,
}: {
  careSubjectName: string;
  supportType: ExcretionSupportType | undefined;
  isLeaked: boolean | undefined;
  urineAmount: AmountType | undefined; // 尿量
  fecesAmount: AmountType | undefined; // 便量
  fecesFirmness: FecesFirmnessType | undefined; // 便の性状
  supportedPlace: ExcretionSupportedPlace | undefined; // 対応方法
  karte: string | undefined; // メモ
  supportedAt: Date | undefined;
  supportStaffName: string | undefined;
}) => {
  const { t } = useTranslation();

  return (
    <SpDataTable>
      <SpDataTable.Row title={t("common.care_subject.target_care_subject")} value={careSubjectName} />
      <SpDataTable.Row
        title={t("module.excretion_support.support_type")}
        value={toExcretionSupportTypeName(supportType, t)}
      />
      <SpDataTable.Row
        title={t("common.excretion.leaked")}
        value={
          isLeaked === true
            ? t("module.excretion_support.with_leaked")
            : isLeaked === false
            ? t("module.excretion_support.no_leaked")
            : ""
        }
      />
      {isUrineCategory(supportType) && (
        <SpDataTable.Row
          title={t("module.excretion_support.urine_amount")}
          value={toAmountName(urineAmount, t)}
          isNone={urineAmount === Amount.None || urineAmount == null}
        />
      )}
      {isFecesCategory(supportType) && (
        <>
          <SpDataTable.Row
            title={t("module.excretion_support.feces_amount")}
            value={toAmountName(fecesAmount, t)}
            isNone={fecesAmount === Amount.None || fecesAmount == null}
          />
          <SpDataTable.Row
            title={t("module.excretion_support.feces_firmness")}
            value={toFecesFirmnessName(fecesFirmness, t)}
            isNone={fecesFirmness === FecesFirmness.None || fecesFirmness == null}
          />
        </>
      )}
      {supportedPlace != null && supportedPlace !== ExcretionSupportedPlaceTypes.None && (
        <SpDataTable.Row
          title={t("module.excretion_support.support_method")}
          value={toExcretionSupportedPlaceTypeName(supportedPlace, t)}
        />
      )}
      {karte != null && karte.length > 0 && (
        <SpDataTable.Row
          title={t("module.excretion_support.memo")}
          value={<KarteTextare disabled={true} row={3} value={karte} showCounter={false} />}
        />
      )}
      <SpDataTable.Row
        title={t("module.excretion_support.support_date")}
        value={supportedAt != null ? format(supportedAt, "yyyy/MM/dd HH:mm") : undefined}
      />
      <SpDataTable.Row title={t("module.excretion_support.support_input_person")} value={supportStaffName} />
    </SpDataTable>
  );
};
