import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";

export function initializeI18n() {
  i18n
    .use(HttpBackend)
    .use(initReactI18next)
    .init({
      fallbackLng: "ja", // 言語ファイルが見つからないときの採用言語
      lng: localStorage.getItem("lang") ?? "ja", // 初期設定言語
      backend: {
        loadPath: "/locales/{{lng}}.json",
        customHeaders: {
          "Cache-Control": "no-cache",
          Pragma: "no-cache",
          Expires: "0",
        },
      },
      react: {
        useSuspense: true,
      },
    });
}
