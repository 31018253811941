import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { usePageMode } from "src/utils/usePageMode";

type Props = {
  activeAlertLogDetail?: ActiveAlertLogDetail;
};

const ActiveAlert = {
  1: "common.notification.notice",
  2: "common.notification.alert",
} as const;

const BackgroundColor = {
  1: "linear-gradient(180deg, rgba(255, 250, 137, 0.8) 0%, rgba(255, 253, 209, 0.8) 100%)",
  2: "linear-gradient(180deg, rgba(255, 190, 198, 0.8) 0%, rgba(255, 121, 137, 0.16) 100%)",
} as const;

/**
 * 1: 注意報
 * 2: 通知
 */
export type ActiveAlertLogDetail = 1 | 2;

export const Notification = ({ activeAlertLogDetail }: Props) => {
  const { t } = useTranslation();
  const { isDesktop } = usePageMode();

  const detail = useMemo(
    () => (activeAlertLogDetail ? ActiveAlert[activeAlertLogDetail] : undefined),
    [activeAlertLogDetail]
  );

  return isDesktop ? (
    // デスクトップ用
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: activeAlertLogDetail ? "inherit" : "#989696",
        background: activeAlertLogDetail ? BackgroundColor[activeAlertLogDetail] : "#f9f9f9",
        border: "1px solid #e6e6e6",
        borderRadius: 2,
        width: "100%",
        height: 80,
        boxSizing: "border-box",
        px: 4,
      }}
    >
      <Typography
        sx={{
          fontSize: 32,
          fontWeight: "bold",
          whiteSpace: "nowrap",
          flexShrink: 0,
        }}
      >
        {t(detail ?? "care_subject.notification.none")}
      </Typography>
    </Box>
  ) : // SP用
  detail != null ? (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      maxHeight={"40px"}
      height={"40px"}
      color={activeAlertLogDetail ? "#222222" : "#989696"}
      borderBottom={"1px solid #e6e6e6"}
      sx={{
        background: activeAlertLogDetail ? BackgroundColor[activeAlertLogDetail] : "#f9f9f9",
      }}
    >
      <Typography fontWeight={700} whiteSpace={"nowrap"}>
        {t(detail)}
      </Typography>
      {activeAlertLogDetail === 2 && (
        <Typography ml={1} fontSize={"14px"} whiteSpace={"nowrap"}>
          {t("care_subject.notification.immediately")}
        </Typography>
      )}
    </Box>
  ) : null;
};
