import { Theme } from "@emotion/react";
import { Grid, SxProps } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IntegrationAppDataType, toDataTypeName } from "src/types/integrationAppDataType";
import { IntegrationAppSystemType, toSystemTypeName } from "src/types/integrationAppSystemType";

type Props = {
  name: string;
  systemType?: IntegrationAppSystemType;
  dataType: IntegrationAppDataType;
  webhooksName?: string;
  webhooksKey?: string;
};

/**
 * 連携機能情報表示
 */
export const IntegrationAppDataTable = ({ name, systemType, dataType, webhooksName, webhooksKey }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Data label={t("module.integration_app.registration_name")} value={name} />
      <Data
        label={t("module.integration_app.integration_target")}
        value={toSystemTypeName(systemType)}
        sx={{ mt: 2 }}
      />
      <Data
        label={t("module.integration_app.integration_information")}
        value={toDataTypeName(dataType, t)}
        sx={{ mt: 2 }}
      />
      {webhooksName != null && (
        <Data label={t("module.integration_app.webhooks_name")} value={webhooksName} sx={{ mt: 2 }} />
      )}
      {webhooksKey != null && (
        <Data label={t("module.integration_app.webhooks_key")} value={webhooksKey} sx={{ mt: 2 }} />
      )}
    </>
  );
};

const Data = ({ label, value, sx }: { label: string; value: string; sx?: SxProps<Theme> }) => {
  return (
    <Grid sx={sx} container>
      <Grid item textAlign={"right"} xs={4}>
        {label}：
      </Grid>
      <Grid item pl={2} xs={8}>
        {value}
      </Grid>
    </Grid>
  );
};
