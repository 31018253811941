import { Box, MenuItem, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { Fragment, memo, useCallback, useMemo } from "react";
import { Constants } from "src/constants/commonConstants";
import { convertISOToJST, checkRelativeDate } from "src/utils/dateUtil";
import { TableRowMoreDropdown } from "src/modules/table/parts/TableRowMoreDropdown";
import { useGlobalProps } from "src/store/GlobalProps";
import { ModelsHistoryLog } from "src/store/helppadApi";
import { usePageMode } from "src/utils/usePageMode";
import { formatLogDate } from "..";
import { ReadMoreButton } from "./ReadMoreButton";
import { ExcretionModal, useConfirmOrEditExcretionModal } from "src/modules/dialog/ExcretionModal";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { useLocale } from "src/utils/localeMap";

type Props = {
  careSubjectName: string | undefined;
  logs: ModelsHistoryLog[];
  showReadMoreButton?: boolean;
  isReadingMore?: boolean;
  onClickReadMore?: () => void;
  onChangedExcretion?: () => Promise<void>;
  deleteHistoryLog: (args: { id?: number }) => void;
};

export const HistoryLogs = memo(
  ({
    careSubjectName,
    logs,
    showReadMoreButton,
    isReadingMore,
    onClickReadMore,
    onChangedExcretion,
    deleteHistoryLog,
  }: Props) => {
    const { t } = useTranslation();
    const locale = useLocale();

    const { isDesktop } = usePageMode();
    const { staffId } = useGlobalProps();
    const {
      showConfirm: showConfirmExcretionModal,
      showEdit: showEditExcretionModal,
      ...confirmOrEditExcretionModalStates
    } = useConfirmOrEditExcretionModal(onChangedExcretion, deleteHistoryLog);

    const handleClickConfirm = useCallback(
      (log: ModelsHistoryLog) => {
        log.id != null && log.entity_id != null && showConfirmExcretionModal(log.id, log.entity_id);
      },
      [showConfirmExcretionModal]
    );

    const handleClickEdit = useCallback(
      (log: ModelsHistoryLog) => {
        log.id != null && log.entity_id != null && showEditExcretionModal(log.id, log.entity_id);
      },
      [showEditExcretionModal]
    );

    return (
      <Fragment>
        {isDesktop ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              boxSizing: "border-box",
              position: "relative",
              width: "100%",
              border: "1px solid #e6e6e6",
              borderRadius: "9px",
              backgroundColor: "#FFFFFF",
            }}
          >
            <Header>{t("care_subject.history_logs.history")}</Header>
            <Divider />
            <Container>
              {logs.map((log, index) => {
                const logMessage = buildHistoryLogMessage(log, t);
                return logMessage == null ? null : (
                  <PcHistoryLog
                    key={`History-${index}`}
                    log={log}
                    message={logMessage}
                    onClickConfirm={handleClickConfirm}
                    onClickEdit={handleClickEdit}
                  />
                );
              })}
              {/* さらに読み込むボタン */}
              {showReadMoreButton && (
                <ReadMoreButton disabled={isReadingMore} loading={isReadingMore} onClick={onClickReadMore} />
              )}
            </Container>
            <ExcretionModal careSubjectName={careSubjectName} {...confirmOrEditExcretionModalStates} />
          </Box>
        ) : (
          /* SP版 通知・履歴表示 */
          <Box sx={{ background: "#FFF", borderRadius: "0.5rem" }}>
            {logs.map((log, index) => {
              const logMessage = buildHistoryLogMessage(log, t);
              if (logMessage === null) {
                return null;
              }

              // 全コンポーネントでパースするとコストが高いので必要なとき(現時点では編集履歴のとき)だけ changes をパース
              // TODO: 多言語対応でコンフリクトが予想されるのでやや暫定対応(多言語対応時に以下をコンポーネントとして切り出す予定)
              const parsedChanges =
                log.table_name === "excretion_results" && log.action === "update" && log.changes != null
                  ? JSON.parse(log.changes)
                  : undefined;

              return (
                // 履歴1件分ごとのBox
                <Box
                  display="flex"
                  key={`History-${index}`}
                  sx={{
                    ":not(:last-child)": { borderBottom: "1px solid #E6E6E6" },
                    ":hover": { background: "#F9F9F9" },
                  }}
                  p={2}
                  color="#222222"
                >
                  {/* 通知内容のBox */}
                  <Box
                    sx={{
                      width: "90%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "inline-flex",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          fontWeight: 700,
                          alignSelf: "center",
                          color: log.input_person ? (log.staff_id === staffId ? "#F29500" : "#404040") : "#0E1C73",
                        }}
                      >
                        {log.input_person ? log.input_person : t("care_subject.history_logs.alert")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: 12,
                          ml: 1,
                          alignSelf: "center",
                        }}
                      >
                        {parsedChanges == null
                          ? formatLogDate(log.created_at!, locale)
                          : t("care_subject.history_logs.edited_at_and_registered_at", {
                              date1: formatLogDate(log.created_at!, locale),
                              date2: formatLogDate(parsedChanges.created_at, locale, { short: true }),
                              interpolation: { escapeValue: false },
                            })}
                      </Typography>
                    </Box>
                    <br />
                    <Box
                      sx={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: 14,
                          whiteSpace: "pre-wrap",
                          color: log.input_person ? "#404040" : "#0E1C73",
                        }}
                      >
                        {logMessage}
                      </Typography>
                    </Box>
                  </Box>
                  {/* 右端のボタンのBox */}
                  {/* SP版ではひとまず非表示 */}
                  {/* <Box
                  sx={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    width: "10%",
                  }}
                >
                  {staffId === log.staff_id && "excretion_results" === log.table_name ? (
                    <IconButton>
                      <MoreVertIcon />
                    </IconButton>
                  ) : null}
                </Box> */}
                </Box>
              );
            })}
          </Box>
        )}
      </Fragment>
    );
  }
);

/**
 * [PC] 通知・設定履歴
 */
const PcHistoryLog = ({
  log,
  message,
  onClickConfirm,
}: {
  log: ModelsHistoryLog;
  message: string;
  onClickConfirm: (log: ModelsHistoryLog) => void;
  onClickEdit: (log: ModelsHistoryLog) => void;
}) => {
  const { t } = useTranslation();
  const locale = useLocale();
  const { table_name, action, changes } = log;

  const handleClickConfirm = useCallback(() => {
    onClickConfirm(log);
  }, [onClickConfirm, log]);

  /*
  編集に関する要件がまとまるまでコメントアウト
  const handleClickEdit = useCallback(() => {
    onClickEdit(log);
  }, [onClickEdit, log]);
  */

  // 全コンポーネントでパースするとコストが高いので必要なとき(現時点では編集履歴のとき)だけ changes をパース
  const parsedChanges = useMemo(() => {
    if (table_name !== "excretion_results" || action !== "update" || changes == null) {
      return;
    }
    return JSON.parse(changes);
  }, [table_name, action, changes]);

  return (
    <Box display="flex" sx={{ color: log.input_person ? "#404040" : "#0E1C73" }}>
      <Typography
        sx={{
          boxSizing: "border-box",
          p: 1,
          width: "100px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          flexShrink: 0,
        }}
      >
        {log.input_person ? log.input_person : t("care_subject.history_logs.alert")}
      </Typography>
      <Box sx={{ p: 1, pl: 1.5, flex: 1, wordBreak: "break-all" }}>
        <Box
          sx={{
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "space-between",
            flexDirection: "row",
            width: "100%",
          }}
        >
          {/* parsedChanges がある = 編集履歴(編集日時と元データの登録日時を表示) */}
          {/* parsedChanges がない = 登録・通知履歴(元データの登録日時のみ表示) */}
          {parsedChanges != null ? (
            <Box>
              <Typography sx={{ fontSize: 14, lineHeight: 1.1 }}>
                {t("care_subject.history_logs.edited_at", {
                  date: formatLogDate(log.created_at!, locale),
                  interpolation: { escapeValue: false },
                })}
              </Typography>
              <Typography sx={{ fontSize: 14, lineHeight: 1.1 }}>
                {t("care_subject.history_logs.base_log_registered_at", {
                  date: formatLogDate(parsedChanges.created_at, locale),
                  interpolation: { escapeValue: false },
                })}
              </Typography>
            </Box>
          ) : (
            <Typography sx={{ fontSize: 14, whiteSpace: "nowrap" }}>
              {t("care_subject.history_logs.registered_at", {
                date: formatLogDate(log.created_at!, locale),
                interpolation: { escapeValue: false },
              })}
            </Typography>
          )}
          {log.table_name === "excretion_results" ? (
            <TableRowMoreDropdown IconButtonProps={{ size: "small" }}>
              <MenuItem onClick={handleClickConfirm}>{t("care_subject.history_logs.check_support")}</MenuItem>
              {/* 編集に関する要件がまとまるまで導線を隠す */}
              {/* <MenuItem onClick={handleClickEdit}>{t("care_subject.history_logs.edit_support")}</MenuItem> */}
            </TableRowMoreDropdown>
          ) : null}
        </Box>
        <Typography sx={{ fontSize: 16, whiteSpace: "pre-wrap" }}>{message}</Typography>
      </Box>
    </Box>
  );
};

export const buildHistoryLogMessage = (log: ModelsHistoryLog, t: TFunction) => {
  const changes = JSON.parse(log.changes!);
  if (log.table_name === "excretion_results" && log.action === "create") {
    const excretionType = JSON.parse(log.changes!).excretion_type;
    const isoSupportedAt = JSON.parse(log.changes!).supported_at;
    const supportedAt = convertISOToJST(isoSupportedAt);
    const formatSupportedAt = checkRelativeDate(supportedAt);
    const isAlert = t(
      changes["first_alerted_at"] === null
        ? "care_subject.history_logs.no_notification"
        : "care_subject.history_logs.with_notification"
    );
    let excretionTypeName = toExcretionTypeName(excretionType, t);
    return t("care_subject.history_logs.support_history_format", {
      isAlert,
      excretionTypeName,
      formatSupportedAt,
      interpolation: { escapeValue: false },
    });
  }

  if (log.table_name === "excretion_results" && log.action === "update") {
    // 編集履歴として扱うためには元となった対応登録の作成日時が必要
    // ただし、対応登録の作成日時はここ(メッセージ生成)では使わない(上部に「いつ編集したか」の表示に用いる)
    if (changes.created_at == null) {
      return null;
    }

    const formatSupportedAt = checkRelativeDate(convertISOToJST(changes.supported_at));
    const excretionTypeName = toExcretionTypeName(changes.excretion_type, t);
    const isAlert = t(
      changes["first_alerted_at"] === null
        ? "care_subject.history_logs.no_notification"
        : "care_subject.history_logs.with_notification"
    );

    return t("care_subject.history_logs.support_history_format", {
      isAlert,
      excretionTypeName,
      formatSupportedAt,
      interpolation: { escapeValue: false },
    });
  }

  if (log.table_name === "care_subjects" && log.action === "update") {
    try {
      if (log.staff_id === 0) {
        if (changes["active_alert_logs.detail"] === 1) {
          return t("common.notification.notice");
        }
        if (changes["active_alert_logs.detail"] === 2) {
          return t("common.notification.alert");
        }
      } else {
        if (
          changes.feces_alert_threshold ||
          changes.urine_alert_threshold ||
          changes.feces_caution_threshold ||
          changes.urine_caution_threshold
        ) {
          const fecesAlertThreshold =
            changes.feces_alert_threshold === Constants.OFF_THRESHOLD ? "OFF" : changes.feces_alert_threshold;
          const urineAlertThreshold =
            changes.urine_alert_threshold === Constants.OFF_THRESHOLD ? "OFF" : changes.urine_alert_threshold;
          const fecesCautionThreshold =
            changes.feces_caution_threshold === Constants.OFF_THRESHOLD ? "OFF" : changes.feces_caution_threshold;
          const urineCautionThreshold =
            changes.urine_caution_threshold === Constants.OFF_THRESHOLD ? "OFF" : changes.urine_caution_threshold;
          return t("care_subject.history_logs.setup_threshold_format", {
            urineAlertThreshold,
            fecesAlertThreshold,
            urineCautionThreshold,
            fecesCautionThreshold,
          });
        } else {
          return t("module.care_subject.edit_care_subject");
        }
      }
    } catch (e) {
      console.error(e);
    }
    return null;
  }
  console.error(`invalid log : ${JSON.stringify(log)}`);
};

export const toExcretionTypeName = (excretionType: number, t: TFunction) => {
  switch (excretionType) {
    case 1:
      return t("common.excretion.urine");
    case 2:
      return t("common.excretion.feces");
    case 3:
      return t("common.excretion.urine_and_feces");
    default:
      return t("common.excretion.none");
  }
};

const Header = styled("div")`
  border-radius: 4px 4px 0 0;
  background-color: #e6e6e6;
  padding: 8px 0;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
`;

const Divider = styled("div")`
  &:after {
    background-color: red;
    content: "";
    position: absolute;
    height: calc(100% - 64px);
    left: 100px;
    border-right: 1px solid #e6e6e6;
  }
`;

const Container = styled("div")`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 732px;
`;
