import { Dispatch } from "redux";
import { Constants } from "src/constants/commonConstants";
import { openAnnouncementModal } from "src/store/GlobalProps";
import { readNumberLocalStorage } from "src/utils/localStorage";
import axios from "axios";

/**
 * バージョンチェック用関数
 */
export const checkVersion = (dispatch: Dispatch, announcementFilePath: string) => {
  const currentAnnouncementId = readNumberLocalStorage(
    Constants.CURRENT_ANNOUNCEMENT_ID_KEY,
    process.env.REACT_APP_HELPPAD2_ANNOUNCEMENT_ID
  );
  return new Promise((resolve, reject) => {
    const hash = new Date().getTime();
    axios
      .get(`${announcementFilePath}?_=${hash}`)
      .then((response) => {
        const latestAnnouncementId: number = response.data.release_infos[0].id;
        const notifyFlag = response.data.release_infos[0].notify_flag;

        if (currentAnnouncementId !== latestAnnouncementId) {
          if (notifyFlag === Constants.NOTICE_ENABLE) {
            // 通知が必要な場合
            dispatch(openAnnouncementModal());
          } else {
            // 強制リロード
            window.location.reload();
            localStorage.setItem(Constants.CURRENT_ANNOUNCEMENT_ID_KEY, String(latestAnnouncementId));
          }
        }
        resolve("ok");
      })
      .catch((error) => {
        console.log(error);
        reject(error);
      });
  });
};
