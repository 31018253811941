import { ModelsHistoryLog } from "src/store/helppadApi";

/**
 * 表示する際に不要なログをフィルターする
 */
export function filterUnnecessaryHistroyLog(logs: ModelsHistoryLog[] | undefined) {
  if (logs == null) {
    return logs;
  }
  return logs.filter((log) => {
    if (!log.changes) return false;
    if (log.table_name === "care_subjects" && log.action === "update") return true;
    if (log.table_name === "excretion_results" && (log.action === "create" || log.action === "update")) return true;
    return false;
  });
}
