import { Alert, AlertColor } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  status: string | undefined;
};

/**
 * 連携先ステータス
 */
export const IntegrationAppStatus = ({ status }: Props) => {
  const { t } = useTranslation();

  const [state, stateXX] = useMemo<[AlertColor, "2xx" | "4xx" | "5xx"]>(() => {
    // 本当は 200-1 も共通処理で求めたいがルールが異なる({name}-{httpStatus}-... ではない)ので専用処理
    if (status === "200-1") {
      return ["success", "2xx"];
    }

    // HTTPステータスを求める
    const httpStatus = status?.split("-")?.[1];
    if (httpStatus == null) {
      return ["error", "5xx"];
    }
    const parsedHttpStatus = parseInt(httpStatus);
    const baseState = Math.floor(parsedHttpStatus / 100) * 100;
    switch (baseState) {
      case 100:
      case 200:
      case 300:
        return ["success", "2xx"];
      case 400:
        return ["warning", "4xx"];
      default:
        // NaN もここ
        return ["error", "5xx"];
    }
  }, [status]);

  // バックグラウンドで連携処理などが走っていない場合、明示的に null があり得る
  // また空文字が来ることもあり、そのときは何も表示しない
  if (status == null || status.length === 0) {
    return null;
  }

  return (
    <Alert severity={state} sx={{ padding: "0 0.5rem" }}>
      {t(`setting.integration_app_status.${status}`, {
        defaultValue: t(`setting.integration_app_status.other-${stateXX}`),
        status,
      })}
    </Alert>
  );
};
