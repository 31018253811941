import { Box, Typography } from "@mui/material";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  disabled?: boolean;
  fontSize?: string;
};

/**
 * 漏れラベル
 */
export const LeakedLabel = ({ disabled = false, fontSize = "16px" }: Props) => {
  const { t } = useTranslation();
  return (
    <Typography fontSize={fontSize}>
      <Text disabled={disabled} color={"#222"}>
        {t("common.excretion.leaked")}
      </Text>
      <Text disabled={disabled} color={"red"}>
        {t("common.excretion.existence")}
      </Text>
    </Typography>
  );
};

export const NotLeakedLabel = ({ disabled = false, fontSize = "16px" }: Props) => {
  const { t } = useTranslation();
  return (
    <Typography fontSize={fontSize}>
      <Text disabled={disabled} color={"#222"}>
        {t("common.excretion.leaked")}
      </Text>
      <Text disabled={disabled} color={"blue"}>
        {t("common.excretion.none")}
      </Text>
    </Typography>
  );
};

const Text = ({ disabled, color, children }: { disabled: boolean; color?: string; children: ReactNode }) => {
  return (
    <Box component="span" fontWeight={"bold"} color={color} sx={{ opacity: disabled ? 0.4 : 1, ml: "0.25rem" }}>
      {children}
    </Box>
  );
};
