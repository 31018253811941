import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { CommonDialog } from "../CommonDialog";
import { useLazyFindIntegrationCareSubjectsByCareSubjectIdQuery } from "src/store/enhancedApi";
import { CustomTextInput } from "../parts/CustomTextInput";
import { DialogInput } from "../parts/DialogInput";
import { Alert, Box, Grid } from "@mui/material";
import {
  ModelsIntegrationAppsDefinition,
  ModelsIntegrationCareSubjects,
  useDeleteIntegrationCareSubjectsMutation,
  usePatchIntegrationCareSubjectsMutation,
  usePostIntegrationCareSubjectsMutation,
} from "src/store/helppadApi";
import { Constants } from "src/constants/commonConstants";
import { useBlueOceanIntegrationSetting } from "src/utils/useBlueOceanIntegrationSetting";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";
import { useTranslation } from "react-i18next";

const CareSubjectIntegrationSettingDialogStatus = {
  Initializing: "initializing",
  Idling: "idling",
  Processing: "processing",
  Finished: "finished",
  Error: "error",
} as const;
type CareSubjectIntegrationSettingDialogStatusType =
  typeof CareSubjectIntegrationSettingDialogStatus[keyof typeof CareSubjectIntegrationSettingDialogStatus];

type Mode = undefined | "add" | "edit";

type Props = {
  open: boolean; // ダイアログの表示・非表示
  status: CareSubjectIntegrationSettingDialogStatusType;
  integrationCode: string | undefined; // 連携コード
  isValidIntegrationCode: boolean | undefined; // 連携コードが正しいか
  onChangeIntegrationCode: (next: string) => void;
  onAccept: () => void;
  onCancel: () => void;
  error?: Error;
  mode: Mode;
};

export const useCareSubjectIntegrationSettingDialog = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [targetCareSubjectId, setTargetCareSubjectId] = useState<undefined | number>();
  const [nextIntegrationCode, setNextIntegrationCode] = useState<string>("");

  const [
    find,
    { currentData, error: findError, isSuccess: isFindSuccess, isError: isFindError, isFetching: isFindFetching },
  ] = useLazyFindIntegrationCareSubjectsByCareSubjectIdQuery();

  const { base, mode }: { base?: ModelsIntegrationCareSubjects; mode?: Mode } = useMemo(() => {
    if (targetCareSubjectId == null || isFindFetching || !isFindSuccess) {
      return {};
    }

    // 今のところ100件も設定されないから問題にならないが、ページネーション機能を有しているので探索し続ける必要があるかもしれない
    // その場合、search クエリパラメータを付けてもらうほうが良さそう
    const item = currentData?.items?.filter(
      (d) => d.care_subject_id === targetCareSubjectId && d.integration_app?.name === Constants.BLUE_OCEAN_NAME
    )?.[0];

    // BlueOcean の item が無いということは連携コードは「未設定」ということ
    return item == null ? { mode: "add" } : { base: item, mode: "edit" };
  }, [targetCareSubjectId, currentData, isFindSuccess, isFindFetching]);

  useEffect(() => {
    setNextIntegrationCode(base?.integration_code ?? "");
  }, [base]);

  const [
    apply,
    resetApply,
    { isLoading: isApplying, isError: isApplyError, error: applyError, isSuccess: isApplySuccess },
  ] = useIntegrationCareSubjectsApplyMutation(mode);

  const [blueOceanIntegrationSetting, blueOceanSettingError, refetchBlueOceanIntegrationSetting] =
    useBlueOceanIntegrationSetting();

  // 入力内容の検証(新規追加時と編集時でルールが異なる点に注意)
  const isValidIntegrationCode = useMemo(() => {
    if (nextIntegrationCode == null || mode == null) {
      return undefined;
    }
    // 追加時は1文字以上必要だが、編集時は削除を意味する0文字も許容する(=常にvalid)
    if (mode === "add") {
      return nextIntegrationCode.length >= 1;
    }

    return true;
  }, [mode, nextIntegrationCode]);

  // ダイアログ閉じてくれないと困る場合は fatality を true にする
  const [fatality, error] = useMemo(() => {
    if (blueOceanSettingError != null) {
      return [true, blueOceanSettingError];
    }
    if (findError != null && isFindError) {
      return [true, new Error(t("module.integration_app.failed_loading_integration_code"))];
    }

    if (applyError != null && isApplyError) {
      // 既に設定があるのにPOSTしようとすると 409 / 既に削除済みだが消そうとすると 404
      // 基本は発生しないので、一度ダイアログを閉じてもらう
      const fatality = "status" in applyError && (applyError.status === 409 || applyError.status === 404);

      return [fatality, new Error(t("module.integration_app.failed_setting_integration_code"))];
    }

    return [false, undefined];
  }, [t, blueOceanSettingError, findError, isFindError, applyError, isApplyError]);

  const status: CareSubjectIntegrationSettingDialogStatusType = useMemo(() => {
    if (fatality) {
      return "error";
    }

    if (isFindFetching) {
      return "initializing";
    }

    if (isApplying) {
      return "processing";
    }

    if (isApplySuccess) {
      return "finished";
    }

    return "idling";
  }, [isFindFetching, isApplying, isApplySuccess, fatality]);

  const showDialog = useCallback(
    (careSubjectId: number) => {
      // 未設定状態に戻す
      setTargetCareSubjectId(careSubjectId);
      setNextIntegrationCode("");

      refetchBlueOceanIntegrationSetting();
      resetApply();

      find({ id: careSubjectId }, false);
      setOpen(true);
    },
    [refetchBlueOceanIntegrationSetting, resetApply, find]
  );

  const onAccept = useCallback(async () => {
    if (targetCareSubjectId == null || blueOceanIntegrationSetting == null) {
      return;
    }

    resetApply();
    await apply(targetCareSubjectId, blueOceanIntegrationSetting, base, nextIntegrationCode);
  }, [resetApply, apply, blueOceanIntegrationSetting, targetCareSubjectId, base, nextIntegrationCode]);

  const onCancel = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    open,
    status,
    integrationCode: nextIntegrationCode,
    isValidIntegrationCode,
    onChangeIntegrationCode: setNextIntegrationCode,
    showDialog,
    onAccept,
    onCancel,
    error,
    mode,
  };
};

/**
 * 入居者の連携情報設定ダイアログ
 *
 * @memo
 * 現状、BlueOcean連携を前提とした実装になっているため、
 * これ以外の要素と連携している場合は修正が必要。
 */
export const CareSubjectIntegrationSettingDialog = ({
  open,
  status,
  integrationCode,
  isValidIntegrationCode,
  onAccept,
  onCancel,
  onChangeIntegrationCode,
  error,
  mode,
}: Props) => {
  const { t } = useTranslation();
  const handleChangeIntegrationCode = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeIntegrationCode(event.target.value);
    },
    [onChangeIntegrationCode]
  );

  return (
    <CommonDialog
      dialogOpen={open}
      title={t("module.integration_app.care_subject_integration_setting")}
      acceptButtonName={t("common.button.configure")}
      onAccept={onAccept}
      cancelButtonName={t("common.button.cancel")}
      onCancel={onCancel}
      isChecked={isValidIntegrationCode === true && status !== "error"}
      isProcessed={status === "processing"}
      isCompleted={status === "finished"}
      closeOnClickBackdropOrEscape={status !== "processing"}
      completeMessage={t("module.integration_app.completed_integration")}
      completeContent={
        <Grid container>
          <Grid item textAlign={"right"} xs={5}>
            {t("module.integration_app.blueocean_integration_code")}：
          </Grid>
          <Grid item pl={2} xs={7} sx={{ lineBreak: "anywhere" }}>
            {integrationCode == null
              ? "---"
              : integrationCode.length === 0
              ? t("module.integration_app.deleted")
              : integrationCode}
          </Grid>
        </Grid>
      }
    >
      {/* 1要素だけだと高さが少し低く感じるのでY軸方向にマージン設定 */}
      <Box my={6}>
        <DialogInput
          label={t("module.integration_app.blueocean_integration_code")}
          labelXs={5}
          helperText={mode === "edit" ? <>{t("module.integration_app.delete_setting_if_blank")}</> : null}
        >
          <CustomTextInput
            name="integration_code"
            isValid={true}
            disabled={status !== "idling"}
            onChange={handleChangeIntegrationCode}
            value={integrationCode ?? ""}
          />
        </DialogInput>
        {error && (
          <Alert style={{ width: "auto", minWidth: "auto" }} sx={{ mt: 2 }} severity={"error"}>
            {error.message}
            {status === "error" && t("module.integration_app.close_dialog_once")}
          </Alert>
        )}
      </Box>
    </CommonDialog>
  );
};

/**
 * 新規作成時は POST / 編集時は PATCH / 編集時でも空文字の場合は DELETE
 * という呼び分けのややこしさをここで吸収する。
 */
const useIntegrationCareSubjectsApplyMutation = (
  mode: Mode
): [
  (
    careSubjectId: number,
    app: ModelsIntegrationAppsDefinition,
    base: ModelsIntegrationCareSubjects | undefined,
    integrationCode: string
  ) => Promise<void>,
  () => void,
  {
    isLoading: boolean;
    isSuccess: boolean;
    isError: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
  }
] => {
  const [
    post,
    { isSuccess: isPostSuccess, isError: isPostError, error: postError, isLoading: isPosting, reset: resetPost },
  ] = usePostIntegrationCareSubjectsMutation();

  const [
    _delete,
    {
      isSuccess: isDeleteSuccess,
      isError: isDeleteError,
      error: deleteError,
      isLoading: isDeleting,
      reset: resetDelete,
    },
  ] = useDeleteIntegrationCareSubjectsMutation();

  const [
    patch,
    { isSuccess: isPatchSuccess, isError: isPatchError, error: patchError, isLoading: isPatching, reset: resetPatch },
  ] = usePatchIntegrationCareSubjectsMutation();

  const mutate = useCallback(
    async (
      careSubjectId: number,
      app: ModelsIntegrationAppsDefinition,
      base: ModelsIntegrationCareSubjects | undefined,
      integrationCode: string
    ) => {
      if (mode == null || app.integration_app_id == null) {
        return;
      }
      switch (mode) {
        // 追加
        case "add":
          if (app.integration_app_id != null) {
            await post({
              modelsIntegrationCareSubjectsPostIn: {
                care_subject_id: careSubjectId,
                integration_app_id: app.integration_app_id,
                integration_code: integrationCode,
              },
            });
          }
          break;
        case "edit":
          if (base == null || base.id == null) {
            return;
          }
          if (integrationCode.length === 0) {
            // 空文字が指定されたので削除
            await _delete({ id: base.id });
          } else {
            // 編集
            await patch({
              id: base.id,
              modelsIntegrationCareSubjectsPatchIn: {
                care_subject_id: careSubjectId,
                integration_app_id: app.integration_app_id,
                integration_code: integrationCode,
              },
            });
          }
          break;
      }
    },
    [mode, post, _delete, patch]
  );

  const reset = useCallback(() => {
    resetPost();
    resetDelete();
    resetPatch();
  }, [resetPost, resetDelete, resetPatch]);

  const isLoading = useMemo(() => isPosting || isDeleting || isPatching, [isPosting, isDeleting, isPatching]);
  const error = useMemo(() => postError || deleteError || patchError, [postError, deleteError, patchError]);
  const isError = useMemo(
    () => isPostError || isDeleteError || isPatchError,
    [isPostError, isDeleteError, isPatchError]
  );
  const isSuccess = useMemo(
    () => isPostSuccess || isDeleteSuccess || isPatchSuccess,
    [isPostSuccess, isDeleteSuccess, isPatchSuccess]
  );

  return [mutate, reset, { isLoading, error, isError, isSuccess }];
};
