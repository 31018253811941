import { helppadApi } from "./helppadApi";

export const enhancedApi = helppadApi.enhanceEndpoints({
  addTagTypes: [
    "listGroup",
    "listStaff",
    "getCareSubject",
    "listCareSubject",
    "getExcretionResult",
    "listExcretionResult",
    "listHistoryLog",
    "listCommentLog",
    "listCommentThread",
    "updateDeviceOfCareSubject",
    "findNotificationTargetsByStaffId",
    "setNotificationTargetsByStaffId",
    "listGroupSubject",
    "updateGroupsOfCareSubject",
    "findCommentLogsByCareSubjectId",
    "aggregation",
    "getIntegrationApp",
    "listIntegrationApp",
    "getIntegrationAppsDefinition",
    "listIntegrationAppsDefinition",
    "findIntegrationCareSubjectsByCareSubjectId",
    "deleteIntegrationCareSubjects",
    "postIntegrationCareSubjects",
    "patchIntegrationCareSubjects",
  ],
  endpoints: {
    listGroup: {
      providesTags: ["listGroup"],
    },
    postGroup: {
      invalidatesTags: ["listGroup"],
    },
    deleteGroup: {
      invalidatesTags: ["listGroup"],
    },
    patchGroup: {
      invalidatesTags: ["listGroup"],
    },
    listStaff: {
      providesTags: ["listStaff"],
    },
    postStaff: {
      invalidatesTags: ["listStaff"],
    },
    deleteStaff: {
      invalidatesTags: ["listStaff"],
    },
    patchStaff: {
      invalidatesTags: ["listStaff"],
    },
    getCareSubject: {
      providesTags: ["getCareSubject"],
    },
    listCareSubject: {
      providesTags: ["listCareSubject"],
    },
    postCareSubject: {
      invalidatesTags: ["getCareSubject", "listCareSubject"],
    },
    deleteCareSubject: {
      invalidatesTags: ["getCareSubject", "listCareSubject"],
    },
    patchCareSubject: {
      invalidatesTags: ["getCareSubject", "listCareSubject", "listHistoryLog"],
    },
    listExcretionResult: {
      providesTags: ["listExcretionResult"],
    },
    getExcretionResult: {
      providesTags: ["getExcretionResult"],
    },
    postExcretionResult: {
      invalidatesTags: ["getCareSubject", "getExcretionResult", "listExcretionResult", "listHistoryLog"],
    },
    deleteExcretionResult: {
      invalidatesTags: ["listExcretionResult", "getExcretionResult"],
    },
    patchExcretionResult: {
      invalidatesTags: ["listExcretionResult", "listHistoryLog", "getExcretionResult"],
    },
    listCommentLog: {
      providesTags: ["listCommentLog"],
    },
    postCommentLog: {
      invalidatesTags: ["listCommentLog", "findCommentLogsByCareSubjectId"],
    },
    deleteCommentLog: {
      invalidatesTags: ["listCommentLog"],
    },
    patchCommentLog: {
      invalidatesTags: ["listCommentLog", "findCommentLogsByCareSubjectId"],
    },
    listCommentThread: {
      providesTags: ["listCommentThread"],
    },
    postCommentThread: {
      invalidatesTags: ["listCommentThread"],
    },
    deleteCommentThread: {
      invalidatesTags: ["listCommentThread"],
    },
    patchCommentThread: {
      invalidatesTags: ["listCommentThread"],
    },
    updateDeviceOfCareSubject: {
      invalidatesTags: ["listCareSubject"],
    },
    findNotificationTargetsByStaffId: {
      providesTags: ["findNotificationTargetsByStaffId"],
    },
    setNotificationTargetsByStaffId: {
      invalidatesTags: ["listGroup", "listCareSubject", "findNotificationTargetsByStaffId"],
    },
    listGroupSubject: {
      providesTags: ["listGroupSubject"],
    },
    updateGroupsOfCareSubject: {
      invalidatesTags: ["listGroup", "listGroupSubject", "listCareSubject"],
    },
    listHistoryLog: {
      providesTags: ["listHistoryLog"],
    },
    deleteHistoryLog: {
      invalidatesTags: ["listHistoryLog", "getExcretionResult"],
    },
    findCommentLogsByCareSubjectId: {
      providesTags: ["findCommentLogsByCareSubjectId"],
    },
    aggregation: {
      providesTags: ["aggregation"],
      query: (queryArg) => ({
        url: `/aggregation`,
        params: {
          aggregation_type: queryArg.aggregationType,
          end: queryArg.end,
          start: queryArg.start,
          target: queryArg.target,
        },
        responseHandler: "text",
      }),
    },
    getIntegrationApp: {
      providesTags: ["getIntegrationApp"],
    },
    listIntegrationApp: {
      providesTags: ["listIntegrationApp"],
    },
    getIntegrationAppsDefinition: {
      providesTags: ["getIntegrationAppsDefinition"],
    },
    listIntegrationAppsDefinition: {
      providesTags: ["listIntegrationAppsDefinition"],
    },
    postIntegrationAppsDefinition: {
      invalidatesTags: ["getIntegrationAppsDefinition", "listIntegrationAppsDefinition"],
    },
    deleteIntegrationAppsDefinition: {
      invalidatesTags: ["getIntegrationAppsDefinition", "listIntegrationAppsDefinition"],
    },
    patchIntegrationAppsDefinition: {
      invalidatesTags: ["getIntegrationAppsDefinition", "listIntegrationAppsDefinition"],
    },
    findIntegrationCareSubjectsByCareSubjectId: {
      providesTags: ["findIntegrationCareSubjectsByCareSubjectId"],
    },
    deleteIntegrationCareSubjects: {
      invalidatesTags: ["listCareSubject"],
    },
    postIntegrationCareSubjects: {
      invalidatesTags: ["listCareSubject"],
    },
    patchIntegrationCareSubjects: {
      invalidatesTags: ["listCareSubject"],
    },
  },
});

export const {
  useListGroupQuery,
  usePostGroupMutation,
  useDeleteGroupMutation,
  usePatchGroupMutation,
  useListStaffQuery,
  usePostStaffMutation,
  useDeleteStaffMutation,
  usePatchStaffMutation,
  useListCareSubjectQuery,
  usePostCareSubjectMutation,
  useDeleteCareSubjectMutation,
  useGetCareSubjectQuery,
  usePatchCareSubjectMutation,
  useListExcretionResultQuery,
  useLazyGetExcretionResultQuery,
  usePostExcretionResultMutation,
  useDeleteExcretionResultMutation,
  usePatchExcretionResultMutation,
  useListCommentLogQuery,
  usePostCommentLogMutation,
  useDeleteCommentLogMutation,
  usePatchCommentLogMutation,
  useListCommentThreadQuery,
  usePostCommentThreadMutation,
  useDeleteCommentThreadMutation,
  usePatchCommentThreadMutation,
  useUpdateDeviceOfCareSubjectMutation,
  useFindNotificationTargetsByStaffIdQuery,
  useSetNotificationTargetsByStaffIdMutation,
  useListGroupSubjectQuery,
  useUpdateGroupsOfCareSubjectMutation,
  useListHistoryLogQuery,
  useDeleteHistoryLogMutation,
  useFindCommentLogsByCareSubjectIdQuery,
  useAggregationQuery,
  useGetIntegrationAppQuery,
  useListIntegrationAppQuery,
  useGetIntegrationAppsDefinitionQuery,
  useListIntegrationAppsDefinitionQuery,
  usePostIntegrationAppsDefinitionMutation,
  useDeleteIntegrationAppsDefinitionMutation,
  usePatchIntegrationAppsDefinitionMutation,
  useLazyFindIntegrationCareSubjectsByCareSubjectIdQuery,
  usePostIntegrationCareSubjectsMutation,
  usePatchIntegrationCareSubjectsMutation,
  useDeleteIntegrationCareSubjectsMutation,
} = enhancedApi;
