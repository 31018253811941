import { TFunction } from "i18next";

export const ExcretionSupportedPlaceTypes = {
  None: 0, // 入力なし
  DiaperChange: 1, // おむつ交換
  ToiletAssistance: 2, // トイレ誘導
} as const;

// 対応方法種別
export type ExcretionSupportedPlace = typeof ExcretionSupportedPlaceTypes[keyof typeof ExcretionSupportedPlaceTypes];

export function parseExcretionSupportedPlaceTypeNumber(num: number | undefined): ExcretionSupportedPlace | undefined {
  if (num == null) {
    return undefined;
  }
  const valid = (Object.values(ExcretionSupportedPlaceTypes) as number[]).includes(num);
  return valid ? (num as ExcretionSupportedPlace) : undefined;
}

export const toExcretionSupportedPlaceTypeName = (type: ExcretionSupportedPlace | undefined, t: TFunction): string => {
  switch (type) {
    case ExcretionSupportedPlaceTypes.None:
      return t("module.excretion_support.none_input");
    case ExcretionSupportedPlaceTypes.DiaperChange:
      return t("module.excretion_support.diaper_change");
    case ExcretionSupportedPlaceTypes.ToiletAssistance:
      return t("module.excretion_support.toilet_assistance");
  }
  return "---";
};
