import { Box, Grid } from "@mui/material";
import { BackButton } from "src/modules/BackButton";
import { Breadcrumb } from "src/modules/Breadcrumb";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Toolbar = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fromPath = queryParams.get("from");

  let breadcrumbPaths;
  if (fromPath === "care_subjects") {
    breadcrumbPaths = [
      { path: "/care_subjects", label: t("care_subject.toolbar.care_subjects_care_subject_list") },
      { path: undefined, label: t("care_subject.toolbar.care_subjects_care_subject_detail") },
    ];
  } else if (fromPath === "notices") {
    breadcrumbPaths = [
      { path: "/notices", label: t("care_subject.toolbar.notices_care_subject_list") },
      { path: undefined, label: t("care_subject.toolbar.notices_care_subject_detail") },
    ];
  } else {
    breadcrumbPaths = [
      { path: "/setting/caresubjects_setting", label: t("care_subject.toolbar.setup_care_subject") },
      { path: undefined, label: t("care_subject.toolbar.care_subject_detail") },
    ];
  }

  return (
    <Box
      display="flex"
      height="80px"
      sx={{
        alignItems: "center",
        borderBottom: "1px solid #e6e6e6",
        pl: 3,
      }}
    >
      <Grid item xs="auto" sx={{ marginRight: "20px" }}>
        {" "}
        <BackButton />
      </Grid>
      <Grid item xs>
        <Breadcrumb paths={breadcrumbPaths} />
      </Grid>
    </Box>
  );
};
