import { Box, Tab, Tabs, TabProps } from "@mui/material";
import { useCallback, SyntheticEvent } from "react";
import { Route, Routes, useNavigate, useMatch, Navigate } from "react-router-dom";
import { Pairing } from "./Pairing";
import { AccountSetting } from "./AccountSetting";
import { CareSubjectsSetting } from "./CareSubjectsSetting";
import { GroupSetting } from "./GroupSetting";
import { FacilityInformation } from "./FacilityInformation";
import { IntegrationAppSetting } from "./IntegrationAppSetting/IntegrationAppSetting";
import { useTranslation } from "react-i18next";

const DEFAULT_TAB_VALUE = "account_setting";

export const Setting = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const tabValueMatch = useMatch("/setting/:tabValue");
  const selectedTabValue = tabValueMatch?.params.tabValue;

  const handleChangeTab = useCallback(
    (_: SyntheticEvent, value: string) => {
      navigate(value);
    },
    [navigate]
  );

  return (
    <Box display={"flex"} sx={{ flexDirection: "column" }} height="100%" width="100%">
      <Box borderBottom="1px solid #D9D9D9">
        <Tabs
          variant="fullWidth"
          value={selectedTabValue ?? false}
          onChange={handleChangeTab}
          TabIndicatorProps={{
            style: { height: "3px", background: "#F29501" },
          }}
        >
          {PageTabItems.map((tab) => (
            <PageTab key={tab.value} value={tab.value} label={t(tab.i18nKey)} />
          ))}
        </Tabs>
      </Box>
      <Box flex={1} width={"100%"} overflow={"auto"}>
        <Routes>
          {PageTabItems.map((tab) => (
            <Route key={tab.value} path={`/${tab.value}`} element={tab.element} />
          ))}
          <Route path="*" element={<Navigate replace to={DEFAULT_TAB_VALUE} />} />
        </Routes>
      </Box>
    </Box>
  );
};

const PageTab = (props: TabProps) => {
  return (
    <Tab
      sx={{
        textTransform: "none",
        "&.MuiTab-root": {
          minHeight: "90px",
          color: "#404040",
          fontSize: "1.25rem",
        },
        "&.Mui-selected": {
          color: "#F29501",
          fontWeight: 700,
        },
        "&.Mui-selected:hover": {
          color: "#F29501",
        },
        ":not(:last-child)": {
          borderRight: "1px solid #D9D9D9",
        },
      }}
      iconPosition="start"
      {...props}
    />
  );
};

const PageTabItems: { value: string; i18nKey: string; element: JSX.Element }[] = [
  {
    value: "account_setting",
    i18nKey: "setting.index.account_setting",
    element: <AccountSetting />,
  },
  {
    value: "facility_information",
    i18nKey: "setting.index.facility_information",
    element: <FacilityInformation />,
  },
  {
    value: "caresubjects_setting",
    i18nKey: "setting.index.caresubjects_setting",
    element: <CareSubjectsSetting />,
  },
  {
    value: "group_setting",
    i18nKey: "setting.index.group_setting",
    element: <GroupSetting />,
  },
  {
    value: "helppad_pairing",
    i18nKey: "setting.index.helppad_pairing",
    element: <Pairing />,
  },
  {
    value: "integration_app_setting",
    i18nKey: "setting.index.integration_app_setting",
    element: <IntegrationAppSetting />,
  },
];
