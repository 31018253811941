import { Fragment } from "react";
import { DataRow } from "../../parts/DataRow";
import { isFecesCategory, isUrineCategory, toExcretionSupportTypeName } from "src/types/ExcretionSupportType";
import { Amount, toAmountName } from "src/types/amount";
import { FecesFirmness, toFecesFirmnessName } from "src/types/fecesFirmness";
import { ExcretionSupportedPlaceTypes, toExcretionSupportedPlaceTypeName } from "src/types/excretionSupportedPlaceType";
import { KarteTextare } from "../../parts/KarteTextarea";
import { format } from "date-fns";
import { Constants } from "src/constants/commonConstants";
import { ExcretionRecord } from "../types/ExcretionRecord";
import { useTranslation } from "react-i18next";

type Props = {
  careSubjectName: string | undefined;
  result: ExcretionRecord | null;
};

export const ExcretionCompleteContent = ({ careSubjectName, result }: Props) => {
  const { t } = useTranslation();

  if (result == null) {
    return null;
  }

  const {
    excretionType,
    supportedAt,
    inputPerson,
    isLeaked,
    urineAmount,
    fecesAmount,
    fecesFirmness,
    supportedPlace,
    karte,
  } = result;
  return (
    <Fragment>
      <DataRow label={t("common.care_subject.target_care_subject")} value={careSubjectName} />
      <DataRow
        label={t("module.excretion_support.support_type")}
        value={toExcretionSupportTypeName(excretionType, t)}
      />
      <DataRow
        label={t("common.excretion.leaked")}
        value={t(isLeaked ? "module.excretion_support.with_leaked" : "module.excretion_support.no_leaked")}
      />
      {isUrineCategory(excretionType) && (
        <DataRow
          label={t("module.excretion_support.urine_amount")}
          value={toAmountName(urineAmount, t)}
          isNone={urineAmount === Amount.None || urineAmount == null}
        />
      )}
      {isFecesCategory(excretionType) && (
        <>
          <DataRow
            label={t("module.excretion_support.feces_amount")}
            value={toAmountName(fecesAmount, t)}
            isNone={fecesAmount === Amount.None || fecesAmount == null}
          />
          <DataRow
            label={t("module.excretion_support.feces_firmness")}
            value={toFecesFirmnessName(fecesFirmness, t)}
            isNone={fecesFirmness === FecesFirmness.None || fecesFirmness == null}
          />
        </>
      )}
      {supportedPlace !== ExcretionSupportedPlaceTypes.None && (
        <DataRow
          label={t("module.excretion_support.support_method")}
          value={toExcretionSupportedPlaceTypeName(supportedPlace, t)}
        />
      )}
      {karte.length > 0 && (
        <DataRow
          label={t("module.excretion_support.memo")}
          value={<KarteTextare disabled={true} value={karte} showCounter={false} />}
        />
      )}
      <DataRow
        label={t("module.excretion_support.support_date")}
        value={format(new Date(supportedAt), Constants.DATE_FORMAT_YYYYMMDD_HHMM)}
      />
      <DataRow label={t("module.excretion_support.support_input_person")} value={inputPerson} />
    </Fragment>
  );
};
