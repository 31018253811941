import { Box, Fade, Stack, Tooltip, Typography } from "@mui/material";
import { ReactNode, useMemo, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { readSoundKeyFromLocalStorage } from "src/utils/localStorage";
import {
  faHourglassHalf,
  faUsers,
  faBell,
  faRotateRight,
  faHourglass2,
  faVolumeOff,
  faVolumeLow,
  faVolumeHigh,
  faVolumeXmark,
} from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  AlertDetailType,
  Constants,
  getDeviceErrorCode,
  getDeviceErrorItem,
  getDeviceErrorMessage,
} from "src/constants/commonConstants";
import { NotificationConstants } from "src/constants/notificationSound";
import { useDispatch } from "react-redux";
import { checkVersion } from "src/utils/checkVersion";
import { useListCareSubjectQuery, ModelsCareSubject, ModelsGroup } from "src/store/helppadApi";
import { ToggleButtons } from "src/modules/ToggleButtons";
import { FilterGroupsPanel } from "src/modules/FilterGroupsPanel";
import { NotificationSoundPanel } from "src/modules/NotificationSoundPanel";
import { useSoundByKey } from "src/utils/soundUtil";
import { UNASSIGNED_GROUP, UNASSIGNED_GROUP_ID, groupColor } from "src/constants/groupColor";
import { useNavigate, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { parseFirstAlertedAt, toTimeDiffString } from "src/utils/dateUtil";
import { useBooleanLocalStorage, useNumberArrayLocalStorage, useStringLocalStorage } from "src/utils/localStorage";
import { toCareSubjectPageLink } from "../CareSubject/toCareSubjectPageLink";
import { NoticeSoundWorker } from "src/worker/NoticeSound.worker";
import { DeviceErrorIcon } from "src/modules/parts/DeviceErrorIcon";
import { useTranslation } from "react-i18next";
import { useIsLargeWidthDesktop } from "src/utils/useIsLargeWidthDesktop";
import { useIsTouchDevice } from "src/utils/useIsTouchDevice";
import { useAnnouncementFile } from "src/utils/useAnnouncementFile";

type NoticeView = {
  group: ModelsGroup;
  careSubjects: ModelsCareSubject[];
};

const getBackgroundForAlertType = (type: number | undefined) => {
  if (type === undefined) {
    return "#FFFFFF";
  }
  switch (type) {
    case 1:
      return "linear-gradient(180deg, #FFFA83 0%, rgba(255, 250, 131, 0.1) 100%)";
    case 2:
      return "linear-gradient(180deg, rgba(255, 121, 137, 0.8) 0%, rgba(255, 121, 137, 0.16) 100%)";
    case 3:
    default:
      return "#FFFFFF";
  }
};

const NoticeItem = (props: {
  careSubject: ModelsCareSubject;
  group: ModelsGroup;
  isNoticeTimeView: boolean;
  isAllView: boolean;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { careSubject, group, isNoticeTimeView, isAllView } = props;
  const { first_alerted_at } = careSubject;
  const location = useLocation();
  const fromPath = location.pathname.slice(1);
  const alertedAt = useMemo(() => parseFirstAlertedAt(first_alerted_at), [first_alerted_at]);
  const details = careSubject.active_alert_log?.detail;

  if (!isAllView && details !== 1 && details !== 2 && !careSubject.device_error) return <></>;
  const groupColorCode = groupColor(group?.id!);

  return (
    <Box
      width="290px"
      height="200px"
      sx={{
        borderRadius: "10px",
        border: "1px solid #E9E9E9",
        ":hover": {
          opacity: 0.5,
          cursor: "pointer",
        },
      }}
      onClick={() => {
        navigate(toCareSubjectPageLink(careSubject?.id, group?.id, fromPath));
      }}
    >
      <Box
        py={1}
        pl={2}
        display="flex"
        sx={{
          borderTopLeftRadius: "10px",
          borderTopRightRadius: "10px",
          backgroundColor: groupColorCode,
        }}
      >
        <Typography>{group.id === UNASSIGNED_GROUP_ID ? t("common.group.unassigned") : group.name}</Typography>
      </Box>
      <Box
        textAlign="center"
        position="relative"
        sx={{
          background: getBackgroundForAlertType(careSubject.active_alert_log?.detail),
        }}
      >
        <Typography
          pt={1}
          fontSize="24px"
          fontWeight="bold"
          height="45px"
          fontFamily="Noto Sans JP"
          color="#404040"
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          {careSubject.room_name}
        </Typography>
        <CareSubjectName value={careSubject.name ?? "---"} />
        {careSubject.device_error ? (
          <Tooltip
            title={
              <>
                <Typography variant="body1" gutterBottom>
                  {t(getDeviceErrorCode(careSubject.device_error) ?? "")}
                  <br />
                </Typography>
                <Typography variant="caption" gutterBottom>
                  {t(getDeviceErrorItem(careSubject.device_error) ?? "")}
                  <br />
                </Typography>
                <Typography variant="caption" gutterBottom>
                  {t(getDeviceErrorMessage(careSubject.device_error) ?? "")}
                </Typography>
              </>
            }
            placement="right-start"
          >
            <Box position="absolute" top="5px" right="10px">
              <DeviceErrorIcon />
            </Box>
          </Tooltip>
        ) : null}
      </Box>
      <Box
        display="flex"
        borderTop="1px solid #E6E6E6"
        borderBottom="1px solid #E6E6E6"
        sx={{
          backgroundColor: "#FFF",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
      >
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRight="1px solid #E6E6E6"
          width="50px"
          height="50px"
        >
          <Typography fontWeight="bold" color="#0E1C73" fontSize="24px">
            {careSubject.active_alert_log ? careSubject.active_alert_log.urine_count : 0}
          </Typography>
        </Box>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          borderRight="1px solid #E6E6E6"
          width="50px"
          height="50px"
        >
          <Typography fontWeight="bold" color="#FA5A00" fontSize="24px">
            {careSubject.active_alert_log ? careSubject.active_alert_log.feces_count : 0}
          </Typography>
        </Box>
        <Box flex={1} alignItems="center" justifyContent="center" display="flex">
          <Typography mx={1}>
            <FontAwesomeIcon icon={isNoticeTimeView ? faClock : faHourglass2} />
          </Typography>
          {alertedAt == null ? (
            <EmptyAlertedAt />
          ) : isNoticeTimeView ? (
            <NoticedAtTime alertedAt={alertedAt} />
          ) : (
            <NotificationElapsedTime alertedAt={alertedAt} />
          )}
        </Box>
      </Box>
    </Box>
  );
};

/**
 * アラート（通知）発生時の表示コンポーネント
 */
const NotificationView = ({ handleClick }: { handleClick: () => void }) => {
  const { t } = useTranslation();
  const [hovered, setHovered] = useState(false);
  const [fade, setFade] = useState(true);
  const [volumeLevel, setVolumeLevel] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setVolumeLevel((prev) => (prev === 2 ? 0 : prev + 1));
    }, 500);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade((prev) => !prev);
    }, 300);
    return () => clearInterval(interval);
  }, []);

  return (
    <Fade in={fade} timeout={600}>
      <Box
        role="button"
        display="flex"
        justifyContent="center"
        alignItems="center"
        py="16px"
        sx={{
          backgroundColor: hovered ? "#e8e8e8" : "#F9da7d",
          transition: "all 0.3s",
          cursor: "pointer",
          boxShadow: hovered ? "0px 4px 6px rgba(0, 0, 0, 0.2)" : "none",
          textTransform: "none",
          ":active": {
            transform: "scale(0.95)",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
          },
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={() => handleClick()}
      >
        {hovered ? (
          <FontAwesomeIcon icon={faVolumeXmark} color="#000" size="2x" />
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: "40px" }}>
            {volumeLevel === 0 && <FontAwesomeIcon icon={faVolumeOff} color="#fd0100" size="2x" />}
            {volumeLevel === 1 && <FontAwesomeIcon icon={faVolumeLow} color="#fd0100" size="2x" />}
            {volumeLevel === 2 && <FontAwesomeIcon icon={faVolumeHigh} color="#fd0100" size="2x" />}
          </Box>
        )}
        <Typography ml="20px" color={hovered ? "#000" : "#fd0100"} fontSize="28px" fontWeight={800}>
          {hovered
            ? t("notices.notification_view.alert_notification_stop")
            : t("notices.notification_view.alert_notification_sound")}
        </Typography>
      </Box>
    </Fade>
  );
};

export const NoticesPC = () => {
  const { t } = useTranslation();
  const announcementFilePath = useAnnouncementFile();
  const dispatch = useDispatch();
  // タブレット端末かどうか、 PC でもディスプレイによってはタッチ操作が可能なため画面サイズが大きい場合にはタッチ操作が可能でもタブレットとして扱わない
  const isTouchDevice = useIsTouchDevice();
  const isLargeWidthDesktop = useIsLargeWidthDesktop();
  const isTablet = isTouchDevice && !isLargeWidthDesktop;

  const careSubjects = useListCareSubjectQuery({}, Constants.POLLING_INTERVAL).data?.items;
  const [cachedCareSubjects, setCachedCareSubjects] = useState<ModelsCareSubject[]>([]);
  // グループ表示
  const [selectedGroups, setSelectedGroups] = useNumberArrayLocalStorage(`${NoticesPC.name}__selectedGroups`, []);

  // 通知音管理用
  const [notificationPermission, setNotificationPermission] = useState("default"); // ブラウザの通知権限
  const [isIntervalAlert, setIsIntervalAlert] = useState(false);
  const [currentSound, setCurrentSound] = useStringLocalStorage(
    Constants.SELECTED_NOTIFICATION_SOUND,
    readSoundKeyFromLocalStorage(Constants.SELECTED_NOTIFICATION_SOUND, NotificationConstants.NOTIFICATION_BELL_2S)
  );
  const {
    playSound: playNotificationSound,
    stopSound: stopNotificationSound,
    isSoundPlaying,
  } = useSoundByKey(currentSound);

  // ブラウザの通知の権限チェック
  const checkNotificationPermission = async () => {
    if ("Notification" in window) {
      const permission = await Notification.requestPermission();
      setNotificationPermission(permission);
    }
  };

  // 他コンポーネント遷移した場合は通知音を停止する
  useEffect(() => {
    return () => {
      stopNotificationSound();
    };
  }, [stopNotificationSound]);

  useEffect(() => {
    checkNotificationPermission();
  }, []);

  // 初回通知が必要かチェック
  useEffect(() => {
    const permission = notificationPermission === "granted";
    if (!permission || !careSubjects || careSubjects.length === 0) {
      return;
    }
    if (checkFirstAlert(careSubjects, cachedCareSubjects, selectedGroups)) {
      playNotificationSound();
    }
    setCachedCareSubjects(careSubjects);
  }, [notificationPermission, playNotificationSound, careSubjects, cachedCareSubjects, selectedGroups]);

  // 指定時間[00分、15分、30分、45分]のチェック
  useEffect(() => {
    const permission = notificationPermission === "granted";
    if (!permission || !careSubjects || careSubjects.length === 0) {
      return;
    }

    // web worker側でチェック処理を実行
    const worker = new Worker(URL.createObjectURL(new Blob([`(${NoticeSoundWorker.toString()})()`])));
    worker.postMessage({
      careSubjects,
      selectedGroups,
    });
    worker.onmessage = async function (e) {
      const { result } = e.data;
      if (!isIntervalAlert && result) {
        setIsIntervalAlert(true);
        playNotificationSound();
        // アラートチェックにより通知音が連続でならないように10秒待機
        await new Promise((resolve) => setTimeout(resolve, 10000));
        setIsIntervalAlert(false);
      }
    };
    return () => {
      worker.terminate();
    };
  }, [notificationPermission, careSubjects, isIntervalAlert, selectedGroups, currentSound, playNotificationSound]);

  useEffect(() => {
    checkVersion(dispatch, announcementFilePath);
  }, [careSubjects, dispatch, announcementFilePath]);

  const noticeViewsMap = useMemo(() => {
    const map: { [key: number]: NoticeView } = {};

    if (careSubjects == null) {
      return map;
    }

    const sortedCareSubjects = careSubjects.slice();
    sortedCareSubjects.sort((a, b) => (a.room_name === b.room_name ? 0 : a.room_name! > b.room_name! ? 1 : -1));
    sortedCareSubjects.forEach((careSubject) => {
      const { groups } = careSubject;
      if (groups == null) {
        return;
      }

      // グループに1つも属していないのなら「未所属」というグループに属させる
      if (groups.length === 0) {
        map[UNASSIGNED_GROUP_ID] = map[UNASSIGNED_GROUP_ID] ?? {
          group: UNASSIGNED_GROUP,
          careSubjects: [],
        };
        map[UNASSIGNED_GROUP_ID].careSubjects.push(careSubject);
        return;
      }

      groups.forEach((group) => {
        map[Number(group.id)] = map[Number(group.id)] ?? { group, careSubjects: [] };
        map[Number(group.id)].careSubjects.push(careSubject);
      });
    });

    return map;
  }, [careSubjects]);

  const [isGroupSortView, setIsGroupSortView] = useBooleanLocalStorage(`${NoticesPC.name}__isGroupSortView`, true);
  const [isNoticeTimeView, setIsNoticeTimeView] = useBooleanLocalStorage(`${NoticesPC.name}__isNoticeTimeView`, true);
  const [isAllView, setIsAllView] = useBooleanLocalStorage(`${NoticesPC.name}__isAllView`, true);

  return (
    <>
      <Box
        display="flex"
        height="80px"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid #e6e6e6",
          px: 3,
        }}
      >
        <Box display="flex" gap="20px">
          <FilterGroupsPanel currentValues={selectedGroups} setValues={setSelectedGroups} />
          {!isTablet && <NotificationSoundPanel currentSound={currentSound} setCurrentSound={setCurrentSound} />}
        </Box>
        <Box display="flex" gap="20px">
          <ToggleButtons
            items={[
              { icon: faRotateRight, label: t("notices.notices.sort_group_order"), value: "sort" },
              { icon: faUsers, label: t("notices.notices.sort_by_group"), value: "split" },
            ]}
            currentValue={isGroupSortView ? "sort" : "split"}
            onChange={(value) => {
              if (value != null) {
                setIsGroupSortView(value === "sort");
              }
            }}
          />
          <ToggleButtons
            items={[
              { icon: faClock, label: t("notices.notices.display_alert_time"), value: "alert" },
              { icon: faHourglassHalf, label: t("notices.notices.display_elapsed_time"), value: "elapse" },
            ]}
            currentValue={isNoticeTimeView ? "alert" : "elapse"}
            onChange={(value) => {
              if (value != null) {
                setIsNoticeTimeView(value === "alert");
              }
            }}
          />
          <ToggleButtons
            items={[
              { icon: faUsers, label: t("notices.notices.display_all"), value: "all" },
              { icon: faBell, label: t("notices.notices.display_alert_and_error"), value: "alert" },
            ]}
            currentValue={isAllView ? "all" : "alert"}
            onChange={(value) => {
              if (value != null) {
                setIsAllView(value === "all");
              }
            }}
          />
        </Box>
      </Box>

      <Stack
        sx={{
          backgroundColor: "#F9F9F9",
        }}
      >
        {/* 通知音が鳴っている時表示 */}
        {isSoundPlaying && <NotificationView handleClick={stopNotificationSound} />}
        <Box
          display="flex"
          gap={"24px 24px"}
          flexWrap="wrap"
          minHeight="calc(100% - 131px)"
          p="24px"
          alignContent="flex-start"
        >
          {Object.entries(noticeViewsMap)
            .filter(([key]) => selectedGroups.length === 0 || selectedGroups.includes(Number(key)))
            .map(([key, view]) =>
              isGroupSortView ? (
                view.careSubjects.map((careSubject) => (
                  <NoticeItem
                    key={`${careSubject.id}_${view.group.id}`}
                    careSubject={careSubject}
                    group={view.group}
                    isNoticeTimeView={isNoticeTimeView}
                    isAllView={isAllView}
                  />
                ))
              ) : (
                <Box key={key} width="100%" display="flex" flexWrap="wrap" gap={"24px"}>
                  {view.careSubjects.map((careSubject) => (
                    <NoticeItem
                      key={`${careSubject.id}_${view.group.id}`}
                      careSubject={careSubject}
                      group={view.group}
                      isNoticeTimeView={isNoticeTimeView}
                      isAllView={isAllView}
                    />
                  ))}
                </Box>
              )
            )}
        </Box>
      </Stack>
    </>
  );
};

/**
 * 通知発生時刻を表示
 */
const NoticedAtTime = (props: { alertedAt: Date }) => {
  const { t } = useTranslation();
  return (
    <>
      <AlertedAtLabel>{t("notices.notices.alert")}</AlertedAtLabel>
      <AlertedAtValue>{format(props.alertedAt, "HH:mm")}</AlertedAtValue>
    </>
  );
};

const EmptyAlertedAt = () => (
  <Typography align="center" fontSize="24px" pl={0.5} flex={1}>
    -
  </Typography>
);
const AlertedAtLabel = (props: { children: ReactNode }) => {
  return (
    <Typography align="center" fontSize="16px" width="50px">
      {props.children}
    </Typography>
  );
};
const AlertedAtValue = (props: { children: ReactNode }) => {
  return (
    <Typography
      sx={{ whiteSpace: "nowrap" }}
      align="left"
      fontSize="24px"
      pl={0.5}
      flex={1}
      maxWidth={"120px"}
      overflow={"hidden"}
    >
      {props.children}
    </Typography>
  );
};

/**
 * 入居者名
 */
const CareSubjectName = (props: { value: string }) => (
  <Typography
    pb={1}
    fontSize={props.value.length >= 11 ? "26px" : "28px"}
    height={48}
    fontFamily="Noto Sans JP"
    color="#404040"
  >
    {props.value}
  </Typography>
);

/**
 * 通知発生してからの時間を表示
 */
const NotificationElapsedTime = (props: { alertedAt: Date }) => {
  const { t } = useTranslation();
  const { alertedAt } = props;

  const str = useMemo(() => toTimeDiffString(new Date(), alertedAt, t), [alertedAt, t]);

  return (
    <>
      <AlertedAtLabel>{t("notices.notices.elapsed")}</AlertedAtLabel>
      <AlertedAtValue>{str}</AlertedAtValue>
    </>
  );
};

/**
 * 初回通知が必要かチェック
 */
const checkFirstAlert = (
  careSubjects: ModelsCareSubject[],
  cachedCareSubjects: ModelsCareSubject[],
  selectedGroups: number[]
): boolean => {
  const isPendingAlert = cachedCareSubjects.some((cachedSubject) => {
    const careSubject = filterCareSubjects(careSubjects, selectedGroups).find(
      (subject) => subject.id === cachedSubject.id
    );
    if (!careSubject) {
      return false;
    }
    const cachedDetails = cachedSubject.active_alert_log?.detail;
    const details = careSubject.active_alert_log?.detail;

    // キャッシュのデータが「ヘルプパッドアラート(details=2)」に更新されているか
    return !compareDetails(cachedDetails, details) && details === AlertDetailType.Alert;
  });

  return isPendingAlert;
};

/**
 * アラートの状態が変更されているかチェック
 */
const compareDetails = (item1: number | undefined, item2: number | undefined): boolean => {
  return item1 === item2;
};

/**
 * 通知ターゲットとなる入居者を絞り込む
 * グループ絞り込みがない場合は全ての入居者を返す
 */
const filterCareSubjects = (careSubjects: ModelsCareSubject[], selectedGroups: number[]): ModelsCareSubject[] => {
  return selectedGroups.length === 0
    ? careSubjects
    : careSubjects.filter(
        (careSubject) =>
          careSubject.groups && careSubject.groups.length > 0 && selectedGroups.includes(careSubject.groups![0].id!)
      );
};
