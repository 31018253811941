import { Box, BoxProps, Button, IconButtonProps, Typography, styled } from "@mui/material";
import { ReactNode, useCallback, useMemo, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis, Tooltip } from "recharts";
import {
  AggregationDataTypeCombination,
  TargetAlertDataType,
  TargetDetectionDataType,
  TargetExcretionDataType,
  TargetLeakedDataType,
} from "src/types/aggregation";
import { useTranslation } from "react-i18next";

const GRAPH_MARGIN = {
  right: 20,
  top: 5,
  left: 0,
  bottom: -5,
};
const STROKE_DASH_ARRAY = "3 3";

const GRAPH_COLORS = {
  Urine: "#00A0FF", // 尿
  Feces: "#ffa000", // 便
  UrineAndFeces: "#C01800", // 尿・便
  None: "#BFBFBF", // 無し、通知なし
  Alert: "#F32100", // ヘルプパッドアラート
  Notice: "#F6C003", // そろそろアラート
  Leaked: "#0033DD", // 漏れ
};

export type AggregationGraphProps = {
  id: string;
  loading: boolean;
  error?: unknown;
  title: string;
  onClickDelete: (id: string) => void;
} & Required<AggregationDataTypeCombination>;

export const AggregationBarGraph = ({
  id,
  loading,
  error,
  title,
  onClickDelete,
  target,
  data,
}: AggregationGraphProps) => {
  return (
    <GraphRoot id={id} onClickDelete={onClickDelete} loading={loading} error={error}>
      <GraphTitle>{title}</GraphTitle>

      {target === "detection" && <DetectionAggregationGraphBody data={data} />}
      {target === "alert" && <AlertAggregationGraphBody data={data} />}
      {target === "excretion" && <ExcretionAggregationGraphBody data={data} />}
      {target === "leaked" && <LeakedAggregationGraphBody data={data} />}
    </GraphRoot>
  );
};

/**
 * 排泄検知累計の積み上げ棒グラフ
 */
export const DetectionAggregationGraphBody = ({ data }: { data: TargetDetectionDataType[] }) => {
  const { t } = useTranslation();
  const [hiddenItems, setHiddenItems] = useState<string[]>([]);
  const hiddenItemsSet = useMemo(() => new Set(hiddenItems), [hiddenItems]);

  const _data = useMemo(() => toGraphData(data), [data]);

  return (
    <>
      <GraphBody>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={_data} margin={GRAPH_MARGIN}>
            <CartesianGrid strokeDasharray={STROKE_DASH_ARRAY} />
            <XAxis dataKey="name" domain={[0, "dataMax"]} interval={1} />
            <YAxis allowDecimals={false} />
            <Tooltip />
            {!hiddenItemsSet.has("urine") && (
              <Bar
                isAnimationActive={false}
                dataKey="urine"
                name={t("common.excretion.urine")}
                stackId="a"
                fill={GRAPH_COLORS.Urine}
              />
            )}
            {!hiddenItemsSet.has("feces") && (
              <Bar
                isAnimationActive={false}
                dataKey="feces"
                name={t("common.excretion.feces")}
                stackId="a"
                fill={GRAPH_COLORS.Feces}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </GraphBody>
      <MyLegend
        items={[
          { dataKey: "urine", name: t("common.excretion.urine"), color: GRAPH_COLORS.Urine },
          { dataKey: "feces", name: t("common.excretion.feces"), color: GRAPH_COLORS.Feces },
        ]}
        hiddenItems={hiddenItems}
        onChangeHiddenItems={setHiddenItems}
      />
    </>
  );
};

/**
 * 排泄通知累計の積み上げ棒グラフ
 */
export const AlertAggregationGraphBody = ({ data }: { data: TargetAlertDataType[] }) => {
  const { t } = useTranslation();
  const [hiddenItems, setHiddenItems] = useState<string[]>([]);
  const hiddenItemsSet = useMemo(() => new Set(hiddenItems), [hiddenItems]);

  const _data = useMemo(() => toGraphData(data), [data]);

  return (
    <>
      <GraphBody>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={_data} margin={GRAPH_MARGIN}>
            <CartesianGrid strokeDasharray={STROKE_DASH_ARRAY} />
            <XAxis dataKey="name" domain={[0, "dataMax"]} interval={1} />
            <YAxis allowDecimals={false} />
            <Tooltip />
            {!hiddenItemsSet.has("alert") && (
              <Bar
                isAnimationActive={false}
                dataKey="alert"
                name={t("common.notification.alert")}
                stackId="a"
                fill={GRAPH_COLORS.Alert}
              />
            )}
            {!hiddenItemsSet.has("notice") && (
              <Bar
                isAnimationActive={false}
                dataKey="notice"
                name={t("common.notification.notice")}
                stackId="a"
                fill={GRAPH_COLORS.Notice}
              />
            )}
            {!hiddenItemsSet.has("none") && (
              <Bar
                isAnimationActive={false}
                dataKey="none"
                name={t("tool.aggregation_graph.no_notification")}
                stackId="a"
                fill={GRAPH_COLORS.None}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </GraphBody>
      <MyLegend
        items={[
          { dataKey: "alert", name: t("common.notification.alert"), color: GRAPH_COLORS.Alert },
          { dataKey: "notice", name: t("common.notification.notice"), color: GRAPH_COLORS.Notice },
          { dataKey: "none", name: t("tool.aggregation_graph.no_notification"), color: GRAPH_COLORS.None },
        ]}
        hiddenItems={hiddenItems}
        onChangeHiddenItems={setHiddenItems}
      />
    </>
  );
};

// 記録累計の積み上げ棒グラフ
export const ExcretionAggregationGraphBody = ({ data }: { data: TargetExcretionDataType[] }) => {
  const { t } = useTranslation();
  const [hiddenItems, setHiddenItems] = useState<string[]>([]);
  const hiddenItemsSet = useMemo(() => new Set(hiddenItems), [hiddenItems]);

  const _data = useMemo(() => toGraphData(data), [data]);

  return (
    <>
      <GraphBody>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={_data} margin={GRAPH_MARGIN}>
            <CartesianGrid strokeDasharray={STROKE_DASH_ARRAY} />
            <XAxis dataKey="name" domain={[0, "dataMax"]} interval={1} />
            <YAxis allowDecimals={false} />
            <Tooltip />
            {!hiddenItemsSet.has("urine") && (
              <Bar
                isAnimationActive={false}
                dataKey="urine"
                name={t("common.excretion.urine")}
                stackId="a"
                fill={GRAPH_COLORS.Urine}
              />
            )}
            {!hiddenItemsSet.has("feces") && (
              <Bar
                isAnimationActive={false}
                dataKey="feces"
                name={t("common.excretion.feces")}
                stackId="a"
                fill={GRAPH_COLORS.Feces}
              />
            )}
            {!hiddenItemsSet.has("urine_and_feces") && (
              <Bar
                isAnimationActive={false}
                dataKey="urine_and_feces"
                name={t("common.excretion.urine_and_feces")}
                stackId="a"
                fill={GRAPH_COLORS.UrineAndFeces}
              />
            )}
            {!hiddenItemsSet.has("none") && (
              <Bar
                isAnimationActive={false}
                dataKey="none"
                name={t("common.excretion.none")}
                stackId="a"
                fill={GRAPH_COLORS.None}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </GraphBody>
      <MyLegend
        items={[
          { dataKey: "urine", name: t("common.excretion.urine"), color: GRAPH_COLORS.Urine },
          { dataKey: "feces", name: t("common.excretion.feces"), color: GRAPH_COLORS.Feces },
          {
            dataKey: "urine_and_feces",
            name: t("common.excretion.urine_and_feces"),
            color: GRAPH_COLORS.UrineAndFeces,
          },
          { dataKey: "none", name: t("common.excretion.none"), color: GRAPH_COLORS.None },
        ]}
        hiddenItems={hiddenItems}
        onChangeHiddenItems={setHiddenItems}
      />
    </>
  );
};

// 漏れの積み上げ棒グラフ
export const LeakedAggregationGraphBody = ({ data }: { data: TargetLeakedDataType[] }) => {
  const { t } = useTranslation();
  const [hiddenItems, setHiddenItems] = useState<string[]>([]);
  const hiddenItemsSet = useMemo(() => new Set(hiddenItems), [hiddenItems]);

  const _data = useMemo(() => toGraphData(data), [data]);

  return (
    <>
      <GraphBody>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart data={_data} margin={GRAPH_MARGIN}>
            <CartesianGrid strokeDasharray={STROKE_DASH_ARRAY} />
            <XAxis dataKey="name" domain={[0, "dataMax"]} interval={1} />
            <YAxis allowDecimals={false} />
            <Tooltip />
            {!hiddenItemsSet.has("leaked") && (
              <Bar
                isAnimationActive={false}
                dataKey="leaked"
                name={t("common.excretion.leaked")}
                stackId="a"
                fill={GRAPH_COLORS.Leaked}
              />
            )}
          </BarChart>
        </ResponsiveContainer>
      </GraphBody>
      <MyLegend
        items={[{ dataKey: "leaked", name: t("common.excretion.leaked"), color: GRAPH_COLORS.Leaked }]}
        hiddenItems={hiddenItems}
        onChangeHiddenItems={setHiddenItems}
      />
    </>
  );
};

/**
 * recharts のグラフ用に
 * {
 *   a: [0, 1, 2, ...],
 *   b: [10, 11, 12, ...]
 * }
 *
 * を
 *
 * [
 *   { a: 0, b: 10},
 *   { a: 1, b: 11},
 *   { a: 2, b: 12},
 *   ...
 * ]
 * に変換する
 */
function toGraphData<K extends string>(
  dataArray: {
    id: number;
    values: Record<K, number[]>;
  }[]
): ({ name: string } & Record<K, number>)[] {
  const current = new Date(0);
  const arr: ({ name: string } & Record<K, number>)[] = [];

  for (let i = 0; i < 48; i++) {
    const hours = current.getUTCHours().toString().padStart(1, "0");
    const minutes = current.getUTCMinutes().toString().padStart(2, "0");
    current.setTime(current.getTime() + 30 * 60 * 1000);

    const timeLabel = `${hours}:${minutes}`;
    const dataEntry: Record<K, number> = {} as Record<K, number>;

    for (const data of dataArray) {
      const keys = Object.keys(data.values) as K[];

      for (const key of keys) {
        if (data.values.hasOwnProperty(key)) {
          dataEntry[key] = (dataEntry[key] ?? 0) + (data.values[key][i] ?? 0);
        }
      }
    }
    arr.push({ name: timeLabel, ...dataEntry });
  }

  return arr;
}

/**
 * グラフ本体
 *
 * 削除ボタンを absoltue で配置する都合用意
 */
const GraphRoot = ({
  id,
  children,
  loading,
  error,
  onClickDelete,
}: {
  id: string;
  children: ReactNode;
  loading: boolean;
  error?: unknown;
  onClickDelete: (id: string) => void;
}) => {
  const { t } = useTranslation();
  const [isHovered, setisHovered] = useState(false);

  const handleClickDelete = useCallback(() => onClickDelete(id), [onClickDelete, id]);

  return (
    <Box
      bgcolor={"white"}
      border="1px solid #CCC"
      height="100%"
      mx={2}
      borderRadius={"0.5rem"}
      position={"relative"}
      onMouseEnter={() => setisHovered(true)}
      onMouseLeave={() => setisHovered(false)}
    >
      {/* グラフ表示 */}
      <Box
        height="100%"
        display={"flex"}
        flexDirection={"column"}
        sx={{
          opacity: loading || error != null ? 0.4 : undefined,
          pointerEvents: loading || error != null ? "none" : undefined,
        }}
      >
        {children}
      </Box>
      {/* 読み込み中表示 */}
      {loading && <LoadingMessage>{t("tool.aggregation_graph.loading")}</LoadingMessage>}
      {/* エラー表示 */}
      {error != null && !loading && <ErrorMessage>{t("tool.aggregation_graph.load_error")}</ErrorMessage>}
      {/* 閉じるボタン */}
      {isHovered && (
        <Box position="absolute" right="0.5rem" top={"0.5rem"}>
          <DeleteButton onClick={handleClickDelete}>
            <ClearIcon />
          </DeleteButton>
        </Box>
      )}
    </Box>
  );
};

/**
 * グラフボディ
 */
const GraphBody = (props: { children: ReactNode }) => {
  return (
    <Box flex={1} display={"flex"}>
      {props.children}
    </Box>
  );
};

/**
 * グラフタイトル
 */
const GraphTitle = (props: { children: ReactNode }) => {
  return (
    <Box textAlign={"center"} fontSize={"1.125rem"} pt={0.5} color={"#666"}>
      {props.children}
    </Box>
  );
};

/**
 * 自前の凡例
 *
 * クリックすることで表示・非表示を切り替える機能ために追加
 */
const MyLegend = (props: {
  items: { dataKey: string; name: string; color: string }[];
  hiddenItems: string[];
  onChangeHiddenItems: (next: string[]) => void;
}) => {
  const { hiddenItems, onChangeHiddenItems } = props;
  const hiddenSet = useMemo(() => new Set(hiddenItems), [hiddenItems]);
  const onClickItem = useCallback(
    (dataKey: string) => {
      const next = new Set(hiddenSet);
      next.has(dataKey) ? next.delete(dataKey) : next.add(dataKey);
      onChangeHiddenItems(Array.from(next));
    },
    [hiddenSet, onChangeHiddenItems]
  );

  return (
    <Box display={"flex"} justifyContent={"center"} pb={"4px"}>
      {props.items.map((item) => (
        <MyLegendItem
          key={item.dataKey}
          hidden={hiddenSet.has(item.dataKey)}
          dataKey={item.dataKey}
          name={item.name}
          color={item.color}
          onClick={onClickItem}
        />
      ))}
    </Box>
  );
};

/**
 * 自前の凡例要素
 */
const MyLegendItem = (props: {
  hidden: boolean;
  dataKey: string;
  name: string;
  color: string;
  onClick: (dataKey: string) => void;
}) => {
  return (
    <Box
      boxSizing={"border-box"}
      position={"relative"}
      sx={{
        cursor: "pointer",
        ":not(:last-child)": {
          mr: 1.5,
        },
      }}
      onClick={() => props.onClick(props.dataKey)}
    >
      <Box mx={0.5} display={"flex"} alignItems={"center"} sx={{ opacity: props.hidden ? 0.5 : null }}>
        <Box bgcolor={props.color} width={"15px"} height={"15px"} mx={0.5} />
        <Typography color={"#666"}>{props.name}</Typography>
      </Box>
      {props.hidden && <Box position={"absolute"} top={"50%"} width={"100%"} height={"2px"} bgcolor={"#333"} />}
    </Box>
  );
};

const LoadingMessage = styled(Box)<BoxProps>(() => ({
  position: "absolute",
  right: "50%",
  top: "35%",
  fontSize: "150%",
  color: "#666",
  transform: "translate(50%, 50%)",
}));

const ErrorMessage = styled(Box)<BoxProps>(() => ({
  position: "absolute",
  right: "50%",
  top: "35%",
  fontSize: "150%",
  color: "#C33",
  transform: "translate(50%, 50%)",
}));

const DeleteButton = styled(Button)<IconButtonProps>(({ disabled }) => ({
  borderRadius: "0.5rem",
  fontSize: "1.2rem",
  color: "#FFF",
  height: "36px",
  width: "36px",
  minWidth: "36px",
  opacity: disabled ? 0.5 : 1,
  backgroundColor: "#606060",
  "&:hover": {
    backgroundColor: "#909090",
  },
}));
