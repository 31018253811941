import { TFunction } from "i18next";

export const ExcretionInputError = {
  InvalidDate: "invalid_date", // 対応日時が不正
  UnselectedExcretion: "unselected_excretion", // 対応内容が未選択
  UnselectedLeaked: "unselected_leaked", // 漏れが未選択
  KarteTooLong: "karte_too_long", // 限度数を超えている
} as const;

export type ExcretionInputErrorType = typeof ExcretionInputError[keyof typeof ExcretionInputError];

export function toExcretionInputErrorMessage(value: ExcretionInputErrorType, t: TFunction) {
  switch (value) {
    case ExcretionInputError.InvalidDate:
      return t("module.excretion_support.warning_invalid_date");
    case ExcretionInputError.UnselectedExcretion:
      return t("module.excretion_support.warning_unselected_excretion");
    case ExcretionInputError.UnselectedLeaked:
      return t("module.excretion_support.warning_unselected_leaked");
    case ExcretionInputError.KarteTooLong:
      return t("module.excretion_support.warning_karte_too_long");
    default:
      return "---";
  }
}
