import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { Tooltip, Grid, Typography, Box } from "@mui/material";
import {
  Constants,
  getDeviceErrorCode,
  getDeviceErrorItem,
  getDeviceErrorMessage,
} from "src/constants/commonConstants";

import { groupColor } from "src/constants/groupColor";
import { MiniChart } from "./MiniChart";
import { calculateAge } from "src/utils/dateUtil";
import { DeviceErrorIcon } from "src/modules/parts/DeviceErrorIcon";
import { ReactNode } from "react";
import { ModelsIntegrationCareSubjects } from "src/store/helppadApi";
import { IntegrationAppNames } from "src/modules/parts/IntegrationAppNames";
import { useTranslation } from "react-i18next";

type Props = {
  groupId: number | undefined;
  groupName: string | undefined;
  roomName: string;
  name: string;
  urineCount: number;
  fecesCount: number;
  kana: string;
  gender: string;
  birthDate: string;
  integrationSettings: ModelsIntegrationCareSubjects[] | undefined; // 連携設定(システムが連携している場合でなく、入居者ごとに個別に連携設定されている場合に値が入る)
  device_error: number | undefined;
};

export const UserInfo = ({
  groupId,
  groupName,
  roomName,
  name,
  urineCount,
  fecesCount,
  kana,
  gender,
  birthDate,
  integrationSettings,
  device_error,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Grid container direction="column">
      <Box
        display={"flex"}
        flexDirection={"column"}
        sx={{
          width: "100%",
          height: 500,
          border: "1px solid #d9d9d9",
          borderRadius: "9px",
          backgroundColor: "#FFFFFF",
          boxSizing: "border-box",
        }}
      >
        <Box
          flex={1}
          sx={{
            px: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-start",
            backgroundColor: groupId ? groupColor(groupId) : undefined,
            borderBottom: "1px solid #d9d9d9",
            borderRadius: "8px 8px 0 0",
          }}
        >
          <Typography
            sx={{
              px: 2,
              maxWidth: "290px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              fontSize: 30,
            }}
          >
            {groupName}
          </Typography>
        </Box>
        <Box
          flex={1}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "1px solid #d9d9d9",
          }}
        >
          <Typography
            sx={{
              fontSize: 30,
              fontWeight: "bold",
            }}
          >
            {roomName}
          </Typography>
        </Box>
        <Box
          flex={2}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "1px solid #d9d9d9",
            position: "relative",
          }}
        >
          {device_error ? (
            <Tooltip
              title={
                <>
                  <Typography variant="body1" gutterBottom>
                    {t(getDeviceErrorCode(device_error) ?? "")}
                    <br />
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    {t(getDeviceErrorItem(device_error) ?? "")}
                    <br />
                  </Typography>
                  <Typography variant="caption" gutterBottom>
                    {t(getDeviceErrorMessage(device_error) ?? "")}
                  </Typography>
                </>
              }
              placement="right-start"
            >
              <Grid position="absolute" top="5px" right="10px">
                <DeviceErrorIcon />
              </Grid>
            </Tooltip>
          ) : null}
          <Typography
            sx={{
              fontSize: 36,
            }}
          >
            {name}
          </Typography>
        </Box>
        <Box flex={2} display={"flex"}>
          <Box
            flex={1}
            display={"flex"}
            flexDirection={"column"}
            sx={{
              py: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRight: "1px solid #d9d9d9",
            }}
          >
            <Typography
              sx={{
                fontSize: 30,
                color: "#0f1c73",
              }}
            >
              {t("common.excretion.urine")} {urineCount}
            </Typography>
            <MiniChart count={urineCount} color="#7a81ac" />
          </Box>
          <Box
            flex={1}
            display={"flex"}
            flexDirection={"column"}
            sx={{
              py: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: 30,
                color: "#fa6413",
              }}
            >
              {t("common.excretion.feces")} {fecesCount}
            </Typography>
            <MiniChart count={fecesCount} color="#f0a073" />
          </Box>
        </Box>
      </Box>
      <Grid container sx={{ width: 384 }} gap={1} p={2} mt={1} direction="column">
        <Grid container>
          <Label>{t("common.care_subject.kana")}</Label>
          <Value>{kana}</Value>
        </Grid>
        <Grid container>
          <Label>{t("common.care_subject.gender")}</Label>
          <Value>{gender}</Value>
        </Grid>
        <Grid container>
          <Label>{t("common.care_subject.birthday")}</Label>
          <Value>
            {format(new Date(birthDate), Constants.DATE_FORMAT_BIRTH_DATE, { locale: ja })} (
            {calculateAge(new Date(birthDate))}
            {t("common.care_subject.years_old")})
          </Value>
        </Grid>
        {integrationSettings != null && integrationSettings.length > 0 && (
          <Grid container>
            <Label>{t("care_subject.user_info.integration")}</Label>
            <Value>
              <IntegrationAppNames integrationSettings={integrationSettings} />
            </Value>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

const Label = ({ children }: { children: ReactNode }) => (
  <Grid item xs={3}>
    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>{children}</Typography>
  </Grid>
);

const Value = ({ children }: { children: ReactNode }) => (
  <Grid item xs={7}>
    <Typography sx={{ fontSize: 16, fontWeight: "bold" }}>: {children}</Typography>
  </Grid>
);
