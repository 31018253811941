import { styled } from "@mui/system";
import { Avatar, Box, IconButton, Typography, MenuItem } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { TableRowMoreDropdown } from "src/modules/table/parts/TableRowMoreDropdown";
import { ChangeMemoDialog, useChangeMemoDialog } from "src/modules/dialog/ChangeMemoDialog";
import { Fragment, useState, useCallback, useMemo, memo } from "react";
import { useGlobalProps } from "src/store/GlobalProps";
import { ModelsCommentLog, useGetStaffQuery } from "src/store/helppadApi";
import { usePostCommentLogMutation, usePatchCommentLogMutation } from "src/store/enhancedApi";
import { formatLogDate } from "..";
import { Constants } from "src/constants/commonConstants";
import { DeleteMemoDialog, useDeleteMemoDialog } from "src/modules/dialog/DeleteMemoDialog";
import { ReadMoreButton } from "./ReadMoreButton";
import { useTranslation } from "react-i18next";
import { useLocale } from "src/utils/localeMap";

type Props = {
  logs: ModelsCommentLog[];
  threadId: number;
  showReadMoreButton?: boolean;
  isReadingMore?: boolean;
  onClickReadMore?: () => void;
  fetchLatestCommentLog: () => void;
  deleteCommentLog: (args: { id?: number }) => Promise<void>;
  updateCommentLog: (args: { id: number; message: string }) => void;
};

export const CommentLogs = memo(
  ({
    logs,
    threadId,
    showReadMoreButton,
    isReadingMore,
    onClickReadMore,
    fetchLatestCommentLog,
    deleteCommentLog,
    updateCommentLog,
  }: Props) => {
    const { t } = useTranslation();
    const locale = useLocale();

    const [submitting, setSubmitting] = useState(false);
    const [newMemo, setNewMemo] = useState<string>("");
    const {
      memo,
      registering,
      registered,
      showDialog: showChangeMemoDialog,
      hideDialog: hideChangeMemoDialog,
      onChangeMemoText,
      saveChangeMemo,
    } = useChangeMemoDialog(fetchLatestCommentLog, updateCommentLog);

    const {
      open: openDeleteMemoDialog,
      deleting,
      deleted,
      target: deleteTarget,
      showDialog: showDeleteMemoDialog,
      hideDialog: hideDeleteMemoDialog,
      deleteMemo,
    } = useDeleteMemoDialog({ deleteCommentLog });

    const { staffId } = useGlobalProps();
    const staff = useGetStaffQuery({ id: staffId! }, Constants.POLLING_INTERVAL).data;
    const staffName = staff?.display_name ?? staff?.name;
    const [postCommentLog] = usePostCommentLogMutation();
    const [patchCommentLog] = usePatchCommentLogMutation();
    const isCreateMemoButtonDisabled = useMemo(
      () => "" === newMemo || Constants.MAX_MEMO_LENGTH < newMemo.length,
      [newMemo]
    );

    const createMemo = useCallback(async () => {
      if (!staffId || !staffName) {
        throw new Error();
      }
      if (submitting) {
        return;
      }
      setSubmitting(true);
      const res = await postCommentLog({
        modelsCommentLogPostIn: {
          staff_id: staffId,
          message: newMemo,
          input_person: staffName,
          thread_id: threadId,
        },
      });
      setNewMemo("");
      // FIXME: メモ投稿直後にメモ履歴が更新されないためSleepを入れたあとに投稿した編集を更新してリストを更新している
      // スマートな方法ではないので解決案があればリファクタしたい
      await new Promise((s) => setTimeout(s, 1000));
      const { data } = res as { data: ModelsCommentLog };
      data &&
        data.message &&
        patchCommentLog({
          id: data.id!,
          modelsCommentLogPatchIn: {
            message: data.message,
          },
        });
      setSubmitting(false);
      fetchLatestCommentLog();
    }, [submitting, newMemo, threadId, staffId, staffName, patchCommentLog, postCommentLog, fetchLatestCommentLog]);

    return (
      <Fragment>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            border: "1px solid #e6e6e6",
            borderRadius: "9px",
            backgroundColor: "#f7f7f7",
          }}
        >
          <Header>{t("care_subject.comment_logs.memo_history")}</Header>
          <Divider />
          <Container>
            {logs.map((log, index) => {
              return (
                <Box display="flex" key={`Comment-${index}`}>
                  <Typography
                    sx={{
                      p: 1,
                      width: "90px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      flexShrink: 0,
                      fontSize: 16,
                    }}
                  >
                    {log.input_person!}
                  </Typography>
                  <Box sx={{ p: 1, width: "100%", Width: "310px", wordBreak: "break-all" }}>
                    <Box
                      sx={{
                        display: "inline-flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                        width: "100%",
                      }}
                    >
                      <Typography sx={{ fontSize: 14, alignSelf: "center" }}>
                        {formatLogDate(log.created_at!, locale)}
                      </Typography>
                      <TableRowMoreDropdown IconButtonProps={{ size: "small" }}>
                        <MenuItem onClick={() => showChangeMemoDialog(log.id!, staffName!, log.message!)}>
                          {t("care_subject.comment_logs.edit")}
                        </MenuItem>
                        <MenuItem onClick={() => showDeleteMemoDialog(log.id!, staffName!, log.message!)}>
                          {t("care_subject.comment_logs.delete")}
                        </MenuItem>
                      </TableRowMoreDropdown>
                    </Box>
                    <Typography sx={{ fontSize: 16, whiteSpace: "pre-wrap" }}>{log.message}</Typography>
                  </Box>
                </Box>
              );
            })}
            {/* さらに読み込むボタン */}
            {showReadMoreButton && (
              <ReadMoreButton disabled={isReadingMore} loading={isReadingMore} onClick={onClickReadMore} />
            )}
          </Container>
          <Box display="flex" sx={{ background: "#f7f7f7", height: "120px" }}>
            <Box
              sx={{
                p: "8px",
                width: "94px",
                display: "flex",
                alignItems: "top",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    background: "#FFFFFF",
                    width: "fit-content",
                    px: 1,
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    minWidth: "80px",
                    border: "1px solid #e6e6e6",
                    fontSize: 16,
                  }}
                >
                  {staffName!}
                </Typography>
              </Box>
            </Box>
            <Form>
              <Textarea
                placeholder={t("care_subject.comment_logs.input")}
                value={newMemo}
                onChange={(e) => setNewMemo(e.target.value)}
              />
              <FormFooter>
                <Box sx={{ color: newMemo.length > Constants.MAX_MEMO_LENGTH ? "red" : undefined }}>
                  {newMemo.length}/{Constants.MAX_MEMO_LENGTH}
                </Box>
                <IconButton
                  onClick={createMemo}
                  disabled={isCreateMemoButtonDisabled || submitting}
                  style={{ opacity: isCreateMemoButtonDisabled || submitting ? 0.3 : 1 }}
                >
                  <Avatar sx={{ color: "black", backgroundColor: "#FFFFFF", border: "1px solid #E6E6E6" }}>
                    <FontAwesomeIcon fontSize="16px" icon={faPaperPlane} />
                  </Avatar>
                </IconButton>
              </FormFooter>
            </Form>
          </Box>
        </Box>
        {/* メモ編集ダイアログ */}
        <ChangeMemoDialog
          memo={memo}
          registering={registering}
          registered={registered}
          onChangeMemoText={onChangeMemoText}
          onConfirm={saveChangeMemo}
          onCancel={hideChangeMemoDialog}
        />
        {/* メモ削除ダイアログ */}
        <DeleteMemoDialog
          open={openDeleteMemoDialog}
          deleting={deleting}
          deleted={deleted}
          target={deleteTarget}
          onConfirm={deleteMemo}
          onCancel={hideDeleteMemoDialog}
        />
      </Fragment>
    );
  }
);

const Header = styled("div")`
  border-radius: 4px 4px 0 0;
  background-color: #e6e6e6;
  padding: 8px 0;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
`;

const Divider = styled("div")`
  &:after {
    background-color: red;
    content: "";
    position: absolute;
    height: calc(100% - 64px);
    left: 110px;
    border-right: 1px solid #e6e6e6;
  }
`;

const Container = styled("div")`
  overflow-y: auto;
  height: 612px;
  background: #fff;
`;

const Textarea = styled("textarea")`
  background-color: inherit;
  border: none;
  padding: 8px;
  height: 90px;
  resize: none;
  font-size: 16px;
`;

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormFooter = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
