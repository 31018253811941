import { Box } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

type Props = { name?: string };

export const CareSubjectName = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      fontSize={"14px"}
      boxSizing={"border-box"}
      px={2}
      py={1.5}
      width={"100%"}
      sx={{ background: "rgba(242, 149, 0, 0.3)" }}
    >
      <FontAwesomeIcon icon={faUser} />
      <Box ml={1} display={"inline"}>
        {t("common.care_subject.target_care_subject")}：
      </Box>
      <Box ml={0.5} fontWeight={700} sx={{ display: "inline" }}>
        {props.name ?? "---"}
      </Box>
    </Box>
  );
};
