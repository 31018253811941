import { Box } from "@mui/material";
import { useMemo } from "react";
import { CareSubjectName } from "src/modules/sp/CareSubjectName";
import { SlidePanel } from "src/modules/sp/SlidePanel";
import { ModelsCareSubject, ModelsStaff } from "src/store/helppadApi";
import { ConfirmRegisterExcretionSupportDialog } from "src/modules/dialog/ConfirmExcretionSupportDialog";
import { useAllStaffs } from "src/utils/useAllStaffs";
import { SpExcretionSupportInputForm } from "./parts/SpExcretionSupportInputForm";
import { SpPage } from "src/modules/sp/SpPage";
import { SpExcretionSupportFooterButtons } from "./parts/SpExcretionSupportFooterButtons";
import { useParams } from "react-router-dom";
import { useCareSubjectEditExcretionSupport } from "./hooks/useCareSubjectEditExcretionSupport";
import { useTranslation } from "react-i18next";
import { FetchErrorAlert } from "src/modules/parts/FetchErrorAlert";

type Props = {
  staff: ModelsStaff; // ログインスタッフ
  careSubject: ModelsCareSubject; // 入居者
};

/**
 * [SP用]
 * 登録済み対応登録の編集
 */
export const CareSubjectEditExcretionSupport = ({ staff, careSubject }: Props) => {
  const { t } = useTranslation();
  const { entityId } = useParams();
  const parsedEntityId = useMemo(() => Number(entityId), [entityId]);

  // 対応スタッフID
  const { staffs } = useAllStaffs();

  const {
    error,
    onChangeExcretionSupportType,
    onChangeIsLeaked,
    onChangeUrineAmount,
    onChangeFecesAmount,
    onChangeFecesFirmness,
    onChangeSupportedPlace,
    onChangeDate,
    onChangeTime,
    supportStaff,
    onChangeSupportStaffId,
    onConfirm,
    onCancel,
    disabled,
    openConfirmDialog,
    onClickAcceptOnDialog,
    onClickCancelOnDialog,
    currentTime,
    inputValues,
    status,
  } = useCareSubjectEditExcretionSupport(parsedEntityId, staffs, { supportStaffId: staff.id });

  return (
    <SpPage>
      <SlidePanel title={t("module.excretion_support.edit_title")} disabled={disabled}>
        <Box px={2}>
          <ConfirmRegisterExcretionSupportDialog
            open={openConfirmDialog}
            processing={status === "processing"}
            onRegister={onClickAcceptOnDialog}
            onCancel={onClickCancelOnDialog}
            careSubjectName={careSubject.name ?? "---"}
            supportStaffName={supportStaff?.display_name ?? "---"}
            {...inputValues}
          />
          <Box boxSizing={"border-box"} display={"flex"} flexDirection={"column"} height={"100%"} width={"100%"}>
            <CareSubjectName name={careSubject.name} />
            <Box mb={3} />
            {error != null ? (
              <FetchErrorAlert error={error} notFoundMessage={t("module.excretion_support.already_deleted")} />
            ) : (
              <>
                {/* 入力フォーム */}
                <SpExcretionSupportInputForm
                  currentTime={currentTime}
                  disabled={disabled || status !== "idling"}
                  onChangeExcretionSupportType={onChangeExcretionSupportType}
                  onChangeIsLeaked={onChangeIsLeaked}
                  onChangeUrineAmount={onChangeUrineAmount}
                  onChangeFecesAmount={onChangeFecesAmount}
                  onChangeFecesFirmness={onChangeFecesFirmness}
                  onChangeSupportedPlace={onChangeSupportedPlace}
                  // onChangeKarte={onChangeKarte}
                  onChangeDate={onChangeDate}
                  onChangeTime={onChangeTime}
                  staffs={staffs}
                  onChangeSupportStaffId={onChangeSupportStaffId}
                  {...inputValues}
                />
                <Box flex={1} />
                {/* 操作ボタン */}
                <SpExcretionSupportFooterButtons
                  disabled={disabled}
                  confirmLabel={t("common.button.register_excretion_result")}
                  onConfirm={onConfirm}
                  onCancel={onCancel}
                />
              </>
            )}
          </Box>
        </Box>
      </SlidePanel>
    </SpPage>
  );
};
