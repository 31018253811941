import { ModelsCareSubject } from "src/store/helppadApi";

/** 共通定数クラス */
export class Constants {
  static readonly FACILITY = "facility";
  static readonly LOGIN = "login";
  static readonly PERMISSIONS = "permissions";

  // date format
  static readonly DATE_FORMAT_YYYYMMDD_HHMM = "yyyy/MM/dd HH:mm";
  static readonly DATE_FORMAT_MMDD_HHMM = "MM/dd HH:mm";
  static readonly DATE_FORMAT_BIRTH_DATE = "yyyy/MM/dd";
  static readonly DATE_FORMAT_SUPPORT_DATE = "yyyy-MM-dd";
  static readonly DATE_FORMAT_SUPPORT_HOUR = "HH";
  static readonly DATE_FORMAT_SUPPORT_MINUTE = "mm";

  // JSの経過ミリ秒は1970/01/01からになっており年齢の計算で使用する
  static readonly JS_START_YEAR = 1970;

  static readonly POLLING_INTERVAL = { pollingInterval: 1 * 30 * 1000 };

  static readonly OFF_THRESHOLD = 255;
  static readonly MAX_MEMO_LENGTH = 200;

  // 対応登録時の初期化値
  static readonly INITIAL_EXCRETION_TYPE = 0;

  // ロールID
  static readonly ROLE_MEMBER_NAME = "メンバー";

  // 通知音設定
  static readonly SOUND_VOLUME = 0.5;
  static readonly SELECTED_NOTIFICATION_SOUND = "selected_notification_sound";

  // お知らせ管理
  static readonly CURRENT_ANNOUNCEMENT_ID_KEY = "current_announcement_id";
  static readonly LAST_READ_ANNOUNCEMENT_ID_KEY = "last_read_announcement_id";
  static readonly NOTICE_ENABLE = "1";

  // アラート
  static readonly INITIAL_THRESHOLD = 1;

  // 通知、設定、コメントログの最大ページ数
  // 最新読み込み時は最大でもこのページまでしか読み込まない(useReadMoreListHistoryLog の note 参照)
  static readonly LATEST_MAX_PAGE = 5;

  // 連携先サービス名
  static readonly BLUE_OCEAN_NAME = "BlueOcean";
}

/**
 * 0: その他
 * 1: 男性
 * 2: 女性
 */
export type Gender = 0 | 1 | 2;
export const getGenderLabel = (gender: Gender | undefined) => {
  switch (gender) {
    case 0:
      return "constants.common_constants.gender_other";
    case 1:
      return "constants.common_constants.gender_male";
    case 2:
      return "constants.common_constants.gender_female";
    default:
      throw new Error();
  }
};

/**
 * 0: プッシュ通知しない
 * 1: プッシュ通知する
 */
export type PushNotify = 0 | 1;
export const getPushNotifyLabel = (pushNotify: PushNotify | undefined) => {
  switch (pushNotify) {
    case 0:
      return "constants.common_constants.notice_disable";
    case 1:
      return "constants.common_constants.notice_enable";
    default:
      throw new Error();
  }
};

export type Group = {
  id?: number;
  name: string;
  pushNotify?: PushNotify;
};

export const calculateAge = (careSubject: ModelsCareSubject) => {
  return new Date().getFullYear() - new Date(careSubject.birth_date!).getFullYear();
};

export type pageInfo = {
  page: number;
  size: number;
  totalPages: number;
  totalRows: number;
};

/**
 * デバイスのエラーコード
 */
export const getDeviceErrorCode = (deviceError: number) => {
  switch (deviceError) {
    case 1:
      return "constants.common_constants.error_code_1";
    case 2:
      return "constants.common_constants.error_code_2";
    case 3:
      return "constants.common_constants.error_code_3";
    case 4:
      return "constants.common_constants.error_code_4";
    case 5:
      return "constants.common_constants.error_code_5";
    default:
      return null;
  }
};

/**
 * デバイスのエラーアイテム
 */
export const getDeviceErrorItem = (deviceError: number) => {
  switch (deviceError) {
    case 1:
      return "constants.common_constants.error_item_1";
    case 2:
      return "constants.common_constants.error_item_2";
    case 3:
      return "constants.common_constants.error_item_3";
    case 4:
      return "constants.common_constants.error_item_4";
    case 5:
      return "constants.common_constants.error_item_5";
    default:
      return null;
  }
};

/**
 * デバイスのエラーメッセージ
 */
export const getDeviceErrorMessage = (deviceError: number) => {
  switch (deviceError) {
    case 1:
      return "constants.common_constants.error_msg_1";
    case 2:
      return "constants.common_constants.error_msg_2";
    case 3:
      return "constants.common_constants.error_msg_3";
    case 4:
      return "constants.common_constants.error_msg_4";
    case 5:
      return "constants.common_constants.error_msg_5";
    default:
      return null;
  }
};

export const AlertDetailType = {
  None: 0,
  Soon: 1,
  Alert: 2,
} as const;
