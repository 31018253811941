import { Box, Typography } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { RadioButton } from "src/modules/parts/RadioButton";
import {
  ExcretionSupportedPlace,
  ExcretionSupportedPlaceTypes,
  toExcretionSupportedPlaceTypeName,
} from "src/types/excretionSupportedPlaceType";

type Props = {
  disabled?: boolean;
  current: ExcretionSupportedPlace | undefined;
  onChange: (next: ExcretionSupportedPlace) => void;
};

/**
 * 対応登録: 交換方法の選択フィールド
 */
export const SupportedPlaceSelectField = ({ disabled = false, current, onChange }: Props) => {
  const { t } = useTranslation();
  const handleClick = useCallback((next: ExcretionSupportedPlace) => onChange(next), [onChange]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "16px",
      }}
    >
      <RadioButton
        disabled={disabled}
        current={current}
        value={ExcretionSupportedPlaceTypes.DiaperChange}
        onClick={handleClick}
      >
        <Typography fontSize={"18px"}>
          {toExcretionSupportedPlaceTypeName(ExcretionSupportedPlaceTypes.DiaperChange, t)}
        </Typography>
      </RadioButton>
      <RadioButton
        disabled={disabled}
        current={current}
        value={ExcretionSupportedPlaceTypes.ToiletAssistance}
        onClick={handleClick}
      >
        <Typography fontSize={"18px"}>
          {toExcretionSupportedPlaceTypeName(ExcretionSupportedPlaceTypes.ToiletAssistance, t)}
        </Typography>
      </RadioButton>
    </Box>
  );
};
