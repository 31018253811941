import { useCallback, useMemo } from "react";
import { Grid, MenuItem } from "@mui/material";
import { CommonButton } from "src/modules/parts/CommonButton";
import {
  CreateStaffDialog,
  DeleteStaffDialog,
  EditStaffDialog,
  useCreateStaffDialog,
  useDeleteStaffDialog,
  useEditStaffDialog,
} from "src/modules/dialog/AccountSettingDialogs";
import { useSearchParams } from "react-router-dom";
import { CommonPagination } from "src/modules/parts/CommonPagination";
import {
  useListStaffQuery,
  usePostStaffMutation,
  usePatchStaffMutation,
  useDeleteStaffMutation,
} from "src/store/enhancedApi";
import { Constants } from "src/constants/commonConstants";
import { ListTable } from "../../modules/table/ListTable";
import { TableRowMoreDropdown } from "src/modules/table/parts/TableRowMoreDropdown";
import { useGlobalProps } from "src/store/GlobalProps";
import { useListRoleQuery } from "src/store/helppadApi";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError, QueryStatus } from "@reduxjs/toolkit/dist/query";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

type QueryParams = {
  page: number;
};

export const AccountSetting = () => {
  const { t } = useTranslation();
  const pageSize = 10;
  const { params, changePage } = usePageQueryParams();
  const roleList = useListRoleQuery({ page: 1, size: 10 }).data; // rolesは現状3種類のためページ固定とする
  const { facilityId } = useGlobalProps();
  const staffsList = useListStaffQuery({ page: params.page, size: pageSize }).data;
  const role_master = roleList?.items?.find((role) => role.facility_id === facilityId && role.is_master === true);
  const staffs = staffsList?.items?.filter((staff) => staff.role_id !== role_master?.id);

  const [patchApi, { status: editStatus, reset: resetEdit, error: _editError }] = usePatchStaffMutation();
  const editError = useMemo(() => handleEditStaffError(_editError, t), [_editError, t]);
  const [deleteApi, { status: deleteStatus, reset: resetDelete, error: _deleteError }] = useDeleteStaffMutation();
  const deleteError = useMemo(() => handleDeleteStaffError(_deleteError, t), [_deleteError, t]);

  const {
    showDialog: showEditStaffDialog,
    hideDialog: hideEditStaffDialog,
    ...editStaffDialogProps
  } = useEditStaffDialog();

  const editStaffParams = useMemo(
    () => ({
      id: editStaffDialogProps.staffId,
      loginId: editStaffDialogProps.loginId,
      name: editStaffDialogProps.staffName,
      displayName: editStaffDialogProps.staffDisplayName,
      password: editStaffDialogProps.password,
      requiredChangePassword: editStaffDialogProps.requiredChangePassword,
    }),
    [editStaffDialogProps]
  );

  const {
    showDialog: showDeleteStaffDialog,
    hideDialog: hideDeleteStaffDialog,
    ...deleteStaffDialogProps
  } = useDeleteStaffDialog();
  const deleteStaffParams = useMemo(
    () => ({
      id: deleteStaffDialogProps.id,
    }),
    [deleteStaffDialogProps]
  );

  const handleClickEditRegisterStaff = useCallback(() => {
    patchApi({
      id: editStaffParams.id,
      modelsStaffPatchIn: {
        display_name: editStaffParams.displayName,
        name: editStaffParams.name,
        // パスワードの変更がないのなら送らないこと
        password: editStaffParams.password !== "" ? editStaffParams.password : undefined,
      },
    });
  }, [editStaffParams, patchApi]);
  const editDialogStatus = useDialogStatus(editStatus);

  // 削除ダイアログの削除するボタンが押された際の振る舞い
  const handleClickDeleteStaff = useCallback(() => {
    deleteApi({
      id: deleteStaffParams?.id ? deleteStaffParams?.id : 0,
    });
  }, [deleteStaffParams, deleteApi]);
  const deleteDialogStatus = useDialogStatus(deleteStatus);

  const handleClickEditDropdownStaff = useCallback(
    (staffId?: number) => {
      const staff = staffs?.find((staff) => staff.id === staffId);
      if (staff == null) {
        return;
      }
      resetEdit();
      showEditStaffDialog({
        defaultStaffId: staff.id,
        defaultLoginId: staff.login_id,
        defaultStaffName: staff.name,
        defaultStaffDisplayName: staff.display_name,
      });
    },
    [staffs, resetEdit, showEditStaffDialog]
  );

  const handleClickDeleteDropdownStaff = useCallback(
    (staffId?: number) => {
      const staff = staffs?.find((staff) => staff.id === staffId);
      if (staff == null) {
        return;
      }
      resetDelete(); // スタッフ削除リクエストのstateを初期化
      showDeleteStaffDialog(staff);
    },
    [staffs, resetDelete, showDeleteStaffDialog]
  );

  return (
    <Grid sx={{ background: "#F9F9F9" }} display={"flex"} p={4} flexDirection={"column"} height={"100%"} width={"100%"}>
      {/* スタッフ編集ダイアログ */}
      <EditStaffDialog
        onRegister={handleClickEditRegisterStaff}
        onCancel={hideEditStaffDialog}
        status={editDialogStatus}
        error={editError}
        {...editStaffDialogProps}
      />
      {/* スタッフ削除ダイアログ  */}
      <DeleteStaffDialog
        onDelete={handleClickDeleteStaff}
        onCancel={hideDeleteStaffDialog}
        status={deleteDialogStatus}
        error={deleteError}
        {...deleteStaffDialogProps}
      />
      <Grid pb={2} display={"flex"} justifyContent={"flex-end"}>
        <AddStaffButton />
      </Grid>
      <ListTable>
        {/* テーブルヘッダー */}
        <ListTable.Head>
          <ListTable.Head.Cell pl={2} width={"22%"}>
            {t("common.staff.staff_id")}
          </ListTable.Head.Cell>
          <ListTable.Head.Cell width={"20%"}>{t("common.staff.staff_name")}</ListTable.Head.Cell>
          <ListTable.Head.Cell width={"8%"}>{t("common.staff.display_name")}</ListTable.Head.Cell>
          <ListTable.Head.Cell flex={1}>{t("setting.account_setting.remarks")}</ListTable.Head.Cell>
        </ListTable.Head>
        {/* テーブルボディ */}
        <ListTable.Body>
          {staffs?.map((staff) => (
            <ListTable.Body.Row key={staff.id}>
              <ListTable.Body.Cell pl={2} width={"22%"}>
                {staff.login_id}
              </ListTable.Body.Cell>
              <ListTable.Body.Cell width={"20%"}>{staff.name}</ListTable.Body.Cell>
              <ListTable.Body.Cell width={"8%"}>{staff.display_name}</ListTable.Body.Cell>
              <ListTable.Body.Cell flex={1}></ListTable.Body.Cell>
              <ListTable.Body.Cell pr={2}>
                <MoreOptionDropdown
                  staffId={staff.id}
                  onClickEdit={handleClickEditDropdownStaff}
                  onClickDelete={handleClickDeleteDropdownStaff}
                />
              </ListTable.Body.Cell>
            </ListTable.Body.Row>
          ))}
        </ListTable.Body>
      </ListTable>
      <Grid container flex={1} />
      <CommonPagination
        currentPage={params.page}
        totalCount={staffsList?.page_info?.total_pages}
        onChangePage={changePage}
      />
    </Grid>
  );
};

const MoreOptionDropdown = (props: {
  staffId?: number;
  onClickEdit: (staffId?: number) => void;
  onClickDelete: (staffId?: number) => void;
}) => {
  const { t } = useTranslation();
  const { staffId, onClickEdit, onClickDelete } = props;

  const handleClickEdit = useCallback(() => {
    onClickEdit(staffId);
  }, [onClickEdit, staffId]);

  const handleClickDelete = useCallback(() => {
    onClickDelete(staffId);
  }, [onClickDelete, staffId]);

  return (
    <TableRowMoreDropdown>
      <MenuItem onClick={handleClickEdit}>{t("setting.account_setting.edit_staff")}</MenuItem>
      <MenuItem onClick={handleClickDelete}>{t("setting.account_setting.delete_staff")}</MenuItem>
    </TableRowMoreDropdown>
  );
};

const usePageQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const params: QueryParams = useMemo(() => {
    const page = parseIntParam(searchParams.get("page")) ?? 1;
    return { page };
  }, [searchParams]);

  const changePage = useCallback(
    (next: number) => {
      searchParams.set("page", next < 0 ? "1" : next.toString());
      setSearchParams(searchParams, { replace: true });
    },
    [searchParams, setSearchParams]
  );

  return { params, changePage };
};

function parseIntParam(value: string | null) {
  if (value === null) {
    return undefined;
  }
  const parsed = parseInt(value);
  return isNaN(parsed) ? undefined : parsed;
}

const useDialogStatus = (status: QueryStatus) => {
  const _status = useMemo(() => {
    if (status === QueryStatus.pending) {
      return "processing";
    }
    if (status === QueryStatus.fulfilled) {
      return "finished";
    }
    return "idling";
  }, [status]);

  return _status;
};

const AddStaffButton = () => {
  const { t } = useTranslation();
  const {
    showDialog: showAddStaffDialog,
    hideDialog: hideAddStaffDialog,
    ...createStaffDialogProps
  } = useCreateStaffDialog();

  const createStaffParams = useMemo(
    () => ({
      id: createStaffDialogProps.staffId,
      loginId: createStaffDialogProps.loginId,
      name: createStaffDialogProps.staffName,
      displayName: createStaffDialogProps.staffDisplayName,
      password: createStaffDialogProps.password,
    }),
    [createStaffDialogProps]
  );

  const roleList = useListRoleQuery({ page: 1, size: 10 }).data; // rolesは現状3種類のためページ固定とする
  const { facilityId } = useGlobalProps();
  const role_member = roleList?.items?.find(
    (role) => role.facility_id === facilityId && role.name === Constants.ROLE_MEMBER_NAME
  );

  const [postApi, { status, error, reset }] = usePostStaffMutation({});
  const createError = useMemo(() => handleCreateStaffError(error, t), [error, t]);

  const handleClickRegisterStaff = useCallback(async () => {
    postApi({
      modelsStaffPostIn: {
        login_id: createStaffParams.loginId,
        display_name: createStaffParams.displayName,
        name: createStaffParams.name,
        password: createStaffParams.password,
        role_id: role_member?.id!,
        show_alert: true, // TODO 一旦固定値 適切な値の確認が必要
      },
    });
  }, [createStaffParams, postApi, role_member]);
  const dialogStatus = useDialogStatus(status);

  return (
    <>
      <CommonButton
        label={t("setting.account_setting.create_staff")}
        onClick={() => {
          reset(); // スタッフ追加リクエストのstateを初期化
          showAddStaffDialog();
        }}
      />
      <CreateStaffDialog
        onCancel={hideAddStaffDialog}
        onRegister={handleClickRegisterStaff}
        status={dialogStatus}
        error={createError}
        {...createStaffDialogProps}
      />
    </>
  );
};

function handleCreateStaffError(
  error: FetchBaseQueryError | SerializedError | undefined,
  t: TFunction
): Error | undefined {
  if (error == null) {
    return undefined;
  }
  if ("status" in error) {
    const { status } = error;
    if (status === 409) {
      return Error(t("setting.account_setting.create_error_msg_409"));
    }
  }
  return Error(t("setting.account_setting.create_error_msg_other"));
}

function handleEditStaffError(
  error: FetchBaseQueryError | SerializedError | undefined,
  t: TFunction
): Error | undefined {
  if (error == null) {
    return undefined;
  }
  return Error(t("setting.account_setting.edit_error_msg_other"));
}

function handleDeleteStaffError(
  error: FetchBaseQueryError | SerializedError | undefined,
  t: TFunction
): Error | undefined {
  if (error == null) {
    return undefined;
  }
  return Error(t("setting.account_setting.delete_error_msg_other"));
}
