import { ModelsGroup } from "src/store/helppadApi";

export const UNASSIGNED_GROUP_ID = -1; // どのグループにも属していない入居者を暫定で属していることにする仮ID

export const UNASSIGNED_GROUP: ModelsGroup = { id: UNASSIGNED_GROUP_ID, name: "---" } as const;

export function groupColor(id: number) {
  switch (id % 10) {
    case 1:
      return "#F7D5F2";
    case 2:
      return "#C9CFF2";
    case 3:
      return "#DAF4CA";
    case 4:
      return "#B7EEF1";
    case 5:
      return "#FFDCC3";
    case 6:
      return "#FFD2D2";
    case 7:
      return "#BFFFE0";
    case 8:
      return "#AFD4FF";
    case 9:
      return "#FFF5C1";
    case 0:
      return "#BDFBFF";
    // 未所属
    case UNASSIGNED_GROUP_ID:
    default:
      return "#F9F9F9";
  }
}
