import { Box, Grid, Typography } from "@mui/material";
import { MiniChart } from "./MiniChart";
import { usePageMode } from "src/utils/usePageMode";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  label: string;
  backgroundColor: string;
  urineThreshold: number;
  fecesThreshold: number;
};

const URINE_COLORS = { chart: "#7a81ac", text: "#0f1c73" };
const FECES_COLORS = { chart: "#f0a073", text: "#fa6413" };

/**
 * デスクトップ用の通知設定
 */
const DesktopNotificationSetting = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: "100%",
        height: 190,
        border: "1px solid #e6e6e6",
        borderRadius: 2,
        backgroundColor: props.backgroundColor,
      }}
    >
      <Grid container direction="column">
        <Typography
          sx={{
            display: "flex",
            justifyContent: "center",
            py: 1.5,
            fontSize: 24,
          }}
        >
          {props.label}
        </Typography>
        <Grid container justifyContent="space-around" py={1}>
          <MiniChart count={props.urineThreshold ?? 0} color={URINE_COLORS.chart} size="small" />
          <MiniChart count={props.fecesThreshold ?? 0} color={FECES_COLORS.chart} size="small" />
        </Grid>
        <Grid container justifyContent="center" alignItems={"center"} gap={3} py={1}>
          <PcThhreshold
            label={t("common.excretion.urine")}
            threshold={props.urineThreshold}
            color={URINE_COLORS.text}
          />
          <Typography sx={{ fontSize: 18, display: "flex", alignItems: "center" }}>
            {t("care_subject.notification_setting.or")}
          </Typography>
          <PcThhreshold
            label={t("common.excretion.feces")}
            threshold={props.fecesThreshold}
            color={FECES_COLORS.text}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const PcThhreshold = ({ label, threshold, color }: { label: string; threshold: number; color: string }) => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  // 英語表記の際に ラベル + OFF の幅が広く、改行されるのでフォントサイズを調整
  // デフォルトサイズは 30px
  const fontSize = useMemo(() => {
    if (language === "en" && (threshold === 255 || threshold === 0)) {
      return 24;
    }
    return 30;
  }, [language, threshold]);

  return (
    <Typography sx={{ fontSize, color }}>
      {label} {toThresholdStr(threshold)}
    </Typography>
  );
};

/**
 * SP用の通知設定
 */
const SpNotificationSetting = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      display={"flex"}
      flexDirection="column"
      flexWrap={"wrap"}
      alignItems={"left"}
      width={"100%"}
      boxSizing={"border-box"}
      p={2}
      color={"#222222"}
      borderRadius={2}
      sx={{ backgroundColor: props.backgroundColor }}
    >
      <Box sx={{ whiteSpace: "nowrap" }}>
        <Typography fontWeight={700} mr={2}>
          {props.label}
        </Typography>
      </Box>
      <Box display={"flex"} flexDirection="row" flexWrap={"wrap"} alignItems={"center"}>
        <Box display="flex" alignItems={"center"}>
          <MiniChart
            count={props.urineThreshold == null || props.urineThreshold === 255 ? 0 : props.urineThreshold}
            color={URINE_COLORS.chart}
            size="sp_small"
          />
          <Box minWidth={"60px"}>
            <Typography fontWeight={700} ml={1} color={URINE_COLORS.text}>
              {t("common.excretion.urine")} {toThresholdStr(props.urineThreshold)}
            </Typography>
          </Box>
        </Box>
        <Typography fontWeight={700} fontSize={"14px"} mx={1}>
          {t("care_subject.notification_setting.or")}
        </Typography>
        <Box display="flex" alignItems={"center"}>
          <MiniChart
            count={props.fecesThreshold == null || props.fecesThreshold === 255 ? 0 : props.fecesThreshold}
            color={FECES_COLORS.chart}
            size="sp_small"
          />
          <Typography fontWeight={700} ml={1} color={FECES_COLORS.text}>
            {t("common.excretion.feces")} {toThresholdStr(props.fecesThreshold)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const toThresholdStr = (value: number | undefined): string => (value === 0 || value === 255 ? "OFF" : `${value}`);

/**
 * アラート通知設定
 * 閾値が 0 か 255 のときは OFF 扱い
 */
export const AlertNotificationSetting = (props: { urineThreshold: number; fecesThreshold: number }) => {
  const { t } = useTranslation();
  const { isDesktop } = usePageMode();
  return isDesktop ? (
    <DesktopNotificationSetting
      label={t("care_subject.notification_setting.alert_number")}
      backgroundColor="#fff0ef"
      {...props}
    />
  ) : (
    <SpNotificationSetting label={t("care_subject.notification_setting.alert")} backgroundColor="#fff0ef" {...props} />
  );
};

/**
 * そろそろ通知設定
 * 閾値が 0 か 255 のときは OFF 扱い
 */
export const CautionNotificationSetting = (props: { urineThreshold: number; fecesThreshold: number }) => {
  const { t } = useTranslation();
  const { isDesktop } = usePageMode();
  return isDesktop ? (
    <DesktopNotificationSetting
      label={t("care_subject.notification_setting.notice_number")}
      backgroundColor="#fffcdf"
      {...props}
    />
  ) : (
    <SpNotificationSetting label={t("care_subject.notification_setting.notice")} backgroundColor="#fffcdf" {...props} />
  );
};
