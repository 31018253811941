import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { ChangeEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AggregationType } from "src/types/aggregation";

type Props = {
  type: AggregationType;
  onChange: (next: AggregationType) => void;
};

/**
 * 入居者・グループ・施設全体を選択するラジオボタングループ
 */
export const AggregationTypeRadioGroup = ({ type, onChange }: Props) => {
  const { t } = useTranslation();
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value as AggregationType);
    },
    [onChange]
  );

  return (
    <RadioGroup value={type} onChange={handleChange} row>
      <FormControlLabel value="careSubject" control={<Radio />} label={t("common.care_subject.care_subject")} />
      <FormControlLabel value="group" control={<Radio />} label={t("common.group.group")} />
      <FormControlLabel value="all" control={<Radio />} label={t("tool.aggregation_type_radio_group.all")} />
    </RadioGroup>
  );
};
