import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type LogoutConfirmModalProps = {
  showDialog: boolean;
  onClose: () => void;
  onComplete: () => void;
};

const ConfirmLogout = ({ handleClose, handleLogout }: { handleClose: () => void; handleLogout: () => void }) => {
  const { t } = useTranslation();

  return (
    <DialogContent>
      <Box sx={{ padding: "0 64px 32px" }}>
        <Box sx={{ marginBottom: "32px" }}>{t("module.logout.confirm_logout")}</Box>
      </Box>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <Box display="flex" justifyContent="center" width="100%" gap="30px">
          <SecondaryButton onClick={handleClose}>{t("common.button.cancel")}</SecondaryButton>
          <RegisterButton onClick={handleLogout}>{t("common.button.logout")}</RegisterButton>
        </Box>
      </DialogActions>
    </DialogContent>
  );
};

const CompleteLogout = ({ handleComplete }: { handleComplete: () => void }) => {
  const { t } = useTranslation();
  return (
    <DialogContent>
      <Box sx={{ padding: "0 64px 32px" }}>
        <Box sx={{ marginBottom: "32px" }}>{t("module.logout.logged_out")}</Box>
      </Box>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <SecondaryButton onClick={handleComplete}>{t("common.button.close")}</SecondaryButton>
      </DialogActions>
    </DialogContent>
  );
};

export const LogoutConfirmModal = ({ showDialog, onClose, onComplete }: LogoutConfirmModalProps) => {
  const { t } = useTranslation();
  const [logouted, setLogouted] = useState(false);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleLogout = useCallback(() => {
    setLogouted(true);
  }, []);

  const handleComplete = useCallback(() => {
    onComplete();
    onClose();
  }, [onComplete, onClose]);

  useEffect(() => {
    if (showDialog) {
      setLogouted(false);
    }
  }, [showDialog]);

  return (
    <Dialog
      open={showDialog}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiDialog-paper": {
            width: "100%",
            minWidth: "800px",
            maxWidth: "800px",
          },
        },
      }}
    >
      <DialogTitle sx={{ mt: 3, mb: 2, textAlign: "center", fontSize: "1.75rem" }}>
        {t("module.logout.logout_confirmation")}
        <CloseDialogButton onClick={handleClose} />
      </DialogTitle>
      {logouted ? (
        <CompleteLogout handleComplete={handleComplete} />
      ) : (
        <ConfirmLogout handleLogout={handleLogout} handleClose={handleClose} />
      )}
    </Dialog>
  );
};

export const CloseDialogButton = (props: { onClick: () => void }) => {
  return (
    <IconButton
      onClick={props.onClick}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

const buttonBase = {
  height: "60px",
  width: "160px",
  borderRadius: "30px",
  fontSize: "1.1rem",
  fontWeight: 700,
  color: "#FFF",
} as const;

const SecondaryButton = styled(Button)<ButtonProps>(() => ({
  ...buttonBase,
  backgroundColor: "#606060",
  "&:hover": {
    backgroundColor: "#909090",
  },
  textTransform: "none",
}));

const RegisterButton = styled(Button)<ButtonProps>(() => ({
  ...buttonBase,
  backgroundColor: "#F29501",
  "&:hover": {
    backgroundColor: "#efac47",
  },
  textTransform: "none",
}));
