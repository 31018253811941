import { Box } from "@mui/material";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FetchError } from "src/types/FetchError";

type Props = {
  error?: FetchError;
  notFoundMessage?: string; // 404 エラー時のエラーメッセージを出したい場合はここを指定
};

export const FetchErrorAlert = ({ error, notFoundMessage }: Props) => {
  const { t } = useTranslation();

  const errorMessage = useMemo(() => {
    if (error == null) {
      return null;
    }

    if (isFetchBaseQueryError(error)) {
      if (error.status === 404 && notFoundMessage != null) {
        return notFoundMessage;
      }
    }

    return t("common.error.failed_fetch");
  }, [error, notFoundMessage, t]);

  if (errorMessage == null) {
    return null;
  }

  return (
    <Box sx={{ backgroundColor: "#FFF0F0", color: "#ff6854", p: 2, display: "flex", justifyContent: "center" }}>
      {errorMessage}
    </Box>
  );
};

function isFetchBaseQueryError(error: FetchError): error is FetchBaseQueryError {
  return "status" in error;
}
