import notificationBell2s from "src/assets/sounds/notification_bell_2s.mp3";
import notificationBell8s from "src/assets/sounds/notification_bell_8s.mp3";
import notificationBell16s from "src/assets/sounds/notification_bell_16s.mp3";

/**
 * 通知音に使用するサウンド選択肢
 */
export const NotificationSounds = {
  notification_bell_2s: notificationBell2s,
  notification_bell_8s: notificationBell8s,
  notification_bell_16s: notificationBell16s,
} as const;

export type NotificationSoundKey = keyof typeof NotificationSounds;

export class NotificationConstants {
  static readonly NOTIFICATION_BELL_2S: NotificationSoundKey = "notification_bell_2s";
  static readonly NOTIFICATION_BELL_8S: NotificationSoundKey = "notification_bell_8s";
  static readonly NOTIFICATION_BELL_16S: NotificationSoundKey = "notification_bell_16s";
}
