import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { closeTermsOfUseModal, openTermsOfUseModal, useGlobalProps } from "src/store/GlobalProps";
import { CommonDialog } from "./CommonDialog";
import { Box, Checkbox, FormControlLabel, Typography } from "@mui/material";
import { SpecialZoomLevel, Viewer, Worker } from "@react-pdf-viewer/core";
import { usePageMode } from "src/utils/usePageMode";
import {
  ModelsTerms,
  useGetStaffQuery,
  useListTermsAgreementLogQuery,
  useListTermsQuery,
  usePostTermsAgreementLogMutation,
} from "src/store/helppadApi";
import { Constants } from "src/constants/commonConstants";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { FetchError } from "src/types/FetchError";
import { useTranslation } from "react-i18next";

type State = "idling" | "loading" | "succeed";

type Props = {
  state: State;
  hasReadAndAgreed: boolean; // 読んで同意したかのチェックボックス状態
  onChangeReadAndAgreement: (next: boolean) => void;
  latestTerm?: ModelsTerms;
  error?: FetchError;
  open: boolean; // 表示・非表示
  onAgreement: () => void;
};

export const useTermsOfUseModal = () => {
  const dispatch = useDispatch();

  // チェックボックスで同意したかどうか
  const [hasReadAndAgreed, setHasReadAndAgreed] = useState(false);

  const { staffId, isOpenTermsOfUseModal } = useGlobalProps();

  const [postAgreement, { status, error, reset: resetPostAgreement }] = usePostTermsAgreementLogMutation();

  /**
   * @memo
   * 以下の流れに則ってリクエストを送る(前の処理が正常に終わらない限り次の処理は行わない)
   * 1. スタッフ情報取得
   * 2. スタッフがマスターアカウントで無い場合は最新の利用約款を取得
   * 3. 最新の利用約款がある場合には最新の同意情報を取得
   */

  // ログインしているスタッフ情報
  const {
    data: staff,
    isFetching: isFetchingStaff,
    error: isStaffError,
  } = useGetStaffQuery({ id: staffId! }, Constants.POLLING_INTERVAL);

  // 利用約款情報(マスターアカウントの場合は例外的に確認しない)
  const {
    data: terms,
    isFetching: isFetchingTerms,
    isError: isTermsError,
  } = useListTermsQuery(
    {
      sortKey: "terms_created_at",
      size: 1,
      sortDesc: true,
    },
    {
      ...Constants.POLLING_INTERVAL,
      skip: isFetchingStaff || !staff || staff?.role?.is_master === true, // 先にスタッフ情報が読み込まれていて欲しいためそれまでは読み込まない
    }
  );

  // 同意状況(マスターアカウントの場合は例外的に確認しない)
  const {
    data: agreements,
    isFetching: isFetchingAgreements,
    isError: isAgreementsError,
  } = useListTermsAgreementLogQuery(
    {
      sortKey: "created_at",
      size: 1,
      sortDesc: true,
    },
    {
      ...Constants.POLLING_INTERVAL,
      skip: isFetchingTerms || !terms || staff?.role?.is_master === true, // 先に最新の約款情報を読み込まれいてほしいためそれまでは読み込まない
    }
  );

  // 同意するボタンが押された際の振る舞い
  const onAgreement = useCallback(() => {
    if (staffId == null || terms == null || terms.items == null) {
      return;
    }
    const termsId = terms.items[0]?.id;
    if (termsId == null) {
      return;
    }

    postAgreement({ modelsTermsAgreementLogPostIn: { staff_id: staffId, terms_id: termsId } });
  }, [postAgreement, staffId, terms]);

  const state: State = useMemo(() => {
    switch (status) {
      case QueryStatus.uninitialized:
        return "idling";
      case QueryStatus.pending:
        return "loading";
      case QueryStatus.fulfilled:
        return "succeed";
      case QueryStatus.rejected:
        return "idling";
    }
  }, [status]);

  // 最新の利用約款を覚えておく(skip を利用している都合でポーリング時に一時的に空になるのでその対策)
  const [latestTerm, setLatestTerm] = useState<ModelsTerms | undefined>();
  useEffect(() => {
    if (terms == null || terms?.items == null || terms.items[0] == null) {
      return;
    }
    setLatestTerm(terms.items[0]);
  }, [latestTerm, terms]);

  const openModal = useCallback(() => {
    dispatch(openTermsOfUseModal());
  }, [dispatch]);

  // 同意のAPIに成功したら自動的にモーダルを閉じる
  useEffect(() => {
    if (status === QueryStatus.fulfilled) {
      dispatch(closeTermsOfUseModal());

      // 1秒後に同意のリクエスト状況やstateをリセット(即時やると見た目に影響が出るため)
      const id = setTimeout(() => {
        resetPostAgreement();
        setHasReadAndAgreed(false);
      }, 1000);
      return () => {
        clearTimeout(id);
      };
    }
  }, [dispatch, status, resetPostAgreement]);

  const isFetching = useMemo(
    () => isFetchingStaff || isFetchingTerms || isFetchingAgreements,
    [isFetchingStaff, isFetchingTerms, isFetchingAgreements]
  );
  const isError = useMemo(
    () => isStaffError || isTermsError || isAgreementsError,
    [isStaffError, isTermsError, isAgreementsError]
  );

  useEffect(() => {
    // エラーの場合は何もしない(1分後のリトライに賭ける)
    if (isError) {
      return;
    }

    // どれかが読込中なら何もしない
    if (isFetching) {
      return;
    }

    // データは揃っている場合のみ処理(マスターアカウントの場合はここの制約に引っかかる)
    if (terms == null || agreements == null) {
      return;
    }

    const latestTerm = terms.items?.[0];
    const latestAgreement = agreements.items?.[0];

    // 最新の利用約款がないのなら何もしない(※最新の同意が無い場合は初回だけあり得る)
    if (latestTerm == null) {
      return;
    }

    // 約款があるが一度も同意していないのでモーダル表示
    if (latestAgreement == null) {
      openModal();
      return;
    }

    // 日付情報が取れないのなら何もしない
    if (latestTerm.terms_created_at == null || latestAgreement.created_at == null) {
      return;
    }

    // 2つの状況を比較し、約款のほうが新しい場合はモーダル表示
    const latestTermCreatedAt = new Date(latestTerm.terms_created_at);
    const latestAgreementCreatedAt = new Date(latestAgreement.created_at);
    if (latestTermCreatedAt.getTime() > latestAgreementCreatedAt.getTime()) {
      openModal();
    }

    // ここまで来たのなら同意のほうが最新なので何もしない
  }, [openModal, isFetching, isError, terms, agreements]);

  return useMemo(
    () => ({
      state,
      hasReadAndAgreed,
      latestTerm,
      onChangeReadAndAgreement: setHasReadAndAgreed,
      error,
      isOpenTermsOfUseModal,
      onAgreement,
    }),
    [state, hasReadAndAgreed, latestTerm, error, isOpenTermsOfUseModal, onAgreement]
  );
};

/**
 * 利用約款表示モーダル
 */
export const TermsOfUseModal = ({
  state,
  hasReadAndAgreed,
  onChangeReadAndAgreement,
  latestTerm,
  error,
  open,
  onAgreement,
}: Props) => {
  const { t } = useTranslation();
  const { isDesktop } = usePageMode();

  const handleChangeHasReadAndAgreed = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChangeReadAndAgreement(event.target.checked);
    },
    [onChangeReadAndAgreement]
  );

  const alertMessages = useMemo(() => {
    if (error == null) {
      return [];
    }
    return [t("common.error.failed_fetch")];
  }, [error, t]);

  const disabled = useMemo(() => state !== "idling", [state]);

  return (
    <CommonDialog
      dialogOpen={open}
      title={isDesktop ? t("module.terms_of_use.title_pc") : t("module.terms_of_use.title_sp")}
      // SP版では同意は行わさせない
      acceptButtonName={isDesktop ? t("common.button.agree") : undefined}
      onAccept={isDesktop ? onAgreement : undefined}
      isProcessed={state === "loading" || state === "succeed"} // 成功時はそのまま閉じさせる
      isChecked={hasReadAndAgreed && !disabled}
      alertMessages={alertMessages}
      footerContent={
        <>
          {isDesktop && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              {/* 同意チェックボックス */}
              <FormControlLabel
                sx={{ "& .MuiFormControlLabel-label": { fontSize: 20 } }}
                control={
                  <Checkbox
                    disabled={disabled}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    checked={hasReadAndAgreed}
                    onChange={handleChangeHasReadAndAgreed}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={t("module.terms_of_use.read_and_agreement")}
              />
            </Box>
          )}
        </>
      }
    >
      {isDesktop ? (
        <>
          <Typography sx={{ mb: 2 }}>{t("module.terms_of_use.description_pc")}</Typography>
          {/* もし最新の利用約款が表示されている際に最新の約款が更新された場合には強制的に pdf を再レンダリングしたいため key を利用 */}
          {latestTerm != null && (
            <Box
              key={`term-${latestTerm.terms_created_at ?? "---"}`}
              sx={{ mb: 2, maxHeight: "400px", overflowY: "auto" }}
            >
              <Worker workerUrl="/libs/pdf.worker.min.js">
                <Viewer defaultScale={SpecialZoomLevel.PageWidth} fileUrl={`/terms/${latestTerm.filepath}`} />
              </Worker>
            </Box>
          )}
        </>
      ) : (
        <>
          <Typography sx={{ mb: 2 }}>{t("module.terms_of_use.description_sp1")}</Typography>
          <Typography sx={{ mb: 2 }}>{t("module.terms_of_use.description_sp2")}</Typography>
        </>
      )}
    </CommonDialog>
  );
};
