import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const BackButton = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Button
      variant="outlined"
      color="secondary"
      disableElevation
      size="large"
      sx={{
        paddingX: 2,
        color: "black",
        textTransform: "none",
      }}
      startIcon={<NavigateBeforeIcon sx={{ position: "relative", top: 0.5 }} />}
      onClick={() => {
        navigate(-1);
      }}
    >
      {t("common.button.back")}
    </Button>
  );
};
