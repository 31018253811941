import { TFunction } from "i18next";

/**
 * 尿量や便量
 */
export const Amount = {
  None: 0, // 入力無し
  Trace: 1, // 付着
  Little: 2, // 少量
  Midium: 3, // 中量
  Much: 4, // 多量
} as const;

export type AmountType = typeof Amount[keyof typeof Amount];

export function parseAmountNumber(num: number | undefined): AmountType | undefined {
  if (num == null) {
    return undefined;
  }
  const valid = (Object.values(Amount) as number[]).includes(num);
  return valid ? (num as AmountType) : undefined;
}

export function toAmountName(value: AmountType | undefined, t: TFunction): string {
  switch (value) {
    case Amount.None:
      return t("module.excretion_support.none_input");
    case Amount.Trace:
      return t("common.excretion.amount_trace");
    case Amount.Little:
      return t("common.excretion.amount_little");
    case Amount.Midium:
      return t("common.excretion.amount_midium");
    case Amount.Much:
      return t("common.excretion.amount_much");
  }
  return t("module.excretion_support.none_input");
}
