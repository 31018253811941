import { Alert, Box, Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { CommonDialog } from "./CommonDialog";
import Close from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { CustomTextInput } from "./parts/CustomTextInput";
import { DialogInput } from "./parts/DialogInput";
import { useTranslation } from "react-i18next";
import { t } from "i18next";

export const AccountSettingDialogStatus = {
  Idling: "idling",
  Processing: "processing",
  Finished: "finished",
} as const;
type AccountSettingDialogStatusType = typeof AccountSettingDialogStatus[keyof typeof AccountSettingDialogStatus];

type InputStaffDataDialogProps = {
  open: boolean; // ダイアログの表示 / 非表示
  status: AccountSettingDialogStatusType;
  error?: Error; // 登録処理で発生したエラー
  staffId: number;
  loginId: string;
  onChangeLoginId: (next: string) => void;
  isValidLoginId?: boolean;
  staffName: string;
  onChangeStaffName: (next: string) => void;
  isValidStaffName?: boolean;
  staffDisplayName: string;
  onChangeStaffDisplayName: (next: string) => void;
  isValidStaffDisplayName?: boolean;
  password: string;
  onChangePassword: (next: string) => void;
  isValidPassword?: boolean;
  onRegister: () => void; // 登録するボタンが押された際の振る舞い
  onCancel: () => void; // 登録するボタンが押されずに閉じられた際の振る舞い(≒ キャンセル)
};

type AddStaffDialogProps = InputStaffDataDialogProps;
type EditStaffDialogProps = InputStaffDataDialogProps;

type DeleteStaffDialogProps = {
  open: boolean; // ダイアログの表示 / 非表示
  status: AccountSettingDialogStatusType;
  error?: Error; // 削除処理で発生したエラー
  id?: number;
  login_id?: string;
  name?: string;
  display_name?: string;
  onDelete: () => void; // 削除するボタンが押された際の振る舞い
  onCancel: () => void; // 削除するボタンが押されずに閉じられた際の振る舞い(≒ キャンセル)
};

/**
 * スタッフの追加ダイアログを使う
 */
export const useCreateStaffDialog = () => {
  const states = useInputStaffDataDialog();
  return states;
};

/**
 * スタッフの編集ダイアログを使う
 */
export const useEditStaffDialog = () => {
  const states = useInputStaffDataDialog();

  const { password } = states;
  const requiredChangePassword = useMemo(() => password.length > 0, [password]);

  return { ...states, requiredChangePassword };
};

/**
 * スタッフの削除ダイアログを使う
 */
export const useDeleteStaffDialog = () => {
  const [open, setOpen] = useState(false);

  const [staff, setStaff] = useState<{ id?: number; login_id?: string; name?: string; display_name?: string }>({
    id: 0,
    login_id: "",
    name: "",
    display_name: "",
  });
  const showDialog = useCallback((staff: { id?: number; login_id?: string; name?: string; display_name?: string }) => {
    setStaff(staff);

    setOpen(true);
  }, []);

  const hideDialog = useCallback(() => {
    setOpen(false);
  }, []);
  return {
    open,
    id: staff.id,
    login_id: staff.login_id,
    name: staff.name,
    display_name: staff.display_name,
    showDialog,
    hideDialog,
  };
};

type InitialParams = {
  defaultLoginId?: string;
  defaultStaffId?: number;
  defaultStaffName?: string;
  defaultStaffDisplayName?: string;
};

/**
 * 追加や編集で使う states やロジックをまとめたもの
 */
const useInputStaffDataDialog = () => {
  const [open, setOpen] = useState(false);

  const [staffId, setStaffId] = useState(0);

  const [loginId, setLoginId] = useState("");
  const [isValidLoginId, setIsValidLoginId] = useState<boolean | undefined>();
  const onChangeLoginId = useCallback((next: string) => {
    setIsValidLoginId(validateLoginId(next));
    setLoginId(next);
  }, []);

  const [staffName, setStaffName] = useState("");
  const [isValidStaffName, setIsValidStaffName] = useState<boolean | undefined>();
  const onChangeStaffName = useCallback((next: string) => {
    setIsValidStaffName(validateStaffName(next));
    setStaffName(next);
  }, []);

  const [staffDisplayName, setStaffDisplayName] = useState("");
  const [isValidStaffDisplayName, setIsValidStaffDisplayName] = useState<boolean | undefined>();
  const onChangeStaffDisplayName = useCallback((next: string) => {
    setIsValidStaffDisplayName(validateStaffDisplayName(next));
    setStaffDisplayName(next);
  }, []);

  const [password, setPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState<boolean | undefined>();
  const onChangePassword = useCallback((next: string) => {
    // 特殊文字を「_-#」だけ許可
    setIsValidPassword(validatePassword(next));
    setPassword(next);
  }, []);

  const showDialog = useCallback((initialParams?: InitialParams) => {
    const { defaultStaffId, defaultLoginId, defaultStaffName, defaultStaffDisplayName } = initialParams ?? {};
    setStaffId(defaultStaffId ?? 0);
    setLoginId(defaultLoginId ?? "");
    setIsValidLoginId(defaultLoginId == null ? undefined : validateLoginId(defaultLoginId));
    setStaffName(defaultStaffName ?? "");
    setIsValidStaffName(defaultStaffName == null ? undefined : validateStaffName(defaultStaffName));
    setStaffDisplayName(defaultStaffDisplayName ?? "");
    setIsValidStaffDisplayName(
      defaultStaffDisplayName == null ? undefined : validateStaffDisplayName(defaultStaffDisplayName)
    );
    setPassword("");
    setIsValidPassword(undefined);

    setOpen(true);
  }, []);

  const hideDialog = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    showDialog,
    hideDialog,
    open,
    loginId,
    onChangeLoginId,
    isValidLoginId,
    staffId,
    staffName,
    onChangeStaffName,
    isValidStaffName,
    staffDisplayName,
    onChangeStaffDisplayName,
    isValidStaffDisplayName,
    password,
    onChangePassword,
    isValidPassword,
  };
};

/**
 * スタッフの追加ダイアログ
 */
export const CreateStaffDialog = (props: AddStaffDialogProps) => {
  return <InputStaffDataDialog mode={InputMode.Create} {...props} />;
};

/**
 * スタッフの編集ダイアログ
 */
export const EditStaffDialog = (props: EditStaffDialogProps) => {
  return <InputStaffDataDialog mode={InputMode.Edit} {...props} />;
};

const InputMode = {
  Create: "create",
  Edit: "edit",
} as const;
type InputModeType = typeof InputMode[keyof typeof InputMode];

/**
 * 追加と編集の共通ダイアログ
 */
const InputStaffDataDialog = (props: InputStaffDataDialogProps & { mode: InputModeType }) => {
  const { t } = useTranslation();
  const { mode, isValidLoginId, isValidStaffName, isValidStaffDisplayName, isValidPassword, password, error } = props;
  const processing = props.status === AccountSettingDialogStatus.Processing;

  const isRegisterable = useMemo(() => {
    // 追加するときはすべての値の精査が必要
    if (mode === InputMode.Create) {
      return isValidLoginId && isValidStaffName && isValidStaffDisplayName && isValidPassword;
    }

    // 編集するときはパスワードの入力がある場合だけパスワードの精査が必要
    return (
      isValidLoginId && isValidStaffName && isValidStaffDisplayName && (password.length > 0 ? isValidPassword : true)
    );
  }, [mode, isValidLoginId, isValidStaffName, isValidStaffDisplayName, isValidPassword, password]);

  const [title, completeMessage] = useMemo(() => {
    if (mode === InputMode.Create) {
      return ["module.account.add_staff", "module.account.added_staff"];
    }
    if (mode === InputMode.Edit) {
      return ["module.account.edit_staff", "module.account.edited_staff"];
    }
    return ["---", "---"];
  }, [mode]);

  return (
    <CommonDialog
      dialogOpen={props.open}
      title={t(title)}
      acceptButtonName={t("common.button.register")}
      onAccept={props.onRegister}
      cancelButtonName={t("common.button.cancel")}
      onCancel={props.onCancel}
      isChecked={isRegisterable}
      isProcessed={processing}
      isCompleted={props.status === AccountSettingDialogStatus.Finished}
      completeMessage={t(completeMessage)}
      completeContent={
        <StaffDataTable
          loginId={props.loginId}
          staffName={props.staffName}
          staffDisplayName={props.staffDisplayName}
          password={props.password}
        />
      }
    >
      <Grid px={5} width={"100%"}>
        <StaffDataInputForm disabled={processing} {...props} />
        {error && (
          <Alert style={{ width: "auto", minWidth: "auto" }} severity="error">
            {error.message}
          </Alert>
        )}
      </Grid>
    </CommonDialog>
  );
};

type StaffDataInputFormProps = {
  mode: InputModeType;
  disabled: boolean;
  staffId: number;
  loginId: string;
  onChangeLoginId: (next: string) => void;
  isValidLoginId?: boolean;
  staffName: string;
  onChangeStaffName: (next: string) => void;
  isValidStaffName?: boolean;
  staffDisplayName: string;
  onChangeStaffDisplayName: (next: string) => void;
  isValidStaffDisplayName?: boolean;
  password: string;
  onChangePassword: (next: string) => void;
  isValidPassword?: boolean;
};

const StaffDataInputForm = (props: StaffDataInputFormProps) => {
  const { onChangeLoginId, onChangeStaffName, onChangeStaffDisplayName, onChangePassword } = props;

  // ログインID
  const handleChangeLoginId = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const next = event.target.value;
      onChangeLoginId(next);
    },
    [onChangeLoginId]
  );

  // スタッフ名
  const handleChangeStaffName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const next = event.target.value;
      onChangeStaffName(next);
    },
    [onChangeStaffName]
  );

  // 表示名
  const handleChangeStaffDisplayName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const next = event.target.value;
      onChangeStaffDisplayName(next);
    },
    [onChangeStaffDisplayName]
  );

  // パスワード
  const handleChangePassword = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const next = event.target.value;
      onChangePassword(next);
    },
    [onChangePassword]
  );

  return (
    <>
      <DialogInput
        label={t("common.staff.staff_id")}
        sx={{ pt: "1px" }}
        helperText={props.mode === InputMode.Create ? t("module.account.staff_id_max_length") : undefined}
      >
        <CustomTextInput
          name={"staff_id"}
          disabled={props.mode === InputMode.Edit || props.disabled}
          isValid={props.isValidLoginId}
          onChange={handleChangeLoginId}
          value={String(props.loginId)}
        />
      </DialogInput>
      <DialogInput label={t("common.staff.staff_name")} helperText={t("module.account.staff_name_max_length")}>
        <CustomTextInput
          name={"staff_name"}
          disabled={props.disabled}
          isValid={props.isValidStaffName}
          onChange={handleChangeStaffName}
          value={props.staffName}
        />
      </DialogInput>
      <DialogInput
        label={t("common.staff.display_name")}
        helperText={t("module.account.display_staff_name_max_length")}
      >
        <CustomTextInput
          name={"display_staff_name"}
          disabled={props.disabled}
          isValid={props.isValidStaffDisplayName}
          onChange={handleChangeStaffDisplayName}
          value={props.staffDisplayName}
        />
      </DialogInput>
      <DialogInput
        label={t("module.account.password")}
        helperText={
          <>
            {props.mode === InputMode.Edit && (
              <Box fontWeight={700}>{t("module.account.input_if_changing_password")}</Box>
            )}
            <Box>{t("module.account.password_max_length")}</Box>
            <Box>{t("module.account.password_required_mixed")}</Box>
          </>
        }
      >
        <PasswordInput
          disabled={props.disabled}
          isValid={props.isValidPassword}
          onChange={handleChangePassword}
          value={props.password}
        />
      </DialogInput>
    </>
  );
};

/**
 * スタッフの削除ダイアログ
 */
export const DeleteStaffDialog = (props: DeleteStaffDialogProps) => {
  const { error } = props;
  const { t } = useTranslation();

  return (
    <CommonDialog
      dialogOpen={props.open}
      title={t("module.account.delete_staff")}
      acceptButtonName={t("common.button.delete")}
      onAccept={props.onDelete}
      cancelButtonName={t("common.button.cancel")}
      onCancel={props.onCancel}
      isChecked={true}
      isProcessed={props.status === AccountSettingDialogStatus.Processing}
      isCompleted={props.status === AccountSettingDialogStatus.Finished}
      completeMessage={t("module.account.deleted_staff")}
    >
      <Grid p={3}>
        <Grid mb={2}>{t("module.account.confirm_deletion_staff")}</Grid>
        <Grid p={3} sx={{ border: "1px solid #D9D9D9", borderRadius: "0.5rem" }}>
          <StaffDataTable
            staffId={props.id}
            loginId={props.login_id}
            staffName={props.name}
            staffDisplayName={props.display_name}
          />
        </Grid>
        {error && (
          <Alert style={{ width: "auto", minWidth: "auto" }} sx={{ mt: 2 }} severity="error">
            {error.message}
          </Alert>
        )}
      </Grid>
    </CommonDialog>
  );
};

const PasswordInput = (props: {
  isValid: boolean | undefined;
  disabled: boolean;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  value: string;
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword);
  }, [showPassword]);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <TextField
      autoComplete="off"
      name={"__password__"}
      disabled={props.disabled}
      error={props.isValid === false}
      type={showPassword ? "text" : "password"}
      InputProps={{
        endAdornment: (
          <>
            {props.isValid === false && (
              <InputAdornment position="end" sx={{ color: "red" }}>
                <Close />
              </InputAdornment>
            )}
            <IconButton
              disabled={props.disabled}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </>
        ),
      }}
      fullWidth={true}
      size="small"
      variant="outlined"
      onChange={props.onChange}
      value={props.value}
      inputProps={{ readOnly: !isFocus }}
      onFocus={() => setIsFocus(true)}
      onBlur={() => setIsFocus(false)}
    />
  );
};

/**
 * スタッフ情報表示
 *
 * パスワードは未指定なら表示しない / 長さが 0 なら変更なしとして扱う / 長さが 1 以上なら * した上で表示
 */
const StaffDataTable = (props: {
  staffId?: number;
  loginId?: string;
  staffName?: string;
  staffDisplayName?: string;
  password?: string;
}) => {
  const { t } = useTranslation();
  const { password } = props;
  const hiddenPassword = useMemo(() => {
    if (password == null) {
      return null;
    }
    if (password.length > 0) {
      return "*".repeat(password.length);
    }

    return t("module.account.password_no_change");
  }, [password, t]);
  return (
    <>
      <Grid mb={2} container>
        <Grid item textAlign={"right"} xs={2.5}>
          {t("common.staff.staff_id")}：
        </Grid>
        <Grid item pl={2} xs={9.5}>
          {props.loginId}
        </Grid>
      </Grid>
      <Grid mb={2} container>
        <Grid item textAlign={"right"} xs={2.5}>
          {t("common.staff.staff_name")}：
        </Grid>
        <Grid item pl={2} xs={9.5}>
          {props.staffName}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item textAlign={"right"} xs={2.5}>
          {t("common.staff.display_name")}：
        </Grid>
        <Grid item pl={2} xs={9.5}>
          {props.staffDisplayName}
        </Grid>
      </Grid>
      {hiddenPassword != null && (
        <Grid mt={2} container>
          <Grid item textAlign={"right"} xs={2.5}>
            {t("module.account.password")}：
          </Grid>
          <Grid item pl={2} xs={9.5}>
            {hiddenPassword}
          </Grid>
        </Grid>
      )}
    </>
  );
};

const validateLoginId = (value: string): boolean => {
  return /^[A-Za-z0-9-]+$/.test(value) && 1 <= value.length && value.length <= 25;
};

const validateStaffName = (value: string): boolean => {
  return 1 <= value.length && value.length <= 11;
};

const validateStaffDisplayName = (value: string): boolean => {
  return 1 <= value.length && value.length <= 4;
};

const validatePassword = (value: string): boolean => {
  return /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)[A-Za-z\d_\-#]{8,25}$/.test(value);
};
