import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Typography, Box, List, ListItem, ListItemText } from "@mui/material";

import { closeAnnouncementModal, useGlobalProps } from "src/store/GlobalProps";
import { AnnouncementInfo } from "src/types/Announcement";
import { CommonDialog } from "src/modules/dialog/CommonDialog";
import { formatDateYMD } from "src/utils/dateUtil";
import { Constants } from "src/constants/commonConstants";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useAnnouncementFile } from "src/utils/useAnnouncementFile";

type Props = {
  opened: boolean;
  onClose: () => void;
};

export const useAnnouncementModal = () => {
  const dispatch = useDispatch();
  const { isOpenAnnouncementModal } = useGlobalProps();

  const closeModal = useCallback(() => {
    window.location.reload();
    dispatch(closeAnnouncementModal);
  }, [dispatch]);
  return {
    versionHistoryOpened: isOpenAnnouncementModal,
    onAnnouncementClose: closeModal,
  };
};

type ReleaseInfoProps = {
  version: string | null;
  title: string | null;
  releaseDate: string;
  releaseContents: string[];
};

function ReleaseInfo({ version, title, releaseDate, releaseContents }: ReleaseInfoProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        p: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: "18px",
          fontWeight: "bold",
        }}
      >
        {formatDateYMD(releaseDate)}
      </Typography>
      {/* title */}
      <Box sx={{ py: 2 }}>
        <Typography sx={{ fontSize: "24px", fontWeight: "bold", display: "inline" }}>{title}</Typography>
      </Box>
      {/* contents */}
      <List>
        {releaseContents.map((content, idx) => (
          <ListItem key={idx} sx={{ pl: 0, alignItems: "flex-start" }}>
            <Typography>・</Typography>
            <ListItemText
              sx={{ my: 0, whiteSpace: "pre-wrap", wordWrap: "break-word" }}
              primary={`${content}`}
              primaryTypographyProps={{ sx: { fontSize: "18px", marginLeft: "12px" } }}
            />
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export const AnnouncementModal = ({ opened, onClose }: Props) => {
  const { t } = useTranslation();
  const announcementFilePath = useAnnouncementFile();
  const [announcementInfo, setAnnouncementInfo] = useState<AnnouncementInfo | null>(null);

  useEffect(() => {
    const fetchReleaseInfo = async () => {
      try {
        const hash = new Date().getTime();
        const latestReleaseInfo = (await axios.get(`${announcementFilePath}?_=${hash}`)).data.release_infos[0];
        setAnnouncementInfo(latestReleaseInfo);
      } catch (error) {
        console.error("Failed to announcement fetch:", error);
      }
    };
    fetchReleaseInfo();
  }, [announcementFilePath]);

  if (!announcementInfo) {
    return null;
  }

  const saveReleaseVersion = () => {
    onClose();
    localStorage.setItem(Constants.CURRENT_ANNOUNCEMENT_ID_KEY, String(announcementInfo.id));
  };

  return (
    <CommonDialog
      dialogOpen={opened}
      title={t("module.announcements.announcements")}
      cancelButtonName={t("common.button.close")}
      onCancel={saveReleaseVersion}
    >
      {/* content */}
      <Box
        sx={{
          borderRadius: "10px",
          border: "1px solid #CECECE",
          maxHeight: "50vh",
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "5px",
          },
          "&::-webkit-scrollbar-track": {
            boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0.3)",
            borderRadius: "10px",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#888",
            borderRadius: "5px",
          },
        }}
      >
        <ListItem divider>
          <ListItemText>
            <ReleaseInfo
              version={announcementInfo.version}
              title={announcementInfo.title}
              releaseDate={announcementInfo.date}
              releaseContents={announcementInfo.contents}
            />
          </ListItemText>
        </ListItem>
      </Box>
    </CommonDialog>
  );
};
