import { useMemo } from "react";
import { useTranslation } from "react-i18next";

/**
 * お知らせファイルを使う際に利用
 *
 * 言語設定に応じて取得するお知らせファイルのパスを返却します。
 * 現状は未対応言語(en, ja 以外)が来た場合は存在しないファイルのパスを返すので注意
 */
export const useAnnouncementFile = () => {
  const { i18n } = useTranslation();
  const { language } = i18n;

  const filePath = useMemo(() => {
    return `/announcements/${language}.json`;
  }, [language]);

  return filePath;
};
