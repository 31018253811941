import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArticleIcon from "@mui/icons-material/ArticleOutlined";
import { Box, Drawer, IconButton, Typography } from "@mui/material";
import { Fragment, useCallback, useMemo, useRef, useState } from "react";
import { CareSubjectName } from "src/modules/sp/CareSubjectName";
import { SlidePanel } from "src/modules/sp/SlidePanel";
import { SpPage } from "src/modules/sp/SpPage";
import { useGlobalProps } from "src/store/GlobalProps";
import { ModelsCareSubject, ModelsHistoryLog, ModelsStaff } from "src/store/helppadApi";
import { filterUnnecessaryHistroyLog } from "src/utils/historyLogs";
import { formatLogDate } from ".";
import { useReadMoreListHistoryLog } from "src/utils/useReadMoreListHistoryLog";
import { buildHistoryLogMessage } from "./parts/HistoryLogs";
import { ReadMoreButton } from "./parts/ReadMoreButton";
import {
  CheckExcretionSupportDialog,
  ConfirmDeleteExcretionSupportDialog,
  useConfirmExcretionSupportDialog,
} from "src/modules/dialog/ConfirmExcretionSupportDialog";
import { useSpNotification } from "src/utils/useSpNotification";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocale } from "src/utils/localeMap";

type Props = {
  staff: ModelsStaff; // スタッフ
  careSubject: ModelsCareSubject; // 入居者
};

const useThis = (careSubject: ModelsCareSubject) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    logs,
    isLoading: isLoadingHistoryLog,
    isAllRead: isAllReadHistoryLog,
    fetchNextPage: fetchNextHisyoryLogPage,
    fetchLatest: fetchLatestHistoryLog,
    deleteLog,
  } = useReadMoreListHistoryLog(careSubject.id as number);

  const { showNotification } = useSpNotification();

  const [openCheckDialog, setOpenCheckDialog] = useState(false);
  const {
    open: openDeleteDialog,
    showDialog: showDeleteDialog,
    hideDialog: hideDeleteDialog,
  } = useConfirmExcretionSupportDialog();
  const [focusedLog, setFocusedLog] = useState<ModelsHistoryLog | undefined>();
  const [deleting, setDeleting] = useState(false);

  const onClickMemoMenu = useCallback(
    (logId: number) => {
      const log = logs?.find((l: ModelsHistoryLog) => l.id === logId);
      if (log === null) {
        return;
      }

      setShowMemoMenu(true);
      setFocusedLog(log);
    },
    [logs]
  );

  const [showMemoMenu, setShowMemoMenu] = useState(false);

  // メニューを閉じる際の振る舞い
  const onCloseMemoMenu = useCallback(() => {
    setShowMemoMenu(false);
    setFocusedLog(undefined);
  }, []);

  // メニューの確認するが押された際の振る舞い
  const onClickMenuConfirm = useCallback(() => {
    setOpenCheckDialog(true);
    setShowMemoMenu(false);
  }, []);

  // メニューの編集するが押された際の振る舞い
  const onClickMenuEdit = useCallback(() => {
    navigate(`${location.pathname}/${focusedLog?.entity_id}/edit`);
    setShowMemoMenu(false);
  }, [location.pathname, navigate, focusedLog]);

  // 確認ダイアログを閉じる際の振る舞い
  const onCloseCheckDialog = useCallback(() => {
    setOpenCheckDialog(false);
    setFocusedLog(undefined);
  }, []);

  // メニューの削除するが押された際の振る舞い
  const onClickMenuDelete = useCallback(() => {
    showDeleteDialog();
    setShowMemoMenu(false);
  }, [showDeleteDialog]);

  // 削除ダイアログを閉じる際の振る舞い
  const onCloseDeleteDialog = useCallback(() => {
    hideDeleteDialog();
    setFocusedLog(undefined);
  }, [hideDeleteDialog]);

  // 削除ダイアログの削除するが押された際の振る舞い
  const onClickDialogDelete = useCallback(async () => {
    if (focusedLog == null) {
      return;
    }
    const { id } = focusedLog;
    setDeleting(true);
    await deleteLog({ id });
    showNotification(t("care_subject.history_logs.deleted"));
    setDeleting(false);
    hideDeleteDialog();
  }, [focusedLog, deleteLog, showNotification, hideDeleteDialog, t]);

  return {
    logs,
    showMemoMenu,
    onClickMemoMenu,
    onCloseMemoMenu,
    focusedLog,
    openCheckDialog,
    onClickMenuConfirm,
    onClickMenuEdit,
    onCloseCheckDialog,
    openDeleteDialog,
    deleting,
    onClickMenuDelete,
    onCloseDeleteDialog,
    onClickDialogDelete,
    isLoadingHistoryLog,
    isAllReadHistoryLog,
    fetchNextHisyoryLogPage,
    fetchLatestHistoryLog,
  };
};

/**
 * [SP用]
 * 通知・対応・設定変更履歴
 */
export const CareSubjectHistoryLog = (props: Props) => {
  const { t } = useTranslation();
  const { careSubject } = props;

  const {
    logs,
    showMemoMenu,
    onCloseMemoMenu,
    onClickMemoMenu,
    focusedLog,
    openCheckDialog,
    onClickMenuConfirm,
    onClickMenuEdit,
    onCloseCheckDialog,
    openDeleteDialog,
    deleting,
    onClickMenuDelete,
    onCloseDeleteDialog,
    onClickDialogDelete,
    isLoadingHistoryLog,
    isAllReadHistoryLog,
    fetchNextHisyoryLogPage,
  } = useThis(careSubject);

  const filteredHistoryLogs = useMemo(() => filterUnnecessaryHistroyLog(logs), [logs]);

  return (
    <SpPage>
      <SlidePanel title={t("care_subject.history_logs.notification_support_history")}>
        <Box display="flex" width={"100%"} flexDirection={"column"}>
          {/* 入居者情報 */}
          <Box boxSizing={"border-box"} px={2} mb={2}>
            <CareSubjectName name={careSubject.name} />
          </Box>
          {/* 履歴一覧 */}
          <Box boxSizing={"border-box"} px={2} mb={2} flex={1} width={"100%"} overflow={"auto"}>
            <HistoryLogsList
              logs={filteredHistoryLogs ?? []}
              focusedLogId={focusedLog?.id}
              onClickMemoMenu={onClickMemoMenu}
              isReadingMore={isLoadingHistoryLog}
              showReadMoreButton={isAllReadHistoryLog === false}
              onClickReadMore={fetchNextHisyoryLogPage}
            />
          </Box>
          {/* 履歴クリック時のメニュー */}
          <MemoMenu
            show={showMemoMenu}
            onClose={onCloseMemoMenu}
            onClickConfirm={onClickMenuConfirm}
            onClickEdit={onClickMenuEdit}
            onClickDelete={onClickMenuDelete}
          />
        </Box>
        {focusedLog && (
          <>
            <CheckExcretionSupportDialog
              open={openCheckDialog}
              onClose={onCloseCheckDialog}
              careSubjectName={careSubject.name ?? "---"}
              excretionSupportId={focusedLog.entity_id as number}
            />
            <ConfirmDeleteExcretionSupportDialog
              open={openDeleteDialog}
              processing={deleting}
              onDelete={onClickDialogDelete}
              onClose={onCloseDeleteDialog}
              careSubjectName={careSubject.name ?? "---"}
              excretionSupportId={focusedLog.entity_id as number}
            />
          </>
        )}
      </SlidePanel>
    </SpPage>
  );
};

export const MemoMenuButton = (props: {
  disabled?: boolean;
  logId: number;
  onClick: (logId: number) => void | undefined;
}) => {
  const { logId, onClick } = props;
  const handleClick = useCallback(() => {
    onClick(logId);
  }, [logId, onClick]);

  return (
    <IconButton disabled={props.disabled} sx={{ minWidth: "48px", minHeight: "48px" }} onClick={handleClick}>
      <MoreVertIcon />
    </IconButton>
  );
};

type HistoryLogsProps = {
  disabled?: boolean;
  logs: ModelsHistoryLog[];
  focusedLogId?: number; // メニューボタンが押されたメモのID
  onClickMemoMenu: (logId: number) => void;
  showReadMoreButton: boolean;
  isReadingMore: boolean;
  onClickReadMore: () => void;
};

export const HistoryLogsList = (props: HistoryLogsProps) => {
  const { t } = useTranslation();
  const locale = useLocale();
  const { staffId } = useGlobalProps();
  const { logs, focusedLogId, onClickMemoMenu } = props;

  return (
    <Fragment>
      <Box sx={{ background: "#FFF", borderRadius: "0.5rem" }}>
        {logs.map((log, index) => {
          const logMessage = buildHistoryLogMessage(log, t);
          if (logMessage === null) {
            return null;
          }
          // 全コンポーネントでパースするとコストが高いので必要なとき(現時点では編集履歴のとき)だけ changes をパース
          // TODO: 多言語対応でコンフリクトが予想されるのでやや暫定対応(多言語対応時に以下をコンポーネントとして切り出す予定)
          const parsedChanges =
            log.table_name === "excretion_results" && log.action === "update" && log.changes != null
              ? JSON.parse(log.changes)
              : undefined;

          return (
            // 履歴1件分ごとのBox
            <Box
              display="flex"
              bgcolor={log.id === focusedLogId ? "#FEF4E5" : undefined}
              key={`History-${index}`}
              sx={{
                ":not(:last-child)": { borderBottom: "1px solid #E6E6E6" },
                ":hover": { background: "#F9F9F9" },
              }}
              p={2}
              color="#222222"
              zIndex={log.id === focusedLogId ? 16000 : 0}
            >
              {/* 通知内容のBox */}
              <Box
                sx={{
                  width: "90%",
                  // color: log.input_person ? "#404040" : "#0E1C73",
                }}
              >
                <Box
                  sx={{
                    display: "inline-flex",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      fontWeight: 700,
                      alignSelf: "center",
                      color: log.input_person ? (log.staff_id === staffId ? "#F29500" : "#404040") : "#0E1C73",
                    }}
                  >
                    {log.input_person ? log.input_person : t("care_subject.history_logs.alert")}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: 12,
                      ml: 1,
                      alignSelf: "center",
                    }}
                  >
                    {parsedChanges == null
                      ? formatLogDate(log.created_at!, locale)
                      : t("care_subject.history_logs.edited_at_and_registered_at", {
                          date1: formatLogDate(log.created_at!, locale),
                          date2: formatLogDate(parsedChanges.created_at, locale, { short: true }),
                          interpolation: { escapeValue: false },
                        })}
                  </Typography>
                </Box>
                <br />
                <Box
                  sx={{
                    display: "inline-flex",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: 14,
                      whiteSpace: "pre-wrap",
                      color: log.input_person ? "#404040" : "#0E1C73",
                    }}
                  >
                    {logMessage}
                  </Typography>
                </Box>
              </Box>
              {/* 右端のボタンのBox */}
              <Box
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  flexDirection: "row",
                  width: "10%",
                }}
              >
                {"excretion_results" === log.table_name ? (
                  <MemoMenuButton disabled={props.disabled} logId={log?.id!} onClick={onClickMemoMenu} />
                ) : null}
              </Box>
            </Box>
          );
        })}
        {/* さらに読み込むボタン */}
        {props.showReadMoreButton && (
          <ReadMoreButton
            sx={{
              minHeight: "48px",
            }}
            disabled={props.isReadingMore}
            loading={props.isReadingMore}
            onClick={props.onClickReadMore}
          />
        )}
      </Box>
    </Fragment>
  );
};

type MemoMenuProps = {
  show: boolean;
  onClose: () => void;
  disabled?: boolean;
  onClickConfirm: () => void;
  onClickEdit: () => void;
  onClickDelete: () => void;
};

/**
 * メモの「…」が押された際に表示するメニュー
 */
const MemoMenu = (props: MemoMenuProps) => {
  const { t } = useTranslation();
  const ref = useRef();

  return (
    <Drawer
      anchor={"bottom"}
      open={props.show}
      onClose={props.onClose}
      ModalProps={{
        disableEscapeKeyDown: props.disabled,
      }}
      PaperProps={{
        sx: {
          backgroundColor: "rgba(0,0,0,0)",
        },
      }}
    >
      <Box ref={ref} width="100%" role="presentation" overflow={"hidden"}>
        <Box bgcolor={"#FFF"} color="#000" p={1}>
          <Box onClick={props.onClickConfirm} sx={{ cursor: "pointer", "&:hover": { opacity: 0.7 } }}>
            <Box display={"flex"} p={2} alignItems={"center"}>
              <ArticleIcon />
              <Typography ml="16px">{t("care_subject.history_logs.confirm_excretion_support")}</Typography>
            </Box>
          </Box>
          {/* 編集に関する要件がまとまるまで導線を隠す */}
          {/* <Box onClick={props.onClickEdit} sx={{ cursor: "pointer", "&:hover": { opacity: 0.7 } }}>
            <Box display={"flex"} p={2} alignItems={"center"}>
              <Box display={"flex"} width={"24px"} justifyContent={"center"}>
                <FontAwesomeIcon fontSize={"20px"} icon={faEdit} />
              </Box>
              <Typography ml="16px">{t("care_subject.history_logs.edit_excretion_support")}</Typography>
            </Box>
          </Box> */}
          <Box onClick={props.onClickDelete} sx={{ cursor: "pointer", "&:hover": { opacity: 0.7 } }}>
            <Box display={"flex"} p={2} alignItems={"center"}>
              <Box display={"flex"} width={"24px"} justifyContent={"center"}>
                <FontAwesomeIcon fontSize={"20px"} icon={faTrash} />
              </Box>
              <Typography ml="16px">{t("care_subject.history_logs.delete_excretion_support")}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};
