import { Button } from "@mui/material";
import { ReactNode, useCallback } from "react";

const baseStyles = {
  px: "4px",
  minHeight: "44px",
  color: "#222222",
  lineHeight: 1,
  p: 0,
  textTransform: "none",
};

export const RadioButton = <T extends any>({
  value,
  current,
  children,
  disabled,
  onClick,
}: {
  value: T;
  current: T | undefined | null;
  children: ReactNode;
  disabled: boolean;
  onClick: (next: T) => void;
}) => {
  const handleClick = useCallback(() => {
    onClick(value);
  }, [onClick, value]);

  return (
    <Button
      onClick={handleClick}
      disabled={disabled}
      sx={
        value === current
          ? {
              ...baseStyles,
              background: "#F8EFE0",
              border: "3px solid #E8963D",
            }
          : {
              ...baseStyles,
              background: "#FFF",
              border: "1px solid #E6E6E6",
            }
      }
    >
      {children}
    </Button>
  );
};
