import {
  Box,
  Button,
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Grid,
  styled,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

type CancelPairingDialogProps = {
  sensorId: string;
  targetUserName: string;
  showDialog: boolean;
  onCancel: (sensorId: string) => void;
  onClose: () => void;
};

const ConfirmPairing = ({
  sensorId,
  targetUserName,
  handleCancel,
  handleClose,
}: {
  sensorId: string;
  targetUserName: string;
  handleCancel: () => void;
  handleClose: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <DialogContent>
      <Box sx={{ padding: "0 64px 32px" }}>
        <Box sx={{ marginBottom: "32px", textAlign: "center" }}>{t("module.unpair.confirm_unpair")}</Box>
        <Box sx={{ border: "1px solid #D9D9D9", borderRadius: "8px", padding: "24px" }}>
          <Grid container sx={{ gap: 2 }}>
            <Grid item xs={2} sx={{ display: "inline-flex", justifyContent: "flex-end" }}>
              {t("module.unpair.sensor_id")}:
            </Grid>
            <Grid item xs={8} flex={1}>
              {sensorId}
            </Grid>
          </Grid>
          <Grid container sx={{ gap: 2 }}>
            <Grid item xs={2} sx={{ display: "inline-flex", justifyContent: "flex-end" }}>
              {t("common.care_subject.care_subject_name")}:
            </Grid>
            <Grid item xs={8} flex={1}>
              {targetUserName}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <Box display="flex" justifyContent="center" width="100%" gap="30px">
          <SecondaryButton onClick={handleClose}>{t("common.button.cancel")}</SecondaryButton>
          <RegisterButton onClick={handleCancel}>{t("common.button.unpair")}</RegisterButton>
        </Box>
      </DialogActions>
    </DialogContent>
  );
};

const CompleteCancel = ({ handleClose }: { handleClose: () => void }) => {
  const { t } = useTranslation();
  return (
    <DialogContent>
      <Box sx={{ padding: "0 64px 32px" }}>
        <Box sx={{ marginBottom: "32px", textAlign: "center" }}>{t("module.unpair.unpaired")}</Box>
      </Box>
      <DialogActions sx={{ justifyContent: "space-around" }}>
        <SecondaryButton onClick={handleClose}>{t("common.button.close")}</SecondaryButton>
      </DialogActions>
    </DialogContent>
  );
};

export const CancelPairingDialog = ({
  sensorId,
  targetUserName,
  showDialog,
  onClose,
  onCancel,
}: CancelPairingDialogProps) => {
  const { t } = useTranslation();
  const [canceled, setCanceled] = useState(false);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleCancel = useCallback(() => {
    onCancel(sensorId);
    setCanceled(true);
  }, [onCancel, sensorId]);

  useEffect(() => {
    if (showDialog) {
      setCanceled(false);
    }
  }, [showDialog]);

  return (
    <Dialog
      open={showDialog}
      onClose={handleClose}
      fullWidth={true}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-container": {
          "& .MuiDialog-paper": {
            width: "100%",
            minWidth: "800px",
            maxWidth: "800px",
          },
        },
      }}
    >
      <DialogTitle sx={{ mt: 3, mb: 2, textAlign: "center", fontSize: "1.75rem" }}>
        {t("module.unpair.unpair")}
        <CloseDialogButton onClick={handleClose} />
      </DialogTitle>
      {canceled ? (
        <CompleteCancel handleClose={handleClose} />
      ) : (
        <ConfirmPairing
          sensorId={sensorId}
          targetUserName={targetUserName}
          handleCancel={handleCancel}
          handleClose={handleClose}
        />
      )}
    </Dialog>
  );
};

export const CloseDialogButton = (props: { onClick: () => void }) => {
  return (
    <IconButton
      onClick={props.onClick}
      sx={{
        position: "absolute",
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  );
};

const SecondaryButton = styled(Button)<ButtonProps>(() => ({
  height: "60px",
  width: "160px",
  borderRadius: "30px",
  fontSize: "1.1rem",
  fontWeight: 700,
  color: "#FFF",
  backgroundColor: "#606060",
  "&:hover": {
    backgroundColor: "#909090",
  },
}));

const RegisterButton = styled(Button)<ButtonProps>(({ disabled }) => ({
  height: "60px",
  width: "160px",
  borderRadius: "30px",
  fontSize: "1.1rem",
  fontWeight: 700,
  color: "#FFF",
  backgroundColor: "#F29501",
  "&:hover": {
    backgroundColor: "#efac47",
  },
}));
