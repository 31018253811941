import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const FILES = [
  { name: "manager_contents.png", alt: "Manager contents" },
  { name: "manager_help1.png", alt: "Manager help 1" },
  { name: "manager_help2.png", alt: "Manager help 2" },
  { name: "manager_help3.png", alt: "Manager help 3" },
  { name: "manager_help4.png", alt: "Manager help 4" },
  { name: "manager_help5.png", alt: "Manager help 5" },
  { name: "manager_help6.png", alt: "Manager help 6" },
];

export const ManagerHelp = () => {
  const { i18n } = useTranslation();
  const basePath = useMemo(() => `/help/${i18n.language}/manager_help`, [i18n.language]);

  return (
    <p>
      {FILES.map((file) => (
        <img key={file.name} src={`${basePath}/${file.name}`} alt={file.alt}></img>
      ))}
    </p>
  );
};
