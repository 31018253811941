import { Fragment, useCallback, useState } from "react";
import { CommonDialog } from "./CommonDialog";
import { DataRow } from "./parts/DataRow";
import { Grid, Typography } from "@mui/material";
import { usePageMode } from "src/utils/usePageMode";
import { SpDataTable } from "./parts/SpDataTable";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean; // ダイアログの開閉状態
  deleting: boolean; // 削除処理中か
  deleted: boolean; // 削除に成功したか
  target?: {
    commentId: number; // 対象ID
    staffName: string; // メモの投稿者名
    message: string; // メモ内容
  };
  onConfirm: () => void;
  onCancel: () => void;
};

export const useDeleteMemoDialog = (
  options: {
    closeOnSuccess?: boolean;
    onSuccess?: () => void;
    onFailure?: () => void;
    deleteCommentLog?: (args: { id?: number }) => Promise<void>;
  } = {}
) => {
  const [state, setState] = useState<Omit<Props, "onConfirm" | "onCancel">>({
    open: false,
    deleting: false,
    deleted: false,
  });
  const { target } = state;
  const { closeOnSuccess, onSuccess, onFailure, deleteCommentLog } = options;

  const showDialog = useCallback((commentId: number, staffName: string, message: string) => {
    setState({
      open: true,
      deleting: false,
      deleted: false,
      target: {
        commentId,
        staffName,
        message,
      },
    });
  }, []);

  const hideDialog = useCallback(() => {
    setState((state) => ({ ...state, open: false }));
  }, []);

  const deleteMemo = useCallback(async () => {
    try {
      setState((state) => ({ ...state, deleting: true }));
      if (target == null) {
        return;
      }

      deleteCommentLog && (await deleteCommentLog({ id: target.commentId }));

      setState((state) => ({
        ...state,
        // 成功時に閉じる設定ならダイアログを非表示にする
        open: closeOnSuccess === true ? false : state.open,
        deleting: false,
        deleted: true,
      }));
      onSuccess != null && onSuccess();
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
      setState((state) => ({ ...state, deleting: false }));
      onFailure != null && onFailure();
    }
  }, [closeOnSuccess, onSuccess, onFailure, deleteCommentLog, target]);

  return {
    open: state.open,
    deleting: state.deleting,
    deleted: state.deleted,
    target: state.target,
    showDialog,
    hideDialog,
    deleteMemo,
  };
};

export const DeleteMemoDialog = ({ open, target, deleting, deleted, onConfirm, onCancel }: Props) => {
  const { t } = useTranslation();
  const { isDesktop } = usePageMode();

  return (
    <CommonDialog
      dialogOpen={open}
      title={isDesktop && deleted ? t("module.memo.deleted_memo") : t("module.memo.confirm_deletion_memo")}
      acceptButtonName={t("common.button.delete")}
      cancelButtonName={t("common.button.cancel")}
      onAccept={onConfirm}
      onCancel={onCancel}
      isChecked={true}
      isProcessed={deleting}
      isCompleted={isDesktop ? deleted : undefined} // SPモード時は完了ダイアログの代わりに通知を表示するので未指定とする
      completeContent={
        isDesktop ? <DesktopMemoContent staffName={target?.staffName} message={target?.message} /> : undefined
      } // SPモード時は完了ダイアログの代わりに通知を表示するので未指定とする
    >
      <Typography sx={{ mb: 1 }}>{t("module.memo.wawrning_deletion")}</Typography>
      <MemoContent staffName={target?.staffName} message={target?.message} />
    </CommonDialog>
  );
};

const DesktopMemoContent = ({ staffName, message }: { staffName?: string; message?: string }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <DataRow label={t("module.memo.staff_name")} value={staffName} />
      <DataRow label={t("module.memo.memo")} value={message} />
    </Fragment>
  );
};

const MemoContent = ({ staffName, message }: { staffName?: string; message?: string }) => {
  const { t } = useTranslation();
  const { isDesktop } = usePageMode();

  if (isDesktop) {
    return (
      <Grid p={3} sx={{ border: "1px solid #D9D9D9", borderRadius: "0.5rem" }}>
        <DesktopMemoContent staffName={staffName} message={message} />
      </Grid>
    );
  }

  return (
    <SpDataTable>
      <SpDataTable.Row title={t("module.memo.staff_name")} value={staffName} />
      <SpDataTable.Row title={t("module.memo.memo")} value={message} />
    </SpDataTable>
  );
};
