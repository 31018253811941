import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Link, NavLink, NavLinkProps } from "react-router-dom";
import CircleIcon from "@mui/icons-material/Circle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBell,
  faBars,
  faGear,
  faCircleQuestion,
  faRightFromBracket,
  faPenToSquare,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import LogoFullImg from "../assets/images/Helppad_common_logo.png";
import LogoIconImg from "../assets/images/Helppad_icon_logo.png";
import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { resetToken, useGlobalProps } from "src/store/GlobalProps";
import { useDispatch } from "react-redux";
import {
  useGetFacilityQuery,
  useGetStaffQuery,
  useLogoutMutation,
  LogoutApiArg,
  useListCareSubjectQuery,
} from "src/store/helppadApi";
import { Constants } from "src/constants/commonConstants";
import { LogoutConfirmModal } from "./dialog/LogoutConfirmModal";
import axios from "axios";
import { format } from "date-fns";
import { useIsLargeWidthDesktop } from "src/utils/useIsLargeWidthDesktop";
import { useTranslation } from "react-i18next";
import { useLocale } from "src/utils/localeMap";
import { useAnnouncementFile } from "src/utils/useAnnouncementFile";

const UnreadBadge = () => {
  const { lastReadAnnouncementId } = useGlobalProps();
  const announcementFilePath = useAnnouncementFile();
  const [totalAnnounceCount, setTotalAnnounceCount] = useState(0);

  useEffect(() => {
    const fetchTotalAnnounceCount = async () => {
      try {
        const hash = new Date().getTime();
        const releaseInfos = (await axios.get(`${announcementFilePath}?_=${hash}`)).data.release_infos;
        setTotalAnnounceCount(releaseInfos.length);
      } catch (error) {
        console.error("Failed to fetch total count:", error);
      }
    };
    fetchTotalAnnounceCount();
  }, [announcementFilePath]);

  const unreadCount = useMemo(
    () => totalAnnounceCount - lastReadAnnouncementId,
    [lastReadAnnouncementId, totalAnnounceCount]
  );

  return (
    <Box sx={{ position: "relative", display: "inline-block" }}>
      <FontAwesomeIcon fontSize="22px" icon={faEnvelope} />
      {unreadCount > 0 && (
        <span
          style={{
            position: "absolute",
            top: "-8px",
            right: "-8px",
            backgroundColor: "red",
            color: "white",
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "12px",
            fontWeight: "bold",
          }}
        >
          {unreadCount}
        </span>
      )}
    </Box>
  );
};

const navigator = [
  { i18nKey: "module.side_menu.notices", path: "/notices", icon: <FontAwesomeIcon fontSize="22px" icon={faBell} /> },
  {
    i18nKey: "module.side_menu.care_subjects",
    path: "/care_subjects",
    icon: <FontAwesomeIcon fontSize="22px" icon={faBars} />,
  },
  { i18nKey: "module.side_menu.setting", path: "/setting", icon: <FontAwesomeIcon fontSize="22px" icon={faGear} /> },
  {
    i18nKey: "module.side_menu.help",
    path: "/help",
    icon: <FontAwesomeIcon fontSize="22px" icon={faCircleQuestion} />,
  },
  { i18nKey: "module.side_menu.tool", path: "/tool", icon: <FontAwesomeIcon fontSize="22px" icon={faPenToSquare} /> },
  { i18nKey: "module.side_menu.announcements", path: "/announcements", icon: <UnreadBadge /> },
] as const;

export const SideMenu = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const { staffId } = useGlobalProps();
  const facility = useGetFacilityQuery().data;
  const { data: staff, error: staffError } = useGetStaffQuery({ id: staffId! }, Constants.POLLING_INTERVAL);
  const careSubjects = useListCareSubjectQuery({}, Constants.POLLING_INTERVAL).data;
  const operationCount = useMemo(
    () => careSubjects?.items?.filter((item) => item.device_error === 0 && item.device_id !== null).length,
    [careSubjects]
  );

  const [openLogoutModal, setOpenLogoutModal] = useState(false);
  const isLargeWidthDesktop = useIsLargeWidthDesktop();

  const [logout] = useLogoutMutation();
  const logoutApiArg: LogoutApiArg = {
    deviceToken: localStorage.getItem("token")!,
  };

  // ログアウトボタン押下時の処理
  const onClickLogout = async () => {
    setOpenLogoutModal(true);
  };

  useEffect(() => {
    if (staffError && "status" in staffError && staffError.status === 404) {
      console.log("------ 404 error ------");
      // ログイン画面に戻す
      dispatch(resetToken());
    }
  }, [staffError, dispatch]);

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
      }}
    >
      <Grid
        container
        direction="column"
        p={isLargeWidthDesktop ? 2 : 1}
        gap={isLargeWidthDesktop ? 3 : 1}
        sx={{ paddingTop: 2.5 }}
      >
        <Button component={Link} to="/notices">
          <Box alignItems="center" display="flex" justifyContent="center">
            <img src={isLargeWidthDesktop ? LogoFullImg : LogoIconImg} height="40" alt="Helppad Logo" />
          </Box>
        </Button>
        {/* 時計 */}
        <Timer />
        {/* 稼働台数 */}
        <OperationCount all={careSubjects?.items?.length} count={operationCount} />
      </Grid>
      <Stack gap={1}>
        {navigator.map(({ i18nKey, path, icon }) => {
          return (
            <ListItemLink to={path} style={{ textTransform: "none", color: "black" }} key={path}>
              <Grid
                container
                paddingLeft={"10%"}
                alignItems="center"
                gap={isLargeWidthDesktop ? 2 : 1}
                height={isLargeWidthDesktop ? "60px" : "48px"}
              >
                {icon ? icon : <CircleIcon color="secondary" />}
                <Typography fontSize={isLargeWidthDesktop ? "16px" : "14px"}>{t(i18nKey)}</Typography>
              </Grid>
            </ListItemLink>
          );
        })}
      </Stack>
      {/* ログインしているユーザ情報 */}
      <Grid
        container
        sx={{
          width: "100%",
          flex: 1,
          flexDirection: "column",
          justifyContent: "flex-end",
          pt: 1,
          px: isLargeWidthDesktop ? 2 : 1,
          color: "#222222",
          overflowWrap: "break-word",
          wordWrap: "break-all",
        }}
      >
        <Typography maxWidth={"100%"} display={"block"} fontSize={isLargeWidthDesktop ? "14px" : "12px"}>
          {facility?.name}
        </Typography>
        <Typography
          maxWidth={"100%"}
          display={"block"}
          mt={isLargeWidthDesktop ? 2 : 1}
          mb={isLargeWidthDesktop ? 2 : 1}
          fontSize={isLargeWidthDesktop ? "22px" : "18px"}
          sx={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "-webkit-box",
            WebkitLineClamp: "2",
            WebkitBoxOrient: "vertical",
            lineBreak: "anywhere",
          }}
        >
          {staff?.name ?? ""}
        </Typography>
        <LogoutButton onClick={onClickLogout} />
      </Grid>
      <LogoutConfirmModal
        showDialog={openLogoutModal}
        onClose={() => setOpenLogoutModal(false)}
        onComplete={() => {
          logout(logoutApiArg);
          dispatch(resetToken());
          navigation("/");
        }}
      />
    </Box>
  );
};

const Timer = () => {
  const { t } = useTranslation();
  const locale = useLocale();
  const isLargeWidthDesktop = useIsLargeWidthDesktop();
  const [now, setNow] = useState<Date>(new Date());

  useEffect(() => {
    const update = () => {
      const date = new Date();
      setNow(date);
    };

    const interval = setInterval(update, 1000);
    update();

    return () => clearInterval(interval);
  }, []);

  const [date, time] = useMemo(() => {
    return [
      format(now, t("format.date.month_day_weekday", { defaultValue: "M/d(E)" }), { locale }),
      format(now, `HH:mm`),
    ];
  }, [now, t, locale]);

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      boxSizing={"border-box"}
      sx={{
        width: "100%",
        height: isLargeWidthDesktop ? 100 : 80,
        border: 1,
        borderColor: "#E6E6E6",
        borderRadius: "8px",
      }}
    >
      <Box>
        <Typography
          alignItems="center"
          display="flex"
          justifyContent="center"
          sx={{ fontSize: 16, whiteSpace: "nowrap" }}
        >
          {date}
        </Typography>
        <Typography
          alignItems="center"
          display="flex"
          justifyContent="center"
          sx={{ fontSize: 32, whiteSpace: "nowrap" }}
        >
          {time}
        </Typography>
      </Box>
    </Box>
  );
};

export const OperationCount = ({ all, count }: { all?: number; count?: number }) => {
  const isLargeWidthDesktop = useIsLargeWidthDesktop();
  const { t } = useTranslation();
  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      gap={-20}
      justifyContent="center"
      sx={{
        height: 50,
        border: 1,
        borderColor: "#E6E6E6",
        borderRadius: "8px",
        backgroundColor: "#F9F9F9",
        lineHeight: 0.5,
      }}
    >
      {all == null || count == null ? null : (
        <>
          <Box display={"flex"} sx={{ alignItems: "center", whiteSpace: "nowrap" }}>
            <Typography component="span" sx={{ fontSize: 20, lineHeight: 0 }}>
              {count}
            </Typography>
            <Typography component="span" sx={{ fontSize: 16, lineHeight: 0, mx: "0.25rem" }}>
              {t("module.side_menu.units_format")}
            </Typography>
            {all === count && (
              <Typography
                component="span"
                mx={isLargeWidthDesktop ? 1 : 0}
                sx={{ fontSize: isLargeWidthDesktop ? 16 : 12, lineHeight: 0 }}
              >
                {t("module.side_menu.all")}
              </Typography>
            )}
          </Box>
          <Typography component="span" sx={{ fontSize: 16, lineHeight: 0, whiteSpace: "nowrap" }}>
            {t("module.side_menu.in_action")}
          </Typography>
        </>
      )}
    </Box>
  );
};

const ListItemLink = (props: NavLinkProps) => {
  return (
    <NavLink
      {...props}
      style={({ isActive }) => ({
        color: isActive ? "#f29500" : "#000000",
        fontWeight: isActive ? "bold" : "normal",
        background: isActive ? "#fef4e5" : undefined,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textDecoration: "none",
      })}
    />
  );
};

const LogoutButton = (props: { onClick: () => void }) => {
  const { t } = useTranslation();
  const isLargeWidthDesktop = useIsLargeWidthDesktop();
  return (
    <Button
      variant="contained"
      color="secondary"
      sx={{
        boxSizing: "border-box",
        width: "100%",
        backgroundColor: "#f9f9f9",
        "&:hover": {
          backgroundColor: "#AAA",
        },
        color: "black",
        p: isLargeWidthDesktop ? 1.5 : 1,
        justifyContent: isLargeWidthDesktop ? "center" : "left",
        borderRadius: 0,
        boxShadow: "none",
        border: "solid 1px #CCC",
        whiteSpace: "nowrap",
        fontSize: isLargeWidthDesktop ? "16px" : "14px",
        textTransform: "none",
      }}
      title={t("module.side_menu.logout")}
      onClick={props.onClick}
    >
      {t("module.side_menu.logout")}
      <Typography position="absolute" right={"8%"} pt={"4px"}>
        <FontAwesomeIcon fontSize="22px" icon={faRightFromBracket} />
      </Typography>
    </Button>
  );
};
