import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { startOfDay } from "date-fns";
import { useCallback, useState } from "react";
import { useLocale } from "src/utils/localeMap";

type Props = {
  disabled: boolean;
  initDate: string;
  onChangeDate: (data: Date) => void; // 時分秒ミリ秒が 0 の Date
};

/**
 * 対応日付選択ピッカー
 */
export const ExcretionDatePicker = ({ disabled, initDate, onChangeDate }: Props) => {
  const locale = useLocale();

  const [inputDate, setInputDate] = useState<Date>(new Date(initDate));
  const handleChangeDate = useCallback(
    (newDate: Date) => {
      setInputDate(newDate);
      onChangeDate(startOfDay(newDate));
    },
    [onChangeDate]
  );
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
      <DatePicker
        disabled={disabled}
        maxDate={new Date()}
        value={inputDate}
        defaultValue={inputDate}
        onChange={(event) => (event === null ? handleChangeDate(new Date(initDate)) : handleChangeDate(event))}
      />
    </LocalizationProvider>
  );
};
