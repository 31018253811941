import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AcceptButton, CancelButton } from "src/modules/parts/CommonButton";

type Props = {
  disabled: boolean;
  confirmLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
};

/**
 * [SP] 対応登録の登録・編集で利用する下部ボタン郡
 */
export const SpExcretionSupportFooterButtons = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Box mt={3} mb={2} display={"flex"} flexDirection={"column"} width={"100%"} boxSizing={"border-box"} px={4}>
      <AcceptButton sx={{ minWidth: "100%" }} disabled={props.disabled} onClick={props.onConfirm}>
        {props.confirmLabel}
      </AcceptButton>
      <CancelButton sx={{ minWidth: "100%", mt: 2 }} disabled={props.disabled} onClick={props.onCancel}>
        {t("common.button.cancel")}
      </CancelButton>
    </Box>
  );
};
