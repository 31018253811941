import { useCallback, useMemo, useState } from "react";
import { CommonDialog } from "./CommonDialog";
import { getDeviceErrorCode, getDeviceErrorItem, getDeviceErrorMessage } from "src/constants/commonConstants";
import { SpDataTable } from "./parts/SpDataTable";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type Params = {
  deviceErrorCode?: number; // デバイスエラーコード
  careSubjectName?: string; // 入居者名
};

type Props = {
  open: boolean; // 表示・非表示
  onClose: () => void; // 閉じる際の振る舞い
} & Params;

export const useDeviceErrorDialog = () => {
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState<Params | undefined>();
  const showDialog = useCallback((params: Params) => {
    setParams(params);
    setOpen(true);
  }, []);
  const hideDialog = useCallback(() => setOpen(false), []);

  return {
    open,
    params,
    showDialog,
    hideDialog,
  };
};

/**
 * デバイスエラーダイアログ
 *
 * 今のところSP表示時のみ出番あり
 */
export function DeviceErrorDialog({ open, onClose, deviceErrorCode, careSubjectName }: Props) {
  const { t } = useTranslation();
  const [code, title, message] = useMemo(() => {
    if (deviceErrorCode == null) {
      return [];
    }

    return [
      t(getDeviceErrorCode(deviceErrorCode) ?? ""),
      t(getDeviceErrorItem(deviceErrorCode) ?? ""),
      t(getDeviceErrorMessage(deviceErrorCode) ?? ""),
    ];
  }, [t, deviceErrorCode]);

  if (deviceErrorCode == null || code == null || title == null || message == null) {
    return null;
  }

  return (
    <CommonDialog
      dialogOpen={open}
      title={`${code}`}
      cancelButtonName={t("common.button.close")}
      onCancel={onClose}
      closeOnClickBackdropOrEscape={true}
    >
      <SpDataTable>
        <SpDataTable.Row title={t("common.care_subject.care_subject")} value={careSubjectName} />
        <SpDataTable.Row
          title={t("module.device_error.error_detail")}
          value={
            <Box>
              <Typography>{title}</Typography>
              <Typography>{message}</Typography>
            </Box>
          }
        />
      </SpDataTable>
    </CommonDialog>
  );
}
