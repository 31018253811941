import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import type { RootState } from "src/app/store";
import { helppadApi } from "./helppadApi";
import { readNumberLocalStorage } from "src/utils/localStorage";
import { Constants } from "src/constants/commonConstants";

const globalProps = createSlice({
  name: "sideMenu",
  initialState: {
    authorized: localStorage.getItem("token") ? true : false,
    isSideMenuOpened: localStorage.getItem("token") ? true : false,
    staffId: localStorage.getItem("staff_id") ? Number(localStorage.getItem("staff_id")) : undefined,
    facilityId: localStorage.getItem("facility_id") ? Number(localStorage.getItem("facility_id")) : undefined,
    lastReadAnnouncementId: readNumberLocalStorage(Constants.LAST_READ_ANNOUNCEMENT_ID_KEY, 0),
    currentAnnouncementId: readNumberLocalStorage(
      Constants.CURRENT_ANNOUNCEMENT_ID_KEY,
      process.env.REACT_APP_HELPPAD2_ANNOUNCEMENT_ID
    ),
    isOpenAnnouncementModal: false,
    isOpenTermsOfUseModal: false,
  },
  reducers: {
    resetToken: (state) => {
      state.authorized = false;
      localStorage.removeItem("role_master_id");
      localStorage.removeItem("role_member_id");
      localStorage.removeItem("facility_id");
      localStorage.removeItem(Constants.CURRENT_ANNOUNCEMENT_ID_KEY);
    },
    showSideMenu: (state) => {
      state.isSideMenuOpened = true;
    },
    hideSideMenu: (state) => {
      state.isSideMenuOpened = false;
    },
    openAnnouncementModal: (state) => {
      state.isOpenAnnouncementModal = true;
    },
    closeAnnouncementModal: (state) => {
      state.isOpenAnnouncementModal = false;
    },
    setAnnouncementId: (state, action) => {
      state.lastReadAnnouncementId = action.payload;
    },
    openTermsOfUseModal: (state) => {
      state.isOpenTermsOfUseModal = true;
    },
    closeTermsOfUseModal: (state) => {
      state.isOpenTermsOfUseModal = false;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(helppadApi.endpoints.login.matchFulfilled, (state, { payload }) => {
      if (payload.token != null) {
        state.authorized = true;
        state.staffId = payload.staff_id;
        state.facilityId = payload.facility_id;
        localStorage.setItem("staff_id", `${payload.staff_id}`);
        localStorage.setItem("token", payload.token);
        localStorage.setItem("facility_id", `${payload.facility_id}`);
        localStorage.setItem(Constants.CURRENT_ANNOUNCEMENT_ID_KEY, String(state.currentAnnouncementId));
        localStorage.setItem(Constants.LAST_READ_ANNOUNCEMENT_ID_KEY, String(state.lastReadAnnouncementId));
      }
    });
    builder.addMatcher(helppadApi.endpoints.logout.matchFulfilled, (state) => {
      state.authorized = false;
      localStorage.removeItem("staff_id");
      localStorage.removeItem("token");
      localStorage.removeItem("facility_id");
      localStorage.removeItem(Constants.CURRENT_ANNOUNCEMENT_ID_KEY);
      // RTKQuery のキャッシュを飛ばすためにリロードする
      window.location.reload();
    });
  },
});

export const {
  resetToken,
  showSideMenu,
  hideSideMenu,
  openAnnouncementModal,
  closeAnnouncementModal,
  setAnnouncementId,
  openTermsOfUseModal,
  closeTermsOfUseModal,
} = globalProps.actions;
const value = (state: RootState) => state.globalPropsReducer;
export const useGlobalProps = () => {
  return useSelector(value);
};
export default globalProps.reducer;
