import { useCallback } from "react";
import { ModelsCareSubject, ModelsStaff } from "src/store/helppadApi";
import { usePostExcretionResultMutation } from "src/store/enhancedApi";
import { Amount } from "src/types/amount";
import { FecesFirmness } from "src/types/fecesFirmness";
import { useCareSubjectExcretionSupport } from "./useCareSubjectExcretionSupport";
import { useTranslation } from "react-i18next";

/**
 * 対応内容の登録用の states やロジック
 */
export const useCareSubjectCreateExcretionSupport = (
  staffs: ModelsStaff[] | undefined,
  loginStaff: ModelsStaff,
  careSubject: ModelsCareSubject,
  defaultValues: {
    supportStaffId: number | undefined;
  }
) => {
  const { t } = useTranslation();
  const { privateStates, publicStates } = useCareSubjectExcretionSupport(staffs, {
    ...defaultValues,
    status: "idling",
  });
  const { navigate, setDisabled, showNotification, confirmDialogState, onChangeStatus } = privateStates;
  const { inputValues, supportStaff } = publicStates;

  const [postExcretionResultApi] = usePostExcretionResultMutation();

  // 登録
  const onRegister = useCallback(async () => {
    if (supportStaff == null) {
      return;
    }

    const { excretionSupportType, supportedAt, isLeaked, urineAmount, fecesAmount, fecesFirmness, supportedPlace } =
      inputValues;
    const { display_name: staffDisplayName } = supportStaff;

    setDisabled(true);
    onChangeStatus("processing");

    try {
      const result = await postExcretionResultApi({
        modelsExcretionResultPostIn: {
          care_subject_id: careSubject.id!,
          staff_id: loginStaff.id!,
          excretion_type: excretionSupportType,
          input_person: staffDisplayName!,
          supported_at: supportedAt.toISOString(),
          is_leaked: isLeaked,
          urine_amount: urineAmount ?? Amount.None, // 未指定時は「入力なし」として設定
          feces_amount: fecesAmount ?? Amount.None, // 未指定時は「入力なし」として設定
          feces_firmness: fecesFirmness ?? FecesFirmness.None, // 未入力時は「入力なし」として設定
          supported_place: supportedPlace,
          // karte, メモ入力が可能になるまでコメントアウト
        },
      });
      if ("error" in result) {
        throw result.error;
      }

      // 反映されるまで時間がかかるので1秒待機
      await new Promise((resolve) => setTimeout(resolve, 1000));

      showNotification(t("module.excretion_support.registered_notification"));
      onChangeStatus("finished");
      confirmDialogState.hideDialog();

      navigate(-1);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
      showNotification(t("module.excretion_support.failed_registration_notification"));
      onChangeStatus("idling");
    } finally {
      setDisabled(false);
    }
  }, [
    inputValues,
    supportStaff,
    postExcretionResultApi,
    onChangeStatus,
    showNotification,
    careSubject,
    loginStaff,
    navigate,
    t,
    confirmDialogState,
    setDisabled,
  ]);

  return {
    ...publicStates,
    onClickRegisterOnDialog: onRegister,
  };
};
