import { useCallback, useRef, useState } from "react";
import { Box, Grid, Typography, DialogContent } from "@mui/material";
import { CommonDialog } from "./CommonDialog";
import { Group } from "src/constants/commonConstants";
import { useDeleteGroupMutation } from "src/store/enhancedApi";
import { useTranslation } from "react-i18next";

type Props = {
  deleteGroupRecord: Group | null;
  saveDeleteGroupRecord: () => void;
  handleClose: () => void;
  registering: boolean; // 登録処理中か
  registered: boolean; // 登録処理が完了したか
  error?: Error; // 登録処理で発生したエラー
};

export const useDeleteGroup = () => {
  const [deleteGroupRecord, setGroupRecord] = useState<Group | null>(null);
  const [deleteRegistering, setRegistering] = useState(false);
  const [deleteRegistered, setRegistered] = useState(false);

  const [deleteApi /*{ isError, isSuccess }*/] = useDeleteGroupMutation(); // TODO apiハンドリング

  const showDeleteDialog = useCallback(({ id, name, pushNotify }: Group) => {
    setGroupRecord({
      id,
      name,
      pushNotify,
    });
  }, []);

  const hideDeleteDialog = useCallback(() => {
    setGroupRecord(null);
    setRegistered(false);
  }, []);

  const startRegistering = useCallback(() => {
    setRegistering(true);
  }, []);

  const finishRegistering = useCallback(() => {
    setRegistering(false);
  }, []);

  const onRegistered = useCallback(() => {
    setRegistered(true);
  }, []);

  const saveDeleteGroupRecord = useCallback(() => {
    startRegistering();
    deleteApi({
      id: deleteGroupRecord?.id ? deleteGroupRecord?.id : 0,
    });
    finishRegistering();
    onRegistered();
  }, [deleteApi, startRegistering, finishRegistering, onRegistered, deleteGroupRecord]);

  return {
    deleteGroupRecord,
    saveDeleteGroupRecord,
    showDeleteDialog,
    hideDeleteDialog,
    deleteRegistering,
    deleteRegistered,
  };
};

export const GroupDeleteModal = ({
  deleteGroupRecord,
  saveDeleteGroupRecord,
  handleClose,
  registering,
  registered,
}: Props) => {
  const { t } = useTranslation();

  const textFieldRef = useRef<HTMLInputElement>(null);
  const handleAnimationEnd = useCallback(() => {
    const { current } = textFieldRef;
    if (current == null) {
      return;
    }
    current.focus();
    current.selectionStart = current.value.length;
    current.selectionEnd = current.value.length;
  }, [textFieldRef]);

  if (!deleteGroupRecord) return null;

  const { name } = deleteGroupRecord;

  return (
    <CommonDialog
      dialogOpen={!!deleteGroupRecord}
      dialogAnimationEnd={handleAnimationEnd}
      title={t("module.group.delete_group")}
      acceptButtonName={t("common.button.delete")}
      cancelButtonName={t("common.button.cancel")}
      onAccept={saveDeleteGroupRecord}
      onCancel={handleClose}
      isChecked={true}
      isProcessed={registering}
      isCompleted={registered}
      completeMessage={t("module.group.deleted_group")}
    >
      <DialogContent>
        <Box
          alignItems="center"
          justifyContent="center"
          sx={{
            margin: "0 30px",
          }}
        >
          <Grid item xs={11}>
            <Typography display="flex" justifyContent="left">
              {t("module.group.confirm_deletion_group")}
            </Typography>
          </Grid>
        </Box>
        <Box
          alignItems="center"
          justifyContent="center"
          sx={{
            margin: "20px 30px",
            border: "1px solid #ccc",
            borderRadius: "10px",
            padding: "30px",
          }}
        >
          <Grid container alignItems="center" rowGap={3}>
            <Grid item xs={4}>
              <Typography textAlign="right" mr={3}>
                {t("common.group.group_name")}：
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{name}</Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </CommonDialog>
  );
};
