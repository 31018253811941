import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const FILES = [
  { name: "general_contents.png", alt: "General contents" },
  { name: "general_help1.png", alt: "General help 1" },
  { name: "general_help2.png", alt: "General help 2" },
  { name: "general_help3.png", alt: "General help 3" },
  { name: "general_help4.png", alt: "General help 4" },
];

export const GeneralHelp = () => {
  const { i18n } = useTranslation();
  const basePath = useMemo(() => `/help/${i18n.language}/general_help`, [i18n.language]);

  return (
    <p>
      {FILES.map((file) => (
        <img key={file.name} src={`${basePath}/${file.name}`} alt={file.alt}></img>
      ))}
    </p>
  );
};
