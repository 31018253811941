import { styled } from "@mui/system";
import { Box, Drawer, IconButton, Paper, Typography } from "@mui/material";
import { Constants } from "src/constants/commonConstants";
import { CareSubjectName } from "src/modules/sp/CareSubjectName";
import { SlidePanel } from "src/modules/sp/SlidePanel";
import {
  useListCommentThreadQuery,
  usePatchCommentLogMutation,
  usePostCommentLogMutation,
} from "src/store/enhancedApi";
import Slide from "@mui/material/Slide";
import { ModelsCareSubject, ModelsCommentLog, ModelsStaff, useDeleteCommentLogMutation } from "src/store/helppadApi";
import { formatLogDate } from ".";
import { faEdit, faPaperPlane, faTrash } from "@fortawesome/free-solid-svg-icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useMemo, useCallback, ChangeEvent, useEffect, useRef } from "react";
import { SpPage } from "src/modules/sp/SpPage";
import { useSpNotification } from "src/utils/useSpNotification";
import { useListCommentLog } from "src/utils/useListCommentLog";
import { DeleteMemoDialog, useDeleteMemoDialog } from "src/modules/dialog/DeleteMemoDialog";
import { useLocale } from "src/utils/localeMap";
import { useTranslation } from "react-i18next";

type Props = {
  staff: ModelsStaff;
  careSubject: ModelsCareSubject;
};
const useThis = (staff: ModelsStaff, careSubject: ModelsCareSubject) => {
  const { t } = useTranslation();

  const careSubjectId = careSubject.id as number;
  const [mode, setMode] = useState<"default" | "edit">("default");
  const { logs, fetch: fetchCommentLog } = useListCommentLog();
  const { showNotification } = useSpNotification();
  const [postCommentLog] = usePostCommentLogMutation();
  const [patchCommentLog] = usePatchCommentLogMutation();
  const [deleteCommentLogApi] = useDeleteCommentLogMutation();

  // メモの再読み込み
  const reloadComments = useCallback(
    async (threadId: number) => {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      await fetchCommentLog(threadId);
    },
    [fetchCommentLog]
  );

  const commentLogs = useListCommentThreadQuery({ careSubjectId: [careSubjectId] }, Constants.POLLING_INTERVAL).data
    ?.items;

  const threadId = useMemo(() => {
    if (commentLogs == null || commentLogs.length === 0) {
      return undefined;
    }
    return commentLogs[0].id as number;
  }, [commentLogs]);

  useEffect(() => {
    if (threadId == null) {
      return;
    }
    fetchCommentLog(threadId);
    const interval = setInterval(() => fetchCommentLog(threadId), Constants.POLLING_INTERVAL.pollingInterval);

    return () => {
      clearInterval(interval);
    };
  }, [threadId, fetchCommentLog]);

  const [disabled, setDisabled] = useState(false);
  const [newMemoText, setNewMemoText] = useState("");
  const onChangeNewMemoText = useCallback((next: string) => {
    setNewMemoText(next);
  }, []);

  // メモの投稿
  const onCreateMemo = useCallback(async () => {
    if (threadId == null) {
      return;
    }
    setDisabled(true);
    try {
      const result = await postCommentLog({
        modelsCommentLogPostIn: {
          staff_id: staff.id as number,
          message: newMemoText,
          input_person: staff.display_name as string,
          thread_id: threadId,
        },
      });
      if ("error" in result) {
        throw result.error;
      }

      // 反映が遅いので再読みこみかける
      await reloadComments(threadId);

      showNotification(t("care_subject.comment_logs.sent_notification"));
      setNewMemoText("");
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
      showNotification(t("care_subject.comment_logs.failed_send_notification"));
    } finally {
      setDisabled(false);
    }
    setDisabled(false);
  }, [t, postCommentLog, newMemoText, staff, threadId, showNotification, reloadComments]);

  const [showMemoMenu, setShowMemoMenu] = useState(false);

  // コメント右の「…」ボタンが押された際に値が入る
  const [focusedLog, setFocusedLog] = useState<ModelsCommentLog | undefined>();

  const [editMemoText, setEditMemoText] = useState("");
  const onClickMemoMemo = useCallback(
    (logId: number) => {
      const log = logs?.find((l) => l.id === logId);
      if (log === null) {
        return;
      }

      // モード変更を最初にもとに戻すのは閉じる際に実施すると見た目が先にもどるため
      setMode("default");
      setShowMemoMenu(true);
      setFocusedLog(log);
    },
    [logs]
  );
  const onCloseMemoMenu = useCallback(() => {
    // なにかしてる最中は閉じさせない
    if (disabled) {
      return;
    }
    setShowMemoMenu(false);
    setFocusedLog(undefined);
  }, [disabled]);
  const onClickEdit = useCallback(() => {
    if (focusedLog == null) {
      return;
    }
    setMode("edit");
    setEditMemoText(focusedLog.message as string);
  }, [focusedLog]);
  const onChangeEditMemoText = useCallback((next: string) => {
    setEditMemoText(next);
  }, []);

  const onSuccessDeletingMemo = useCallback(() => {
    setShowMemoMenu(false);
    setFocusedLog(undefined);

    if (threadId == null) {
      return;
    }
    // 再読み込みをかける
    reloadComments(threadId);

    showNotification(t("care_subject.comment_logs.deleted_notification"));
  }, [t, reloadComments, threadId, showNotification]);

  const onFailureDeletingMemo = useCallback(() => {
    showNotification(t("care_subject.comment_logs.failed_delete_notification"));
  }, [t, showNotification]);

  const deleteCommentLog = useCallback(
    async ({ id }: { id?: number }) => {
      if (id == null) {
        return;
      }
      await deleteCommentLogApi({ id });
    },
    [deleteCommentLogApi]
  );

  const deleteMemoDialogState = useDeleteMemoDialog({
    closeOnSuccess: true,
    onSuccess: onSuccessDeletingMemo,
    onFailure: onFailureDeletingMemo,
    deleteCommentLog,
  });
  const { showDialog: showDeleteMemoDialog } = deleteMemoDialogState;

  const onClickDelete = useCallback(() => {
    if (focusedLog == null) {
      return;
    }
    setShowMemoMenu(false);
    showDeleteMemoDialog(focusedLog.id as number, focusedLog.input_person as string, focusedLog.message as string);
  }, [showDeleteMemoDialog, focusedLog]);

  // メモの編集
  const onUpdateMemo = useCallback(async () => {
    if (threadId == null || focusedLog == null) {
      return;
    }
    setDisabled(true);
    try {
      const result = await patchCommentLog({
        id: focusedLog.id!,
        modelsCommentLogPatchIn: {
          message: editMemoText,
        },
      });
      if ("error" in result) {
        throw result.error;
      }

      // 反映が遅いので再読みこみかける
      await reloadComments(threadId);

      showNotification(t("care_subject.comment_logs.edited_notification"));
      setEditMemoText("");

      setShowMemoMenu(false);
      setFocusedLog(undefined);
    } catch (error) {
      if (error instanceof Error) {
        console.error(error.message);
      }
      showNotification(t("care_subject.comment_logs.failed_edit_notification"));
    } finally {
      setDisabled(false);
    }
  }, [t, patchCommentLog, threadId, showNotification, reloadComments, editMemoText, focusedLog]);

  return {
    threadId,
    mode,
    logs,
    disabled,
    newMemoText,
    onChangeNewMemoText,
    onCreateMemo,
    showMemoMenu,
    onClickMemoMemo,
    onCloseMemoMenu,
    focusedLog,
    onClickEdit,
    editMemoText,
    onClickDelete,
    onChangeEditMemoText,
    onUpdateMemo,
    deleteMemoDialogState,
  };
};

/**
 * [SP用]
 * みんなのメモ
 */
export const CareSubjectComment = (props: Props) => {
  const { t } = useTranslation();

  const { staff, careSubject } = props;

  const {
    logs,
    mode,
    disabled,
    newMemoText,
    onChangeNewMemoText,
    onCreateMemo,
    showMemoMenu,
    onClickMemoMemo,
    onCloseMemoMenu,
    focusedLog,
    onClickEdit,
    editMemoText,
    onClickDelete,
    onChangeEditMemoText,
    onUpdateMemo,
    deleteMemoDialogState,
  } = useThis(staff, careSubject);
  return (
    <SpPage
      bottomFixedComponent={
        <MemoInputForm
          memoText={newMemoText}
          onChangeMemo={onChangeNewMemoText}
          onClickSend={onCreateMemo}
          disabled={disabled}
        />
      }
    >
      <SlidePanel title={t("care_subject.comment_logs.staff_memo")} disabled={disabled}>
        <Box display="flex" width={"100%"} flexDirection={"column"}>
          {/* 入居者情報 */}
          <Box boxSizing={"border-box"} px={2} mb={2}>
            <CareSubjectName name={careSubject.name} />
          </Box>
          {/* メモ */}
          <Box boxSizing={"border-box"} px={2} mb={2} flex={1} width={"100%"} overflow={"auto"}>
            {logs && (
              <CommentLogsList
                disabled={disabled}
                staff={staff}
                logs={logs}
                focusedLogId={focusedLog?.id}
                onClickMemoMenu={onClickMemoMemo}
              />
            )}
          </Box>
          {/* メモクリック時のメニュー */}
          <MemoMenu
            mode={mode}
            disabled={disabled}
            show={showMemoMenu}
            onClose={onCloseMemoMenu}
            onClickEdit={onClickEdit}
            onClickDelete={onClickDelete}
            editMemoText={editMemoText}
            onChangeEditMemoText={onChangeEditMemoText}
            onClickSendMemo={onUpdateMemo}
          />
        </Box>
      </SlidePanel>
      <DeleteMemoDialog
        open={deleteMemoDialogState.open}
        deleting={deleteMemoDialogState.deleting}
        deleted={deleteMemoDialogState.deleted}
        target={deleteMemoDialogState.target}
        onConfirm={deleteMemoDialogState.deleteMemo}
        onCancel={deleteMemoDialogState.hideDialog}
      />
    </SpPage>
  );
};

export const MemoMenuButton = (props: { disabled?: boolean; logId: number; onClick: (logId: number) => void }) => {
  const { logId, onClick } = props;
  const handleClick = useCallback(() => {
    onClick(logId);
  }, [logId, onClick]);

  return (
    <IconButton disabled={props.disabled} sx={{ minWidth: "48px", minHeight: "48px" }} onClick={handleClick}>
      <MoreVertIcon />
    </IconButton>
  );
};

/**
 * onClickEdit が undefined の場合は編集ボタンは必ず出ない。
 */
export const CommentLogsList = (props: {
  disabled?: boolean;
  staff: ModelsStaff;
  logs: ModelsCommentLog[];
  focusedLogId?: number; // メニューボタンが押されたメモのID
  onClickMemoMenu?: (logId: number) => void;
}) => {
  const locale = useLocale();
  const { staff, logs, focusedLogId, onClickMemoMenu: onClickEdit } = props;

  return (
    <Box sx={{ background: "#FFF", borderRadius: "0.5rem" }}>
      {logs.map((log, index) => {
        return (
          <Box
            display="flex"
            bgcolor={log.id === focusedLogId ? "#FEF4E5" : undefined}
            key={`Comment-${index}`}
            sx={{
              ":not(:last-child)": { borderBottom: "1px solid #E6E6E6" },
              ":hover": { opacity: 0.7 },
            }}
            p={2}
            color="#222222"
            zIndex={log.id === focusedLogId ? 16000 : 0}
          >
            <Box flex={1} sx={{ maxWidth: "calc(100% - 48px)" }}>
              <Box display={"flex"}>
                <Typography
                  sx={{
                    fontSize: 14,
                    fontWeight: 700,
                    alignSelf: "center",
                    color: log.staff_id === staff.id ? "#F29500" : "#222222",
                  }}
                >
                  {log.input_person}
                </Typography>
                <Typography sx={{ color: "#606060", fontSize: 12, ml: 1, alignSelf: "center" }}>
                  {formatLogDate(log.created_at!, locale)}
                </Typography>
              </Box>
              <Box width={"100%"} sx={{ maxWidth: "100%", whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
                <Typography fontSize={"14px"}>{log.message}</Typography>
              </Box>
            </Box>
            <Box width={"48px"}>
              {onClickEdit && (
                <MemoMenuButton disabled={props.disabled} logId={log.id as number} onClick={onClickEdit} />
              )}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

type MemoInputFormProps = {
  memoText: string;
  onChangeMemo: (next: string) => void;
  onClickSend: () => void;
  disabled: boolean;
};

/**
 * 下部に表示するメモ入力フォーム
 */
const MemoInputForm = (props: MemoInputFormProps) => {
  const { t } = useTranslation();

  const { memoText, onChangeMemo, disabled } = props;
  const handleChangeMemo = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChangeMemo(event.target.value);
    },
    [onChangeMemo]
  );
  const disabledSendButton = useMemo(
    () => disabled || memoText.length === 0 || memoText.length > 200,
    [disabled, memoText]
  );

  return (
    <Box bgcolor={"#FFF"} bottom={0} sx={{ boxShadow: "0px 0px 15px -5px #777" }}>
      <Form>
        <Box flex={1} display={"flex"}>
          <Textarea
            sx={{ minHeight: "64px" }}
            disabled={props.disabled}
            placeholder={t("care_subject.comment_logs.please_input_memo")}
            value={props.memoText}
            onChange={handleChangeMemo}
          />
          <Box display={"flex"} alignItems={"center"} mx={1}>
            <FormFooter>
              <IconButton
                sx={{ minWidth: "48px", minHeight: "48px" }}
                onClick={props.onClickSend}
                disabled={disabledSendButton}
                style={{ opacity: disabledSendButton ? 0.3 : 1, background: "#F29500" }}
              >
                <FontAwesomeIcon color="#FFF" fontSize="22px" icon={faPaperPlane} />
              </IconButton>
            </FormFooter>
          </Box>
        </Box>
      </Form>
    </Box>
  );
};

type MemoMenuProps = {
  mode: "default" | "edit";
  show: boolean;
  onClose: () => void;
  disabled: boolean;
  onClickEdit: () => void;
  editMemoText: string;
  onClickDelete: () => void;
  onChangeEditMemoText: (next: string) => void;
  onClickSendMemo: () => void;
};

/**
 * メモの「…」が押された際に表示するメニュー
 */
const MemoMenu = (props: MemoMenuProps) => {
  const { t } = useTranslation();
  const ref = useRef();

  return (
    <Drawer
      anchor={"bottom"}
      open={props.show}
      onClose={props.onClose}
      ModalProps={{
        disableEscapeKeyDown: props.disabled,
      }}
      PaperProps={{
        sx: {
          backgroundColor: "rgba(0,0,0,0)",
        },
      }}
    >
      <Box ref={ref} width="100%" role="presentation" overflow={"hidden"}>
        {props.mode === "default" ? (
          <Box bgcolor={"#FFF"} color="#000" p={1}>
            <Box onClick={props.onClickEdit} sx={{ cursor: "pointer", "&:hover": { opacity: 0.7 } }}>
              <Box display={"flex"} p={2} alignItems={"center"}>
                <FontAwesomeIcon fontSize={"20px"} icon={faEdit} />
                <Typography ml="16px">{t("care_subject.comment_logs.edit")}</Typography>
              </Box>
            </Box>
            <Box onClick={props.onClickDelete} sx={{ cursor: "pointer", "&:hover": { opacity: 0.7 } }}>
              <Box display={"flex"} p={2} alignItems={"center"}>
                <FontAwesomeIcon fontSize={"20px"} icon={faTrash} />
                <Typography ml="16px">{t("care_subject.comment_logs.delete")}</Typography>
              </Box>
            </Box>
          </Box>
        ) : (
          <Slide direction="up" in={true} container={ref.current}>
            <Paper elevation={4}>
              <MemoInputForm
                memoText={props.editMemoText}
                onChangeMemo={props.onChangeEditMemoText}
                onClickSend={props.onClickSendMemo}
                disabled={props.disabled}
              />
            </Paper>
          </Slide>
        )}
      </Box>
    </Drawer>
  );
};

const Textarea = styled("textarea")`
  background-color: inherit;
  border: none;
  padding: 8px;
  width: 100%;
  resize: none;
  font-size: 16px;
`;

const Form = styled("form")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormFooter = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
