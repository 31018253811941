import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * 必須タグ
 */
export const RequiredTag = () => {
  const { t } = useTranslation();
  return (
    <Box display={"inline-block"} ml={1} maxHeight={"20px"} height={"20px"}>
      <Box
        display={"flex"}
        alignItems={"center"}
        p={"4px"}
        fontSize={"12px"}
        fontWeight={700}
        sx={{ background: "#F29500", color: "#FFF" }}
        lineHeight={1}
      >
        {t("common.tag.required")}
      </Box>
    </Box>
  );
};
