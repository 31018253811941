import { Fragment, memo, useEffect } from "react";
import { useGlobalProps } from "src/store/GlobalProps";
import { useGetFacilityQuery, useGetStaffQuery } from "src/store/helppadApi";
import { v5 as uuidv5 } from "uuid";

const NAMESPACE_OID = "6ba7b812-9dad-11d1-80b4-00c04fd430c8"; // OID NAMESPACE

// FullStar の javascript を利用するとグローバル環境に自動的に定義される
declare const fs: {
  clear: () => void;
  tracking: () => void;
  show: (key: string, uuid: string, params: any) => void;
  survey: () => void;
  guide: () => void;
  checklist: () => void;
  tooltip: () => void;
};

/**
 * FullStar でのトラッキングを有効にするためのコンポーネント
 * ログインした後にだけ色々したいのでコンポーネントにしているが何もレンダリングはされない。
 */
export const FullStarTracking = () => {
  const { authorized, staffId } = useGlobalProps();

  if (!authorized || staffId == null) {
    return null;
  }

  return <Initialize staffId={staffId} />;
};

const Initialize = memo(({ staffId }: { staffId: number }) => {
  // 画面表示時に一度だけ必要な情報を読み込んで FullStar の初期化を行う(= ここでは定期的なポーリングは実施しない)
  const { data: staff } = useGetStaffQuery({ id: staffId });
  const { data: facility } = useGetFacilityQuery();

  useEffect(() => {
    if (staff != null && facility != null) {
      const oid = `${facility.login_id}:${staff.login_id}`;

      const companyName = facility.name;
      const userName = staff.id;
      const userEmail = "dummy";
      const planName = "dummy";
      const uuid = uuidv5(oid, NAMESPACE_OID);

      fs.clear();
      fs.show("e6a95b3a-9631-4a0c-8b73-0a954e891fbc", uuid, {
        company_name: companyName,
        user_name: userName,
        user_email: userEmail,
        plan_name: planName,
        tags: "",
      });
      fs.tracking();
      fs.survey();
      fs.guide();
      fs.checklist();
      fs.tooltip();
    }
  }, [staff, facility]);

  return <Fragment />;
});
