import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { Button, Box, Grid } from "@mui/material";
import { parseFirstAlertedAt, toTimeDiffString } from "src/utils/dateUtil";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  createdAt: string | undefined;
  openModal: () => void;
};

export const ExcretionResult = ({ createdAt, openModal }: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        p: 2,
        width: "100%",
        boxSizing: "border-box",
        border: "1px solid #e6e6e6",
        borderRadius: 2,
        backgroundColor: "#FFFFFF",
      }}
    >
      <Grid container direction="column" gap={1}>
        <Box
          sx={{
            py: 1,
            border: "1px solid #e6e6e6",
            borderRadius: 2,
            display: "flex",
            flexDirection: "column",
            px: "14px",
          }}
        >
          <Result
            label={t("care_subject.excretion_result.alert_time")}
            value={createdAt ? format(new Date(createdAt), "HH:mm", { locale: ja }) : "-"}
          />
          <Result
            label={t("care_subject.excretion_result.elapsed_time")}
            value={createdAt ? `${toTimeDiffString(new Date(), parseFirstAlertedAt(createdAt), t)}` : "-"}
          />
        </Box>
        <Grid container justifyContent="center" gap={3} py={1}>
          <RegisterSupportButton onClick={openModal}>
            {t("common.button.register_excretion_result")}
          </RegisterSupportButton>
        </Grid>
      </Grid>
    </Box>
  );
};

const Result = ({ label, value }: { label: string; value: string }) => {
  return (
    <Box display={"flex"} sx={{ alignItems: "center" }}>
      <Box sx={{ fontSize: "18px" }}>{label}</Box>
      <Box sx={{ fontSize: "24px", textAlign: "right", flex: 1 }}>{value}</Box>
    </Box>
  );
};

export const RegisterSupportButton = (props: { onClick: () => void; children: ReactNode | ReactNode[] }) => {
  return (
    <Button
      variant="contained"
      sx={{
        width: "100%",
        fontSize: 24,
        fontWeight: "bold",
        color: "#FFFFFF",
        backgroundColor: "#F29500",
        "&:hover": {
          backgroundColor: "#efac47",
        },
        borderRadius: "40px",
        textTransform: "none",
      }}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
};
