import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const FILES = [
  { name: "general_sp_contents.png", alt: "General sp contents" },
  { name: "general_sp_help1.png", alt: "General sp help 1" },
  { name: "general_sp_help2.png", alt: "General sp help 2" },
  { name: "general_sp_help3.png", alt: "General sp help 3" },
  { name: "general_sp_help4.png", alt: "General sp help 4" },
  { name: "general_sp_help5.png", alt: "General sp help 5" },
];

export const GeneralSpHelp = () => {
  const { i18n } = useTranslation();
  const basePath = useMemo(() => `/help/${i18n.language}/general_sp_help`, [i18n.language]);

  return (
    <p>
      {FILES.map((file) => (
        <img key={file.name} src={`${basePath}/${file.name}`} alt={file.alt}></img>
      ))}
    </p>
  );
};
