import { TFunction } from "i18next";

export const FecesFirmness = {
  None: 0, // 入力無し
  Pebble: 1, // コロコロ便
  Hard: 2, // 硬い便
  SlightlyHard: 3, // やや硬い便
  Normal: 4, // 普通便
  SlightlySoft: 5, // やや軟らかい便
  Mushy: 6, // 泥状便
  Watery: 7, // 水様便
} as const;

// このオブジェクトの型
export type FecesFirmnessType = typeof FecesFirmness[keyof typeof FecesFirmness];

export function parseFecesFirmnessNumber(num: number | undefined): FecesFirmnessType | undefined {
  if (num == null) {
    return undefined;
  }
  const valid = (Object.values(FecesFirmness) as number[]).includes(num);
  return valid ? (num as FecesFirmnessType) : undefined;
}

export function toFecesFirmnessName(value: FecesFirmnessType | undefined, t: TFunction): string {
  switch (value) {
    case FecesFirmness.None:
      return t("common.excretion.feces_firmness_none");
    case FecesFirmness.Pebble:
      return t("common.excretion.feces_firmness_pebble");
    case FecesFirmness.Hard:
      return t("common.excretion.feces_firmness_hard");
    case FecesFirmness.SlightlyHard:
      return t("common.excretion.feces_firmness_slightly_hard");
    case FecesFirmness.Normal:
      return t("common.excretion.feces_firmness_normal");
    case FecesFirmness.SlightlySoft:
      return t("common.excretion.feces_firmness_slightly_soft");
    case FecesFirmness.Mushy:
      return t("common.excretion.feces_firmness_mushy");
    case FecesFirmness.Watery:
      return t("common.excretion.feces_firmness_watery");
  }

  return t("common.excretion.feces_firmness_none");
}
