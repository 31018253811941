import { TFunction } from "i18next";

export const IntegrationAppData = {
  Alert: "alert", // 通知情報
  Record: "record", // 記録情報
  ExcretionResult: "excretion_result", // 排泄記録情報
} as const;
export type IntegrationAppDataType = typeof IntegrationAppData[keyof typeof IntegrationAppData];

export const toDataTypeName = (type: IntegrationAppDataType | undefined, t: TFunction) => {
  switch (type) {
    case "alert":
      return t("module.integration_app.notification_information");
    case "record":
      return t("module.integration_app.record_information");
    case "excretion_result":
      return t("module.integration_app.excretion_result_information");
    default:
      return "---";
  }
};

export const toDataType = (name: string): IntegrationAppDataType | undefined => {
  switch (name) {
    case "alert":
      return IntegrationAppData.Alert;
    case "record":
      return IntegrationAppData.Record;
    case "excretion_result":
      return IntegrationAppData.ExcretionResult;
    default:
      return undefined;
  }
};
