import { Grid, Paper, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
// import Button from "@mui/material/Button";
// import { CommonButton } from "src/modules/parts/CommonButton";
// import {
//   Account,
//   ChangeAdminAccountDialog,
//   useChangeAdminAccountDialog,
// } from "src/modules/dialog/ChangeAdminAccountDialog";
import {
  useGetFacilityQuery,
  // useGetRoleQuery, useGetStaffQuery
} from "src/store/helppadApi";
// import { useGlobalProps } from "src/store/GlobalProps";

export const FacilityInformation = () => {
  const { t } = useTranslation();
  // const { staffId } = useGlobalProps();
  const facility = useGetFacilityQuery().data;
  // const staff = useGetStaffQuery({ id: staffId! }).data;
  // const role = useGetRoleQuery({ id: staff?.role_id! }).data;
  // const { props, show: showDialog } = useChangeAdminAccountDialog(async (account: Account) => {
  //   console.log("submit", account);
  // });

  return (
    <Grid sx={{ background: "#F9F9F9" }} display="flex" p={4} flexDirection="column" height="100%" width="100%">
      <Box style={{ marginTop: 29 }}>
        <Typography variant="h3" fontSize={20} fontWeight={700}>
          {t("setting.facility_information.facility_information")}
        </Typography>
        <Paper elevation={0} style={{ padding: 32, marginTop: 16, borderRadius: "0.5rem" }}>
          <Grid container spacing={3}>
            {[
              { i18nKey: "setting.facility_information.facility_name", value: facility?.name },
              { i18nKey: "setting.facility_information.facility_id", value: facility?.login_id },
              { i18nKey: "setting.facility_information.phone_number", value: facility?.phone_number },
              { i18nKey: "setting.facility_information.post_code", value: facility?.postal_code },
              { i18nKey: "setting.facility_information.address", value: facility?.address },
              { i18nKey: "setting.facility_information.mail", value: facility?.email },
            ].map((column) => {
              return (
                <Grid item container key={column.i18nKey}>
                  <Grid item xs={3}>
                    {t(column.i18nKey)}
                  </Grid>
                  <Grid item xs={9}>
                    {column.value}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Paper>

        {/* TODO: 仕様確定まで非表示
        <Grid container justifyContent="space-between" style={{ marginTop: 44 }}>
          <Typography variant="h3" fontSize={20} fontWeight={700}>
            スタッフ管理者（窓口）
          </Typography>
          <CommonButton label={"管理者アカウントの変更"} onClick={() => showDialog()} />
        </Grid>
        <Paper elevation={0} style={{ padding: 32, marginTop: 16, borderRadius: "0.5rem" }}>
          <Grid container spacing={3}>
            {[
              { label: "アカウント役割", value: role?.name },
              { label: "スタッフ管理者名", value: staff?.name },
              { label: "スタッフID", value: staff?.login_id },
            ].map((column) => {
              return (
                <Grid item container key={column.label}>
                  <Grid item xs={3}>
                    {column.label}
                  </Grid>
                  <Grid item xs={9}>
                    {column.value}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Paper>

        <ChangeAdminAccountDialog {...props} /> */}
      </Box>
    </Grid>
  );
};
