import { useCallback, useState } from "react";
import { Constants } from "src/constants/commonConstants";
import { NotificationSounds, NotificationConstants } from "src/constants/notificationSound";
import useSound from "use-sound";

/*
 * 指定されたsoundKeyの通知音を再生、停止する
 */
export const useSoundByKey = (soundKey: string) => {
  const [isSoundPlaying, setIsSoundPlaying] = useState(false);
  const [play, { stop }] = useSound(NotificationSounds[soundKey as keyof typeof NotificationSounds], {
    interrupt: true,
    volume: Constants.SOUND_VOLUME,
    onplay: () => setIsSoundPlaying(true),
    onend: () => setIsSoundPlaying(false),
    onstop: () => setIsSoundPlaying(false),
  });

  const playSound = useCallback(() => {
    play();
  }, [play]);

  const stopSound = useCallback(() => {
    stop();
  }, [stop]);

  return { playSound, stopSound, isSoundPlaying };
};

/*
 * 登録されている通知音を一括で管理し再生、停止する
 */
export const useNotificationSounds = () => {
  const sounds = [
    { id: NotificationConstants.NOTIFICATION_BELL_2S, ...useSoundByKey(NotificationConstants.NOTIFICATION_BELL_2S) },
    { id: NotificationConstants.NOTIFICATION_BELL_8S, ...useSoundByKey(NotificationConstants.NOTIFICATION_BELL_8S) },
    {
      id: NotificationConstants.NOTIFICATION_BELL_16S,
      ...useSoundByKey(NotificationConstants.NOTIFICATION_BELL_16S),
    },
  ];

  const stopAllSounds = () => {
    sounds.forEach(({ stopSound }) => stopSound());
  };

  const playSoundById = (id: string) => {
    const sound = sounds.find((sound) => sound.id === id);
    if (sound) sound.playSound();
  };

  return { sounds, stopAllSounds, playSoundById };
};
