import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ExcretionSupportType,
  ExcretionSupportTypes,
  isFecesCategory,
  isUrineCategory,
} from "src/types/ExcretionSupportType";
import { set, format } from "date-fns";
import { ModelsStaff } from "src/store/helppadApi";
import { useNavigate } from "react-router-dom";
import { useSpNotification } from "src/utils/useSpNotification";
import { useConfirmExcretionSupportDialog } from "src/modules/dialog/ConfirmExcretionSupportDialog";
import { ExcretionInputError, ExcretionInputErrorType, toExcretionInputErrorMessage } from "src/types/excretionInput";
import { AmountType } from "src/types/amount";
import { FecesFirmnessType } from "src/types/fecesFirmness";
import { ExcretionSupportedPlace, ExcretionSupportedPlaceTypes } from "src/types/excretionSupportedPlaceType";
import { Constants } from "src/constants/commonConstants";
import { useTranslation } from "react-i18next";
import { FetchError } from "src/types/FetchError";

export const Status = {
  Initializing: "initializing",
  Idling: "idling",
  Processing: "processing",
  Finished: "finished",
} as const;
type StatusType = typeof Status[keyof typeof Status];

type InputValues = {
  excretionSupportType: ExcretionSupportType | undefined; // 対応内容(必須)
  isLeaked: boolean | undefined; // 漏れ(必須)
  urineAmount: AmountType | undefined; // 尿量(任意) 対応内容が 尿 or 尿・便 のときのみ入力可
  fecesAmount: AmountType | undefined; // 便量(任意) 対応内容が 便 or 尿・便 のときのみ入力可
  fecesFirmness: FecesFirmnessType | undefined; // 便の性状(任意) 対応内容が 便 or 尿・便 のときのみ入力可
  supportedPlace: ExcretionSupportedPlace; // 対応方法(必須) 対応内容が 尿 or 便 or 尿・便 のときのみ入力可
  karte: string; // メモ
  supportStaffId: number | undefined; // 対応スタッフID
  supportedAt: Date; // 対応日時
  createdAt?: string; // 編集時のみここに元となった対応登録情報の created_at を入れる
};

/**
 * [SP] 対応内容の登録と編集の共通 states やロジック
 */
export const useCareSubjectExcretionSupport = (
  staffs: ModelsStaff[] | undefined,
  defaultValues: {
    status: StatusType;
    excretionSupportType?: ExcretionSupportType;
    supportedAt?: Date;
    supportStaffId: number | undefined;
  }
) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  // 分が切り替わったときのみ更新される点に注意
  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime((current) => {
        const now = new Date();
        // 分まで同じなら更新しない
        const prev = format(current, "yyyy/MM/dd HH:mm");
        const next = format(now, "yyyy/MM/dd HH:mm");
        return prev === next ? current : now;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const [status, setStatus] = useState<StatusType>(defaultValues.status);

  const { showNotification } = useSpNotification();
  const confirmDialogState = useConfirmExcretionSupportDialog();

  const [error, setError] = useState<FetchError | undefined>();

  const [inputErrors, setInputErrors] = useState<ExcretionInputErrorType[]>([]);

  const [inputValues, setInputValues] = useState<InputValues>({
    excretionSupportType: defaultValues.excretionSupportType ?? undefined,
    isLeaked: undefined,
    urineAmount: undefined,
    fecesAmount: undefined,
    fecesFirmness: undefined,
    supportedPlace: ExcretionSupportedPlaceTypes.DiaperChange,
    karte: "",
    supportStaffId: defaultValues.supportStaffId,
    supportedAt: defaultValues.supportedAt ?? set(new Date(), { seconds: 0, milliseconds: 0 }),
  });
  const { excretionSupportType, isLeaked, karte, supportStaffId } = inputValues;

  const onChangeInputValues = useCallback((next: Partial<InputValues>) => {
    setInputValues((state) => ({ ...state, ...next }));
  }, []);

  // 対応内容変更時
  const onChangeExcretionSupportType = useCallback((next: ExcretionSupportType) => {
    setInputValues((state) => {
      const nextState = { ...state };

      // 尿 or 尿便対応のときにしか尿量は入力できないのでそれ以外のときは尿量を未指定に戻す
      if (!isUrineCategory(next)) {
        nextState.urineAmount = undefined;
      }

      // 便 or 尿便対応のときにしか便量は入力できないのでそれ以外のときは便量と便の性状を未指定に戻す
      if (!isFecesCategory(next)) {
        nextState.fecesAmount = undefined;
        nextState.fecesFirmness = undefined;
      }

      // 排泄なしの際は強制的に「漏れなし」「対応方法なし」とする
      if (next === ExcretionSupportTypes.None) {
        nextState.isLeaked = false;
      }

      nextState.excretionSupportType = next;
      return nextState;
    });
  }, []);

  // 漏れ変更時
  const onChangeIsLeaked = useCallback((next: boolean) => {
    setInputValues((state) => ({ ...state, isLeaked: next }));
  }, []);

  // 尿量変更時
  const onChangeUrineAmount = useCallback((next: AmountType) => {
    setInputValues((state) => ({ ...state, urineAmount: next }));
  }, []);

  // 便料変更時
  const onChangeFecesAmount = useCallback((next: AmountType) => {
    setInputValues((state) => ({ ...state, fecesAmount: next }));
  }, []);

  // 便の性状変更時
  const onChangeFecesFirmness = useCallback((next: FecesFirmnessType) => {
    setInputValues((state) => ({ ...state, fecesFirmness: next }));
  }, []);

  // 対応方法変更時
  const onChangeSupportedPlace = useCallback((next: ExcretionSupportedPlace) => {
    setInputValues((state) => ({ ...state, supportedPlace: next }));
  }, []);

  // メモ変更時
  const onChangeKarte = useCallback((next: string) => {
    setInputValues((state) => ({ ...state, karte: next }));
  }, []);

  // 日付変更時
  const onChangeDate = useCallback(
    (next: Date) => {
      setInputValues((state) => {
        const nextSupportedAt = set(state.supportedAt, {
          year: next.getFullYear(),
          month: next.getMonth(),
          date: next.getDate(),
        });
        return { ...state, supportedAt: nextSupportedAt > currentTime ? currentTime : nextSupportedAt };
      });
    },
    [currentTime]
  );

  // 時刻変更時
  const onChangeTime = useCallback(
    (next: Date) => {
      setInputValues((state) => {
        const nextSupportedAt = set(state.supportedAt, {
          hours: next.getHours(),
          minutes: next.getMinutes(),
        });
        return { ...state, supportedAt: nextSupportedAt > currentTime ? currentTime : nextSupportedAt };
      });
    },
    [currentTime]
  );

  // 対応スタッフID変更時
  const onChangeSupportStaffId = useCallback((next: number) => {
    setInputValues((state) => ({ ...state, supportStaffId: next }));
  }, []);

  const supportStaff = useMemo(() => staffs?.find((staff) => staff.id === supportStaffId), [staffs, supportStaffId]);

  const [disabled, setDisabled] = useState(false);

  const onConfirm = useCallback(() => {
    // 入力値チェック
    const inputErrors: ExcretionInputErrorType[] = [];
    if (excretionSupportType == null) {
      inputErrors.push(ExcretionInputError.UnselectedExcretion);
    }
    if (isLeaked == null) {
      inputErrors.push(ExcretionInputError.UnselectedLeaked);
    }

    // メモは200文字以内
    if (karte.length > Constants.MAX_MEMO_LENGTH) {
      inputErrors.push(ExcretionInputError.KarteTooLong);
    }

    setInputErrors(inputErrors);
    if (inputErrors.length > 0) {
      // あんましたくさん出すのもあれなので1つだけ出す
      showNotification(toExcretionInputErrorMessage(inputErrors[0], t), "alert");
      return;
    }

    confirmDialogState.showDialog();
  }, [showNotification, confirmDialogState, excretionSupportType, isLeaked, karte, t]);

  // キャンセル
  const onCancel = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const privateStates = useMemo(
    () => ({
      navigate,
      setDisabled,
      onChangeStatus: setStatus,
      confirmDialogState,
      showNotification,
      onChangeInputValues,
      onError: setError,
    }),
    [navigate, setDisabled, confirmDialogState, showNotification, onChangeInputValues]
  );

  const publicStates = useMemo(
    () => ({
      error,
      inputErrors,
      inputValues,
      status,
      onChangeExcretionSupportType,
      onChangeIsLeaked,
      onChangeUrineAmount,
      onChangeFecesAmount,
      onChangeFecesFirmness,
      onChangeSupportedPlace,
      onChangeKarte,
      onChangeSupportStaffId,
      supportStaff,
      onChangeDate,
      onChangeTime,
      onConfirm,
      onCancel,
      disabled,
      openConfirmDialog: confirmDialogState.open,
      onClickCancelOnDialog: confirmDialogState.hideDialog,
      currentTime,
    }),
    [
      error,
      inputErrors,
      inputValues,
      status,
      onChangeExcretionSupportType,
      onChangeIsLeaked,
      onChangeUrineAmount,
      onChangeFecesAmount,
      onChangeFecesFirmness,
      onChangeSupportedPlace,
      onChangeKarte,
      onChangeSupportStaffId,
      supportStaff,
      onChangeDate,
      onChangeTime,
      onConfirm,
      onCancel,
      disabled,
      confirmDialogState,
      currentTime,
    ]
  );

  return {
    privateStates, // コンポーネントに触らせない(触るのは hooks まで)
    publicStates, // コンポーネントに触らせる
  };
};
