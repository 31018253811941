import { Box } from "@mui/material";
import { useCallback } from "react";
import { ToggleButtons } from "src/modules/ToggleButtons";
import { faUsers, faBell } from "@fortawesome/free-solid-svg-icons";
import { FilterGroupsPanel } from "src/modules/FilterGroupsPanel";
import { useTranslation } from "react-i18next";

export type FilterValue = {
  group: number[];
  alertOnly: boolean;
};

type Props = {
  filter: FilterValue;
  setGroupValues: (values: number[]) => void;
  onFilterChange: (value: FilterValue) => void;
};

export const Toolbar = (props: Props) => {
  const { t } = useTranslation();
  const onChange = useCallback(
    (value: Partial<FilterValue>) => {
      props.onFilterChange({
        ...props.filter,
        ...value,
      });
    },
    [props]
  );

  return (
    <Box
      display="flex"
      height="80px"
      sx={{
        alignItems: "center",
        borderBottom: "1px solid #e6e6e6",
        pl: 3,
      }}
    >
      <FilterGroupsPanel currentValues={props.filter.group} setValues={props.setGroupValues} />
      <ToggleButtons
        items={[
          { icon: faUsers, value: "all", label: t("care_subjects.toolbar.all") },
          { icon: faBell, value: "alert", label: t("care_subjects.toolbar.alert_and_error") },
        ]}
        currentValue={props.filter.alertOnly ? "alert" : "all"}
        onChange={(value) => {
          if (value != null) {
            onChange({ alertOnly: value === "alert" });
          }
        }}
        sx={{ ml: "auto", mr: "15px" }}
      />
    </Box>
  );
};
