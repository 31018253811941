import { Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText, Box } from "@mui/material";
import { AcceptButton, CancelButton } from "../parts/CommonButton";
import { useTranslation } from "react-i18next";

export type ConfirmDialogProps = {
  open: boolean;
  onAccept: () => void;
  acceptButtonLabel: string;
  onCancel: () => void;
  title?: string;
  message?: string;
};

export const ConfirmDialog = ({ open, onAccept, acceptButtonLabel, onCancel, title, message }: ConfirmDialogProps) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      onClose={onCancel}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "80%",
            height: "300px",
          },
        },
      }}
    >
      <DialogTitle
        sx={{
          mt: 3,
          mb: 2,
          fontSize: "1.75rem",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{
            fontSize: "1.25rem",
          }}
        >
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ mb: 3 }}>
        <Box display={"flex"} justifyContent={"center"} width={"100%"} gap={"100px"}>
          <CancelButton onClick={onCancel}>{t("common.button.cancel")}</CancelButton>
          <AcceptButton onClick={onAccept}>{acceptButtonLabel}</AcceptButton>
        </Box>
      </DialogActions>
    </Dialog>
  );
};
