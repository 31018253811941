import { Box } from "@mui/material";
import { Amount, AmountType } from "src/types/amount";
import { RadioButton } from "./RadioButton";
import { useTranslation } from "react-i18next";

export const AmountSelect = ({
  disabled,
  amount,
  onChange,
}: {
  disabled: boolean;
  amount: AmountType | undefined;
  onChange: (next: AmountType) => void;
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "8px" }}>
      <RadioButton value={Amount.Trace} current={amount} disabled={disabled} onClick={onChange}>
        {t("common.excretion.amount_trace")}
      </RadioButton>
      <RadioButton value={Amount.Little} current={amount} disabled={disabled} onClick={onChange}>
        {t("common.excretion.amount_little")}
      </RadioButton>
      <RadioButton value={Amount.Midium} current={amount} disabled={disabled} onClick={onChange}>
        {t("common.excretion.amount_midium")}
      </RadioButton>
      <RadioButton value={Amount.Much} current={amount} disabled={disabled} onClick={onChange}>
        {t("common.excretion.amount_much")}
      </RadioButton>
    </Box>
  );
};
