import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Backdrop, Box, Stack, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVolumeOff } from "@fortawesome/free-solid-svg-icons";
import { NotificationConstants } from "src/constants/notificationSound";
import { useNotificationSounds } from "src/utils/soundUtil";

export const NotificationSoundPanel = ({
  currentSound,
  setCurrentSound,
}: {
  currentSound: string;
  setCurrentSound: (value: string) => void;
}) => {
  const { t } = useTranslation();
  const [showSelectNotification, setShowSelectNotification] = useState(false);
  const { stopAllSounds, playSoundById } = useNotificationSounds();

  return (
    <Box position="relative">
      <Box
        border="1px solid #e6e6e6"
        px={2}
        height="40px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setShowSelectNotification(!showSelectNotification);
        }}
        sx={{
          cursor: "pointer",
        }}
      >
        <Typography align="left" pr={1}>
          <FontAwesomeIcon icon={faVolumeOff} color="#606060" />
        </Typography>
        <Typography align="center" fontSize="16px" sx={{ userSelect: "none", lineHeight: 1.1 }}>
          {t("module.notification_sound_panel.notification_sound")}
        </Typography>
      </Box>

      {/* 通知音設定パネルの表示 */}
      {showSelectNotification && (
        <SelectNotificationItems
          currentSound={currentSound}
          setCurrentSound={setCurrentSound}
          stopAllSounds={stopAllSounds}
          playSoundById={playSoundById}
        />
      )}
      <Backdrop
        sx={{ zIndex: 1 }}
        open={showSelectNotification}
        onClick={() => {
          setShowSelectNotification(false);
          stopAllSounds();
        }}
      />
    </Box>
  );
};

const SelectNotificationItems = ({
  currentSound,
  setCurrentSound,
  stopAllSounds,
  playSoundById,
}: {
  currentSound: string;
  setCurrentSound: (value: string) => void;
  stopAllSounds: () => void;
  playSoundById: (id: string) => void;
}) => {
  const { t } = useTranslation();

  // 通知音の選択肢
  const notificationItems = Object.values(NotificationConstants).map((soundId) => ({
    id: soundId,
    label: t(`module.notification_sound_panel.${soundId}`),
    isSelected: currentSound === soundId,
  }));

  return (
    <Box
      border="1px solid #e6e6e6"
      position="absolute"
      top="45px"
      left="0px"
      p="20px"
      width="580px"
      sx={{ backgroundColor: "#FFFFFF" }}
      display="flex"
      flexWrap="wrap"
      zIndex={2}
    >
      <Stack width="100%" spacing={5}>
        <Stack direction="row" justifyContent="space-around" spacing={2}>
          {notificationItems.map((item) => (
            <NotificationItem
              key={item.id}
              id={item.id}
              label={item.label}
              isSelect={item.isSelected}
              setCurrentSound={setCurrentSound}
              stopAllSounds={stopAllSounds}
              playSoundById={playSoundById}
            />
          ))}
        </Stack>
      </Stack>
    </Box>
  );
};

const NotificationItem = ({
  id,
  label,
  isSelect,
  setCurrentSound,
  stopAllSounds,
  playSoundById,
}: {
  id: string;
  label: string;
  isSelect: boolean;
  setCurrentSound: (value: string) => void;
  stopAllSounds: () => void;
  playSoundById: (id: string) => void;
}) => {
  return (
    <Box
      role="button"
      width="100%"
      sx={{
        padding: "8px 16px",
        borderRadius: "20px",
        backgroundColor: isSelect ? "#f29500" : "#e8e8e8",
        color: isSelect ? "#fff" : "#000",
        cursor: "pointer",
        textAlign: "center",
        fontWeight: "bold",
        transition: "all 0.3s",
      }}
      onClick={() => {
        stopAllSounds();
        setCurrentSound(id);
        playSoundById(id);
      }}
    >
      <Typography>{label}</Typography>
    </Box>
  );
};
