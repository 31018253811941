import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import { styled } from "@mui/material/styles";
import { Grid } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

type RowItem = {
  nameI18nKey: string;
  descriptionI18nKey: string;
  fileName: string;
};

//'/help/doc'

// 実データ
const rows: RowItem[] = [
  {
    nameI18nKey: "help.other_help.general_web_help_title",
    descriptionI18nKey: "help.other_help.general_web_help_description",
    fileName: "HP2_general_web_help.pdf",
  },
  {
    nameI18nKey: "help.other_help.general_sp_help_title",
    descriptionI18nKey: "help.other_help.general_sp_help_description",
    fileName: "HP2_general_sp_help.pdf",
  },
  {
    nameI18nKey: "help.other_help.manager_web_help_title",
    descriptionI18nKey: "help.other_help.manager_web_help_description",
    fileName: "HP2_manager_web_help.pdf",
  },
  {
    nameI18nKey: "help.other_help.other_function_help_title",
    descriptionI18nKey: "help.other_help.other_function_help_description",
    fileName: "HP2_other_function_help.pdf",
  },
  {
    nameI18nKey: "help.other_help.device_setup_manual_title",
    descriptionI18nKey: "help.other_help.device_setup_manual_description",
    fileName: "HP2_device_setup_manual.pdf",
  },
];

export const OtherHelp = () => {
  const { t, i18n } = useTranslation();

  const basePath = useMemo(() => `/help/${i18n.language}/doc`, [i18n.language]);

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#feb",
      fontSize: 28,
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      backgroundColor: "#fff",
      fontSize: 28,
    },
  }));

  const URLLinkCell = (row: RowItem) => {
    if (row.fileName !== "") {
      return (
        <Link target="_blank" href={`${basePath}/${row.fileName}`}>
          {t(row.nameI18nKey)}
        </Link>
      );
    } else {
      return <p>{t(row.nameI18nKey)}</p>;
    }
  };

  return (
    <Grid container>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 400, fontWeight: "medium" }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("help.other_help.manuals")}</StyledTableCell>
              <StyledTableCell align="left">{t("help.other_help.description")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.nameI18nKey} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                <StyledTableCell style={{ width: "40%" }} component="th" scope="row">
                  {URLLinkCell(row)}
                </StyledTableCell>
                <StyledTableCell align="left">{t(row.descriptionI18nKey)}</StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid
        item
        xs={12}
        sx={{
          whiteSpace: "nowrap",
          overflowX: "auto",
          my: 2,
          bgcolor: (theme) => (theme.palette.mode === "dark" ? "#101010" : "grey.100"),
          color: (theme) => (theme.palette.mode === "dark" ? "grey.300" : "grey.800"),
          border: "1px solid",
          borderColor: (theme) => (theme.palette.mode === "dark" ? "grey.800" : "grey.300"),
          borderRadius: 2,
          fontSize: "1.5rem",
          fontWeight: "700",
        }}
      >
        <Grid item xs={12} sx={{ bgcolor: "orange", textAlign: "center", letterSpacing: "1rem" }}>
          {t("help.other_help.contact_us")}
        </Grid>
        <Grid container sx={{ justifyContent: "center" }}>
          <Grid item p={0.5}>
            <EmailIcon sx={{ fontSize: "2rem" }} />
          </Grid>
          <Grid item>cs@aba-lab.com</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
