import {
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableCellProps,
  TableRow,
  TableRowProps,
  Typography,
} from "@mui/material";
import { ReactNode } from "react";

type Props = {
  children: ReactNode | ReactNode[];
};

export function SpDataTable({ children }: Props) {
  return (
    <>
      <Box px={2} py={2} sx={{ border: "1px solid #D9D9D9", borderRadius: "0.5rem" }}>
        <Table>
          <TableBody>{children}</TableBody>
        </Table>
      </Box>
    </>
  );
}

type RowProps = {
  title: string | ReactNode; // タイトル
  value: string | ReactNode | undefined | null; // 値
  isNone?: boolean; // 「未指定」「未設定」などの場合は true にすると文字の色が薄くなります
};

SpDataTable.Row = ({ title, value, isNone }: RowProps) => {
  return (
    <Tr>
      <DataTableTitle value={title} />
      <DataTableValue value={value} isNone={isNone} />
    </Tr>
  );
};

const DataTableTitle = ({ value }: { value: string | ReactNode }) => {
  return (
    <>
      <TdLabel>
        <Typography fontWeight={"bold"}>{value}</Typography>
      </TdLabel>
      <TdColon>
        <Typography fontWeight={"bold"}>:</Typography>
      </TdColon>
    </>
  );
};

const DataTableValue = ({ value, isNone }: { value: string | ReactNode | undefined | null; isNone?: boolean }) => {
  return (
    <TdValue sx={{ color: isNone === true ? "#999" : "#000" }}>
      <Typography>{value ?? ""}</Typography>
    </TdValue>
  );
};

const Tr = styled(TableRow)<TableRowProps>(() => ({
  border: 0,
}));

const TdLabel = styled(TableCell)<TableCellProps>(() => ({
  border: 0,
  whiteSpace: "nowrap",
  width: 0,
  padding: 0,
  verticalAlign: "top",
  textAlign: "right",
  paddingTop: "0.25rem",
  paddingBottom: "0.25rem",
}));

const TdColon = styled(TableCell)<TableCellProps>(() => ({
  border: 0,
  padding: 0,
  width: 0,
  verticalAlign: "top",
  paddingLeft: "0.25rem",
  paddingTop: "0.25rem",
  paddingBottom: "0.25rem",
}));

const TdValue = styled(TableCell)<TableCellProps>(() => ({
  border: 0,
  padding: 0,
  paddingLeft: "1rem",
  paddingTop: "0.25rem",
  paddingBottom: "0.25rem",
}));
