import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useLocale } from "src/utils/localeMap";

type Props = {
  disabled?: boolean;
  maxDate?: Date;
  date: Date;
  onChangeDate: (next: Date, isValid: boolean) => void;
};

export const InputDatePicker = (props: Props) => {
  const { t } = useTranslation();
  const locale = useLocale();
  const { disabled, maxDate, date, onChangeDate } = props;

  const onChange = useCallback(
    (next: Date | null) => {
      const isInvalid = next == null || isNaN(next.getTime());
      onChangeDate(isInvalid ? date : next, !isInvalid);
    },
    [date, onChangeDate]
  );

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={locale}
      localeText={{
        datePickerToolbarTitle: t("module.date.select_date"),
        cancelButtonLabel: t("common.button.cancel"),
      }}
    >
      <DatePicker sx={{ width: "100%" }} disabled={disabled} maxDate={maxDate} value={date} onChange={onChange} />
    </LocalizationProvider>
  );
};
