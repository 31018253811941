import {
  Box,
  FormControlLabel,
  FormControlLabelProps,
  MenuItem,
  Radio,
  RadioGroup,
  RadioProps,
  Select,
  SelectChangeEvent,
  styled,
  Typography,
} from "@mui/material";
import { set } from "date-fns";
import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RequiredTag } from "src/modules/dialog/ExcretionModal/parts/RequiredTag";
// import { KarteTextare } from "src/modules/dialog/parts/KarteTextarea";
import { AmountSelect } from "src/modules/parts/AmountSelect";
import { FecesFirmnessSelect } from "src/modules/parts/FecesFirmnessSelect";
import { InputDatePicker } from "src/modules/parts/InputDatePicker";
import { LeakedLabel, NotLeakedLabel } from "src/modules/parts/LeakLabel";
import { ModelsStaff } from "src/store/helppadApi";
import {
  ExcretionSupportType,
  ExcretionSupportTypes,
  isFecesCategory,
  isUrineCategory,
} from "src/types/ExcretionSupportType";
import { AmountType } from "src/types/amount";
import {
  ExcretionSupportedPlace,
  ExcretionSupportedPlaceTypes,
  toExcretionSupportedPlaceTypeName,
} from "src/types/excretionSupportedPlaceType";
import { FecesFirmnessType } from "src/types/fecesFirmness";

type Props = {
  currentTime: Date;
  disabled: boolean;
  excretionSupportType: ExcretionSupportType | undefined; // 排泄対応種別
  onChangeExcretionSupportType: (next: ExcretionSupportType) => void;
  isLeaked: boolean | undefined;
  onChangeIsLeaked: (next: boolean) => void; // 漏れ変更時の振る舞い
  urineAmount: AmountType | undefined; // 尿量
  onChangeUrineAmount: (next: AmountType) => void; // 尿量変更時の振る舞い
  fecesAmount: AmountType | undefined; // 便量
  onChangeFecesAmount: (next: AmountType) => void; // 便量変更時の振る舞い
  fecesFirmness: FecesFirmnessType | undefined; // 便の性状
  onChangeFecesFirmness: (next: FecesFirmnessType) => void; // 便の性状変更時の振る舞い
  supportedPlace: ExcretionSupportedPlace; // 対応方法
  onChangeSupportedPlace: (next: ExcretionSupportedPlace) => void; // 対応方法変更時の振る舞い
  // karte: string; // メモ
  // onChangeKarte: (next: string) => void; // メモ変更時の振る舞い
  supportedAt: Date; // 対応日時
  onChangeDate: (next: Date) => void; // 日付変更時の振る舞い
  onChangeTime: (next: Date) => void; // 日時変更時の振る舞い
  supportStaffId: number | undefined; // 対応したスタッフID
  staffs: ModelsStaff[] | undefined; // スタッフ一覧
  onChangeSupportStaffId: (next: number) => void; // 対応したスタッフ変更時
};

/**
 * [SP用]
 * 排泄対応入力フォーム
 */
export const SpExcretionSupportInputForm = ({
  currentTime,
  disabled,
  excretionSupportType,
  onChangeExcretionSupportType,
  isLeaked,
  onChangeIsLeaked,
  urineAmount,
  onChangeUrineAmount,
  fecesAmount,
  onChangeFecesAmount,
  fecesFirmness,
  onChangeFecesFirmness,
  supportedPlace,
  onChangeSupportedPlace,
  supportedAt,
  onChangeDate,
  onChangeTime,
  staffs,
  supportStaffId,
  onChangeSupportStaffId,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Label required>{t("module.excretion_support.select_support_type")}</Label>
      <SupportTypeSelect disabled={disabled} type={excretionSupportType} onChange={onChangeExcretionSupportType} />
      <Box mb={3} />
      {/* 漏れ選択 */}
      <Label required>{t("module.excretion_support.select_leaked")}</Label>
      <LeakedSelect
        disabled={excretionSupportType === ExcretionSupportTypes.None || disabled}
        isLeaked={isLeaked}
        onChange={onChangeIsLeaked}
      />
      <Separator />
      <Accordion expanded={isUrineCategory(excretionSupportType)}>
        {/* 尿量選択 */}
        <Label disabled={!isUrineCategory(excretionSupportType)}>
          {t("module.excretion_support.select_urine_amount")}
        </Label>
        <AmountSelect
          disabled={disabled || !isUrineCategory(excretionSupportType)}
          amount={urineAmount}
          onChange={onChangeUrineAmount}
        />
        <Separator />
      </Accordion>
      <Accordion expanded={isFecesCategory(excretionSupportType)}>
        {/* 便量選択 */}
        <Label disabled={!isFecesCategory(excretionSupportType)}>
          {t("module.excretion_support.select_feces_amount")}
        </Label>
        <AmountSelect
          disabled={disabled || !isFecesCategory(excretionSupportType)}
          amount={fecesAmount}
          onChange={onChangeFecesAmount}
        />
        <Separator />
        {/* 便の性状選択 */}
        <Label disabled={!isFecesCategory(excretionSupportType)}>
          {t("module.excretion_support.select_feces_firmness")}
        </Label>
        <FecesFirmnessSelect
          disabled={disabled || !isFecesCategory(excretionSupportType)}
          fecesFirmness={fecesFirmness}
          onChange={onChangeFecesFirmness}
        />
        <Separator />
      </Accordion>
      {/* 対応方法の選択 */}
      <Label required>{t("module.excretion_support.select_support_method")}</Label>
      <SupportedPlaceSelect disabled={disabled} current={supportedPlace} onChange={onChangeSupportedPlace} />
      <Separator />
      {/* メモ */}
      {/* メモ関連の要件がまとまるまでコメントアウト */}
      {/*  <Label>{t("module.excretion_support.input_memo")}</Label>
      <KarteTextare disabled={disabled} value={karte} onChange={onChangeKarte} row={3} />
      <Separator /> */}
      {/* 対応日時選択 */}
      <Label required>{t("module.excretion_support.input_support_date")}</Label>
      <Box display={"flex"} gap={"16px"}>
        <Box flex={1}>
          <InputDatePicker disabled={disabled} maxDate={currentTime} date={supportedAt} onChangeDate={onChangeDate} />
        </Box>
        <Box flex={1}>
          <InputTimePicker disabled={disabled} maxTime={currentTime} date={supportedAt} onChangeDate={onChangeTime} />
        </Box>
      </Box>
      <Separator />
      {/* 対応したスタッフ選択 */}
      <Label>{t("module.excretion_support.input_support_input_person")}</Label>
      <SupportStaffSelect
        disabled={disabled}
        value={supportStaffId}
        staffs={staffs}
        onChange={onChangeSupportStaffId}
      />
    </>
  );
};

const Separator = () => <Box mb={2} />;

const Label = ({
  children,
  disabled,
  required,
}: {
  children: ReactNode | ReactNode[];
  disabled?: boolean;
  required?: boolean;
}) => {
  return (
    <Box display={"flex"} mb={0.5} alignItems={"center"}>
      <Typography fontSize={"14px"} fontWeight={700} sx={{ opacity: disabled ? 0.3 : 1 }}>
        {children}
      </Typography>
      {required === true && <RequiredTag />}
    </Box>
  );
};

/**
 * 対応内容選択
 */
const SupportTypeSelect = ({
  disabled,
  type,
  onChange,
}: {
  disabled: boolean;
  type: ExcretionSupportType | undefined;
  onChange: (next: ExcretionSupportType) => void;
}) => {
  const { t } = useTranslation();

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(parseInt(event.target.value) as ExcretionSupportType);
    },
    [onChange]
  );

  return (
    <Box width={"100%"} boxSizing={"border-box"}>
      <RadioGroup sx={{ boxSizing: "border-box", width: "100%" }} onChange={handleChange}>
        <ExcretionSupportTypeRadioButton
          disabled={disabled}
          label={t("common.excretion.urine_support")}
          current={type}
          value={ExcretionSupportTypes.Urine}
        />
        <ExcretionSupportTypeRadioButton
          disabled={disabled}
          label={t("common.excretion.feces_support")}
          current={type}
          value={ExcretionSupportTypes.Feces}
        />
        <ExcretionSupportTypeRadioButton
          disabled={disabled}
          label={t("common.excretion.urine_and_feces_support")}
          current={type}
          value={ExcretionSupportTypes.UrineAndFeces}
        />
        <ExcretionSupportTypeRadioButton
          disabled={disabled}
          label={t("common.excretion.excretion_none")}
          current={type}
          value={ExcretionSupportTypes.None}
        />
      </RadioGroup>
    </Box>
  );
};

const ExcretionSupportTypeRadioButton = (props: {
  disabled: boolean;
  label: string;
  current: ExcretionSupportType | undefined;
  value: ExcretionSupportType;
}) => {
  return (
    <SupportRadioLabel
      disabled={props.disabled}
      checked={props.current === props.value}
      value={props.value}
      control={<RadioControl />}
      label={props.label}
    />
  );
};

const LeakedRadioButton = (props: {
  disabled: boolean;
  label: ReactNode;
  current: boolean | undefined;
  value: boolean;
}) => {
  return (
    <LeakedRadioLabel
      disabled={props.disabled}
      checked={props.current === props.value}
      value={props.value}
      control={<RadioControl />}
      label={props.label}
    />
  );
};

const SupportedPlaceButton = (props: {
  disabled: boolean;
  current: ExcretionSupportedPlace;
  value: ExcretionSupportedPlace;
}) => {
  const { t } = useTranslation();
  return (
    <SupportedPlaceRadioLabel
      disabled={props.disabled}
      checked={props.current === props.value}
      value={props.value}
      control={<RadioControl />}
      label={toExcretionSupportedPlaceTypeName(props.value, t)}
    />
  );
};

const LeakedSelect = ({
  disabled,
  isLeaked,
  onChange,
}: {
  disabled: boolean;
  isLeaked: undefined | boolean;
  onChange: (next: boolean) => void;
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(event.target.value === "true");
    },
    [onChange]
  );

  return (
    <Box width={"100%"} boxSizing={"border-box"}>
      <RadioGroup row sx={{ boxSizing: "border-box", width: "100%" }} onChange={handleChange}>
        <LeakedRadioButton
          disabled={disabled}
          label={<NotLeakedLabel disabled={disabled} />}
          current={isLeaked}
          value={false}
        />
        <LeakedRadioButton
          disabled={disabled}
          label={<LeakedLabel disabled={disabled} />}
          current={isLeaked}
          value={true}
        />
      </RadioGroup>
    </Box>
  );
};

const SupportedPlaceSelect = ({
  disabled,
  current,
  onChange,
}: {
  disabled: boolean;
  current: ExcretionSupportedPlace;
  onChange: (next: ExcretionSupportedPlace) => void;
}) => {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      onChange(parseInt(event.currentTarget.value) as ExcretionSupportedPlace);
    },
    [onChange]
  );

  return (
    <Box width={"100%"} boxSizing={"border-box"}>
      <RadioGroup row sx={{ boxSizing: "border-box", width: "100%" }} onChange={handleChange}>
        <SupportedPlaceButton disabled={disabled} current={current} value={ExcretionSupportedPlaceTypes.DiaperChange} />
        <SupportedPlaceButton
          disabled={disabled}
          current={current}
          value={ExcretionSupportedPlaceTypes.ToiletAssistance}
        />
      </RadioGroup>
    </Box>
  );
};

const RadioLabel = styled(FormControlLabel)<FormControlLabelProps>((props) => ({
  boxSizing: "border-box",
  margin: 0,
  minHeight: "48px",
  background: props.checked ? "rgba(242, 149, 0, 0.1)" : "#FFFFFF",
}));

const SupportRadioLabel = styled(RadioLabel)<FormControlLabelProps>(() => ({
  paddingLeft: "32px",
}));

const LeakedRadioLabel = styled(RadioLabel)<FormControlLabelProps>(() => ({
  flex: 1,
  display: "flex",
  justifyContent: "center",
  paddingRight: "12px",
}));

const SupportedPlaceRadioLabel = LeakedRadioLabel;

const RadioControl = styled(Radio)<RadioProps>(() => ({
  "&.Mui-checked": {
    color: "#F29500",
  },
}));

/**
 * 対応スタッフ選択
 */
const SupportStaffSelect = (props: {
  disabled: boolean;
  value: number | undefined;
  staffs: ModelsStaff[] | undefined;
  onChange: (next: number) => void;
}) => {
  const { onChange } = props;
  const handleChange = useCallback(
    (event: SelectChangeEvent<number>) => {
      onChange(event.target.value as number);
    },
    [onChange]
  );
  if (props.staffs == null) {
    return null;
  }
  return (
    <Select sx={{ width: "100%" }} disabled={props.disabled} value={props.value} onChange={handleChange}>
      {props.staffs.map((staff) => (
        <MenuItem key={staff.id} value={staff.id}>
          {staff.display_name}
        </MenuItem>
      ))}
    </Select>
  );
};

/**
 * 時刻選択
 */
const InputTimePicker = ({
  disabled,
  maxTime,
  date,
  onChangeDate,
}: {
  disabled: boolean;
  maxTime: Date;
  date: Date;
  onChangeDate: (next: Date) => void;
}) => {
  const { t } = useTranslation();

  const [maxHours, maxMinutes] = useMemo(() => {
    // maxTime の日付よりも過去の日付なら無制限
    if (
      set(date, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }) <
      set(maxTime, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 })
    ) {
      return [23, 59];
    }

    // 同日の場合は「時」が同一でなければ分は無制限
    // 時が同一の場合は現在の分までしか選択できない
    const maxDateHours = maxTime.getHours();
    return [maxDateHours, date.getHours() < maxDateHours ? 59 : maxTime.getMinutes()];
  }, [maxTime, date]);

  const handleChangeHours = useCallback(
    (event: SelectChangeEvent<number>) => {
      onChangeDate(set(date, { hours: Number(event.target.value) }));
    },
    [onChangeDate, date]
  );

  const handleChangeMinutes = useCallback(
    (event: SelectChangeEvent<number>) => {
      onChangeDate(set(date, { minutes: Number(event.target.value) }));
    },
    [onChangeDate, date]
  );

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
        {/* 時 選択 */}
        <Select
          sx={{ flex: 1, minWidth: "65px" }}
          disabled={disabled}
          value={date.getHours()}
          onChange={handleChangeHours}
        >
          {Array.from(new Array(maxHours + 1)).map((_, i) => (
            <MenuItem key={`h_${i}`} value={i}>
              {i}
            </MenuItem>
          ))}
        </Select>
        <Typography sx={{ mx: "0.5rem" }} component={"span"}>
          {t("common.time.hours")}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flex: 1, alignItems: "center" }}>
        {/* 分 選択 */}
        <Select
          sx={{ flex: 1, minWidth: "65px" }}
          disabled={disabled}
          value={date.getMinutes()}
          onChange={handleChangeMinutes}
        >
          {Array.from(new Array(maxMinutes + 1)).map((_, i) => (
            <MenuItem key={`h_${i}`} value={i}>
              {i}
            </MenuItem>
          ))}
        </Select>
        <Typography sx={{ mx: "0.5rem" }} component={"span"}>
          {t("common.time.minutes")}
        </Typography>
      </Box>
    </Box>
  );
};

const Accordion = ({ expanded, children }: { expanded: boolean; children: ReactNode }) => {
  const [contentHeight, setContentHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (ref.current) {
      setContentHeight(ref.current.scrollHeight);
    }
  }, [children]);

  const contentStyle = {
    maxHeight: expanded ? `${contentHeight}px` : "0",
    overflow: "hidden",
    transition: "max-height 0.3s ease",
  };

  return (
    <Box ref={ref} style={contentStyle}>
      {children}
    </Box>
  );
};
