import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { groupColor, UNASSIGNED_GROUP_ID } from "src/constants/groupColor";
import { ModelsGroup } from "src/store/helppadApi";

/**
 * [SP用]
 * 右上に表示するグループ名
 */
export const TopRightCornerGroupName = (props: { group: ModelsGroup | undefined }) => {
  const { t } = useTranslation();
  const { group } = props;
  const backgroundColor = useMemo(() => (group == null ? "#FFF" : groupColor(group.id as number)), [group]);

  return (
    <Box
      display="flex"
      p={1}
      alignItems="center"
      gap={1}
      sx={{
        borderTopRightRadius: "9.1px", // 10px のコーナーの内側に配置する想定なのでそれよりもコーナーは小さくなる
        backgroundColor,
        borderStyle: "solid",
        borderWidth: "0 0 1px 1px",
      }}
      borderColor={"#E6E6E6"}
    >
      <Typography fontSize={14}>
        {group?.id === UNASSIGNED_GROUP_ID ? t("common.group.unassigned") : group?.name ?? "---"}
      </Typography>
    </Box>
  );
};
