import { Grid, IconButton, Menu, MenuItem, Box, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Fragment, useState } from "react";
import { CommonButton } from "src/modules/parts/CommonButton";
import {
  initialState as initialGroupRecordState,
  GroupModifyModal,
  useModifyGroup,
} from "src/modules/dialog/GroupModifyModal";
import { GroupDeleteModal, useDeleteGroup } from "src/modules/dialog/GroupDeleteModal";
import { GroupCareSubjectModifyModal, useModifyGroupCareSubject } from "src/modules/dialog/GroupCareSubjectModifyModal";
import { Constants, Group } from "src/constants/commonConstants";
import { CommonPagination } from "src/modules/parts/CommonPagination";
import { ModelsGroup, ModelsGroupSubject, ModelsCareSubject } from "src/store/helppadApi";
import {
  useListGroupQuery,
  useListGroupSubjectQuery,
  useListCareSubjectQuery,
  useFindNotificationTargetsByStaffIdQuery,
} from "src/store/enhancedApi";
import { useGlobalProps } from "src/store/GlobalProps";
import { ListTable } from "../../modules/table/ListTable";
import { useTranslation } from "react-i18next";

export const GroupSetting = () => {
  const { t } = useTranslation();
  const groupSubjects = useListGroupSubjectQuery({ page: 1, size: 300 }, Constants.POLLING_INTERVAL).data;
  const careSubjects = useListCareSubjectQuery({ page: 1, size: 100 }, Constants.POLLING_INTERVAL).data?.items;

  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const groups = useListGroupQuery({ page: currentPage, size: pageSize }, Constants.POLLING_INTERVAL).data;
  const { staffId } = useGlobalProps();
  const notificationTargets = useFindNotificationTargetsByStaffIdQuery(
    { id: staffId! },
    Constants.POLLING_INTERVAL
  ).data;

  const {
    modifyGroupRecord,
    onModifyGroupRecord,
    saveModifyGroupRecord,
    showModifyDialog,
    hideModifyDialog,
    modifyRegistering,
    modifyRegistered,
  } = useModifyGroup();

  const {
    deleteGroupRecord,
    saveDeleteGroupRecord,
    showDeleteDialog,
    hideDeleteDialog,
    deleteRegistering,
    deleteRegistered,
  } = useDeleteGroup();

  const {
    modifyGroupSubjectInputModalOpen,
    modifyGroupSubjectRecord,
    saveModifyGroupSubjectRecord,
    showGroupSubjectModifyDialog,
    hideGroupSubjectModifyDialog,
    modifyGroupSubjectRegistering,
    modifyGroupSubjectRegistered,
  } = useModifyGroupCareSubject();

  return (
    <Grid sx={{ background: "#F9F9F9" }} display={"flex"} p={4} flexDirection={"column"} height={"100%"} width={"100%"}>
      <Grid pb={2} display={"flex"} justifyContent={"flex-end"}>
        <GroupModifyModal
          modifyGroupRecord={modifyGroupRecord}
          onModifyGroupRecord={onModifyGroupRecord}
          saveModifyGroupRecord={saveModifyGroupRecord}
          handleClose={() => hideModifyDialog()}
          registering={modifyRegistering}
          registered={modifyRegistered}
        />
        <GroupDeleteModal
          deleteGroupRecord={deleteGroupRecord}
          saveDeleteGroupRecord={saveDeleteGroupRecord}
          handleClose={() => hideDeleteDialog()}
          registering={deleteRegistering}
          registered={deleteRegistered}
        />
        <GroupCareSubjectModifyModal
          modifyInputModalOpen={modifyGroupSubjectInputModalOpen}
          modifyGroupSubjectRecord={modifyGroupSubjectRecord}
          saveModifyGroupSubjectRecord={saveModifyGroupSubjectRecord}
          handleClose={() => hideGroupSubjectModifyDialog()}
          registering={modifyGroupSubjectRegistering}
          registered={modifyGroupSubjectRegistered}
        />
        <CommonButton
          label={t("setting.group_setting.create_group")}
          onClick={() => {
            showModifyDialog(initialGroupRecordState);
          }}
        />
      </Grid>

      <ListTable>
        <ListTable.Head>
          <ListTable.Head.Cell width={"25%"}>{t("common.group.group_name")}</ListTable.Head.Cell>
          <ListTable.Head.Cell width={"15%"}>
            {t("setting.group_setting.setting_push_notification")}
          </ListTable.Head.Cell>
          <ListTable.Head.Cell flex={1}>{t("common.care_subject.care_subject_name")}</ListTable.Head.Cell>
        </ListTable.Head>
        <ListTable.Body>
          {groups?.items?.map((group: ModelsGroup) => {
            const matchingGroupSubjects = groupSubjects?.items?.filter(
              (groupSubject: ModelsGroupSubject) => group.id === groupSubject.group_id
            );
            const matchedCareSubjects: ModelsCareSubject[] =
              matchingGroupSubjects?.flatMap((groupSubject: ModelsGroupSubject) => {
                const matchedCareSubject = careSubjects?.find(
                  (careSubject: ModelsCareSubject) => careSubject.id === groupSubject.care_subject_id
                );
                return matchedCareSubject ? [matchedCareSubject] : [];
              }) || [];

            return (
              <ListTable.Body.Row key={group.id}>
                <ListTable.Body.Cell width={"25%"}>{group.name}</ListTable.Body.Cell>
                <ListTable.Body.Cell width={"15%"}>
                  {notificationTargets?.group_ids?.includes(group.id!)
                    ? t("setting.group_setting.enable")
                    : t("setting.group_setting.disable")}
                </ListTable.Body.Cell>
                <ListTable.Body.Cell flex={1}>
                  <Box>
                    {matchedCareSubjects?.map((careSubject: ModelsCareSubject) => (
                      <Typography key={careSubject.id}>{careSubject.name}</Typography>
                    ))}
                  </Box>
                </ListTable.Body.Cell>
                <ListTable.Body.Cell pr={2}>
                  <ActionMenu
                    group={{
                      id: group.id,
                      name: group.name!,
                      pushNotify: notificationTargets?.group_ids?.includes(group.id!) ? 1 : 0,
                    }}
                    showAddDialog={showGroupSubjectModifyDialog}
                    showModifyDialog={showModifyDialog}
                    showDeleteDialog={showDeleteDialog}
                  />
                </ListTable.Body.Cell>
              </ListTable.Body.Row>
            );
          })}
        </ListTable.Body>
      </ListTable>
      <CommonPagination
        currentPage={currentPage}
        totalCount={groups?.page_info?.total_pages}
        onChangePage={setCurrentPage}
      />
    </Grid>
  );
};

export const ActionMenu = ({
  group,
  showAddDialog,
  showModifyDialog,
  showDeleteDialog,
}: {
  group: Group;
  showAddDialog: any;
  showModifyDialog: any;
  showDeleteDialog: any;
}) => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAdd = () => {
    setAnchorEl(null);
    showAddDialog({
      id: group.id,
      name: group.name,
      pushNotify: group.pushNotify,
    });
  };

  const handleEdit = () => {
    setAnchorEl(null);
    showModifyDialog({
      id: group.id,
      name: group.name,
      pushNotify: group.pushNotify,
    });
  };

  const handleDelete = () => {
    setAnchorEl(null);
    showDeleteDialog({
      id: group.id,
      name: group.name,
      pushNotify: group.pushNotify,
    });
  };

  return (
    <Fragment>
      <IconButton
        aria-label="menu"
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleMenuClick}
        sx={{ padding: 0 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu id="menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem onClick={handleAdd}>{t("setting.group_setting.add_care_subject_to_group")}</MenuItem>
        <MenuItem onClick={handleEdit}>{t("setting.group_setting.edit_group")}</MenuItem>
        <MenuItem onClick={handleDelete}>{t("setting.group_setting.delete_group")}</MenuItem>
      </Menu>
    </Fragment>
  );
};
