import { Box, Typography } from "@mui/material";
import { ReactNode, useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHourglass2 } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { Constants } from "src/constants/commonConstants";
import { useListCareSubjectQuery, ModelsCareSubject, ModelsGroup } from "src/store/helppadApi";
import { UNASSIGNED_GROUP, UNASSIGNED_GROUP_ID } from "src/constants/groupColor";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { parseFirstAlertedAt, toTimeDiffString } from "src/utils/dateUtil";
import { useBooleanLocalStorage, useNumberArrayLocalStorage } from "src/utils/localStorage";
import { toCareSubjectPageLink } from "../CareSubject/toCareSubjectPageLink";
import { TopRightCornerGroupName } from "src/modules/sp/TopRightCornerGroupName";
import { SpPage } from "src/modules/sp/SpPage";
import { DeviceErrorIcon } from "src/modules/parts/DeviceErrorIcon";
import { DeviceErrorDialog, useDeviceErrorDialog } from "src/modules/dialog/DeviceErrorDialog";
import { useTranslation } from "react-i18next";

type NoticeView = {
  group: ModelsGroup;
  careSubjects: ModelsCareSubject[];
};

const getBackgroundForAlertType = (type: number | undefined) => {
  if (type === undefined) {
    return "#FFFFFF";
  }
  switch (type) {
    case 1:
      return "linear-gradient(90deg, #FFFA83 0%, rgba(255, 250, 131, 0.1) 100%)";
    case 2:
      return "linear-gradient(90deg, rgba(255, 121, 137, 0.8) 0%, rgba(255, 121, 137, 0.16) 100%)";
    case 3:
    default:
      return "#FFFFFF";
  }
};

const NoticeItem = (props: {
  careSubject: ModelsCareSubject;
  group: ModelsGroup;
  isNoticeTimeView: boolean;
  isAllView: boolean;
  onClickDeviceErrorIcon: (careSubject: ModelsCareSubject) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { careSubject, group, isNoticeTimeView, isAllView, onClickDeviceErrorIcon } = props;
  const { first_alerted_at } = careSubject;
  const details = careSubject.active_alert_log?.detail;

  const alertedAt = useMemo(() => parseFirstAlertedAt(first_alerted_at), [first_alerted_at]);

  const handleClickDeviceErrorIcon = useCallback(() => {
    onClickDeviceErrorIcon(careSubject);
  }, [careSubject, onClickDeviceErrorIcon]);

  if (!isAllView && details !== 1 && details !== 2 && !careSubject.device_error) return <></>;

  return (
    <>
      <Box
        width="100%"
        p={2}
        position="relative"
        sx={{
          borderRadius: "10px",
          border: "1px solid #E6E6E6",
          background: getBackgroundForAlertType(careSubject.active_alert_log?.detail),
          ":hover": {
            opacity: 0.5,
            cursor: "pointer",
          },
        }}
        onClick={() => {
          navigate(toCareSubjectPageLink(careSubject?.id, group?.id));
        }}
      >
        <Box
          position="absolute"
          sx={{
            top: 0,
            right: 0,
            zIndex: 1,
          }}
        >
          <TopRightCornerGroupName group={group} />
        </Box>
        <Box display="flex" flexDirection="column" alignItems="flex-start" gap={1}>
          <Typography
            fontSize="16px"
            fontWeight="bold"
            fontFamily="Noto Sans JP"
            color="#404040"
            sx={{
              borderTopLeftRadius: "10px",
            }}
          >
            {careSubject.room_name}
          </Typography>
          <Typography fontSize="24px" fontFamily="Noto Sans JP" color="#404040" mb={2}>
            {careSubject.name}
          </Typography>
          {careSubject.device_error ? (
            <Box position="absolute" top="50px" right="10px">
              <DeviceErrorIcon onClick={handleClickDeviceErrorIcon} />
            </Box>
          ) : null}
        </Box>
        <Box display="flex" alignItems="center" gap={1} alignSelf="stretch">
          <Box display="flex" alignItems="flex-start" gap={1}>
            <Box
              display="flex"
              py={1}
              px={1.5}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              borderRadius={1}
              sx={{
                backgroundColor: "#E7E8F1",
              }}
            >
              <Typography color="#0E1C73" fontFamily="Noto Sans JP" fontSize={16} lineHeight={1.5} fontWeight="bold">
                {`${t("common.excretion.urine")} ${
                  careSubject.active_alert_log ? careSubject.active_alert_log.urine_count : 0
                }`}
              </Typography>
            </Box>
            <Box
              display="flex"
              py={1}
              px={1.5}
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              borderRadius={1}
              sx={{
                backgroundColor: "#FEEDE5",
              }}
            >
              <Typography color="#FA5A00" fontFamily="Noto Sans JP" fontSize={16} lineHeight={1.5} fontWeight="bold">
                {`${t("common.excretion.feces")} ${
                  careSubject.active_alert_log ? careSubject.active_alert_log.feces_count : 0
                }`}
              </Typography>
            </Box>
          </Box>
          <Box
            display="flex"
            px={2}
            justifyContent="center"
            alignItems="center"
            gap={1.25}
            flex="1 0 0"
            alignSelf="stretch"
            sx={{
              backgroundColor: "#F9F9F9",
            }}
          >
            <Typography fontSize={16}>
              <FontAwesomeIcon icon={isNoticeTimeView ? faClock : faHourglass2} />
            </Typography>
            <Box display="flex" justifyContent="center" alignItems="center" gap={1}>
              {alertedAt == null ? (
                <EmptyAlertedAt />
              ) : isNoticeTimeView ? (
                <NoticedAtTime alertedAt={alertedAt} />
              ) : (
                <NotificationElapsedTime alertedAt={alertedAt} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export const NoticesSP = () => {
  const careSubjects = useListCareSubjectQuery({}, Constants.POLLING_INTERVAL).data?.items;
  const navigate = useNavigate();

  const noticeViewsMap = useMemo(() => {
    const map: { [key: number]: NoticeView } = {};

    if (careSubjects == null) {
      return map;
    }

    const sortedCareSubjects = careSubjects.slice();
    sortedCareSubjects.sort((a, b) => (a.room_name === b.room_name ? 0 : a.room_name! > b.room_name! ? 1 : -1));
    sortedCareSubjects.forEach((careSubject) => {
      const { groups } = careSubject;
      if (groups == null) {
        return;
      }

      // グループに1つも属していないのなら「未所属」というグループに属させる
      if (groups.length === 0) {
        map[UNASSIGNED_GROUP_ID] = map[UNASSIGNED_GROUP_ID] ?? {
          group: UNASSIGNED_GROUP,
          careSubjects: [],
        };
        map[UNASSIGNED_GROUP_ID].careSubjects.push(careSubject);
        return;
      }

      groups.forEach((group) => {
        map[Number(group.id)] = map[Number(group.id)] ?? { group, careSubjects: [] };
        map[Number(group.id)].careSubjects.push(careSubject);
      });
    });

    return map;
  }, [careSubjects]);

  const handleClickHeaderSetting = useCallback(() => {
    navigate("/notices_menu");
  }, [navigate]);

  const [isGroupSortView] = useBooleanLocalStorage(`${NoticesSP.name}__isGroupSortView`, true);
  const [isNoticeTimeView] = useBooleanLocalStorage(`${NoticesSP.name}__isNoticeTimeView`, true);
  const [isAllView] = useBooleanLocalStorage(`${NoticesSP.name}__isAllView`, true);
  const [selectedGroups] = useNumberArrayLocalStorage(`${NoticesSP.name}__selectedGroups`, []);

  const {
    open: openDeviceErrorDialog,
    showDialog: showDeviceErrorDialog,
    hideDialog: handleCloseDeviceErrorDialog,
    params: DeviceErrorDialogParams,
  } = useDeviceErrorDialog();

  const handleClickDeviceErrorIcon = useCallback(
    (careSubject: ModelsCareSubject) => {
      showDeviceErrorDialog({
        careSubjectName: careSubject.name,
        deviceErrorCode: careSubject.device_error,
      });
    },
    [showDeviceErrorDialog]
  );

  return (
    <SpPage onClickSetting={handleClickHeaderSetting}>
      <DeviceErrorDialog
        open={openDeviceErrorDialog}
        onClose={handleCloseDeviceErrorDialog}
        {...DeviceErrorDialogParams}
      />
      <Box display="flex" px={2} py={3} gap={1} flexWrap="wrap">
        {Object.entries(noticeViewsMap)
          .filter(([key]) => selectedGroups.length === 0 || selectedGroups.includes(Number(key)))
          .map(([key, view]) =>
            isGroupSortView ? (
              view.careSubjects.map((careSubject) => (
                <NoticeItem
                  key={`${careSubject.id}_${view.group.id}`}
                  careSubject={careSubject}
                  group={view.group}
                  isNoticeTimeView={isNoticeTimeView}
                  isAllView={isAllView}
                  onClickDeviceErrorIcon={handleClickDeviceErrorIcon}
                />
              ))
            ) : (
              <Box key={key} width="100%" display="flex" flexWrap="wrap" gap={"24px"}>
                {view.careSubjects.map((careSubject) => (
                  <NoticeItem
                    key={`${careSubject.id}_${view.group.id}`}
                    careSubject={careSubject}
                    group={view.group}
                    isNoticeTimeView={isNoticeTimeView}
                    isAllView={isAllView}
                    onClickDeviceErrorIcon={handleClickDeviceErrorIcon}
                  />
                ))}
              </Box>
            )
          )}
      </Box>
    </SpPage>
  );
};

/**
 * 通知発生時刻を表示
 */
const NoticedAtTime = (props: { alertedAt: Date }) => {
  const { t } = useTranslation();
  return (
    <>
      <AlertedAtValue>{format(props.alertedAt, "HH:mm")}</AlertedAtValue>
      <AlertedAtLabel>{t("notices.notices.alert")}</AlertedAtLabel>
    </>
  );
};

const EmptyAlertedAt = () => (
  <Typography align="center" fontSize="16px" pl={0.5} flex={1}>
    -
  </Typography>
);
const AlertedAtLabel = (props: { children: ReactNode }) => {
  return (
    <Typography fontSize={16} fontWeight="bold" lineHeight={1.5}>
      {props.children}
    </Typography>
  );
};
const AlertedAtValue = (props: { children: ReactNode }) => {
  return (
    <Typography fontSize={16} fontWeight="bold" lineHeight={1.5}>
      {props.children}
    </Typography>
  );
};

/**
 * 通知発生してからの時間を表示
 */
const NotificationElapsedTime = (props: { alertedAt: Date }) => {
  const { t } = useTranslation();
  const { alertedAt } = props;

  const str = useMemo(() => toTimeDiffString(new Date(), alertedAt, t), [alertedAt, t]);

  return (
    <>
      <AlertedAtValue>{str}</AlertedAtValue>
      <AlertedAtLabel>{t("notices.notices.elapsed")}</AlertedAtLabel>
    </>
  );
};
