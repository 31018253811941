import { format } from "date-fns";
import { useCallback, useMemo, useRef, useState } from "react";
import { Grid, Typography, DialogContent, Box, Alert } from "@mui/material";
import { CommonDialog } from "./CommonDialog";
import { Constants, getGenderLabel } from "src/constants/commonConstants";
import { useDeleteCareSubjectMutation } from "src/store/enhancedApi";
import { ModelsCareSubject } from "src/store/helppadApi";
import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { useTranslation } from "react-i18next";
import { useLocale } from "src/utils/localeMap";

type Props = {
  deleteCareSubjectRecord: ModelsCareSubject | null;
  saveDeleteCareSubjectRecord: () => void;
  handleClose: () => void;
  status: "idling" | "processing" | "finished";
  error?: Error; // 削除処理で発生したエラー
};

export const useDeleteCareSubject = () => {
  const { t } = useTranslation();
  const [deleteCareSubjectRecord, setCareSubjectRecord] = useState<ModelsCareSubject | null>(null);

  const [deleteApi, { status, reset, error }] = useDeleteCareSubjectMutation(); // TODO apiハンドリング

  const showDeleteDialog = useCallback(
    ({ id, name, kana, room_name, gender, birth_date }: ModelsCareSubject) => {
      reset();
      setCareSubjectRecord({
        id,
        name,
        kana,
        room_name,
        gender,
        birth_date,
      });
    },
    [reset]
  );

  const hideDeleteDialog = useCallback(() => {
    setCareSubjectRecord(null);
  }, []);

  const saveDeleteCareSubjectRecord = useCallback(() => {
    deleteApi({
      id: deleteCareSubjectRecord?.id ? deleteCareSubjectRecord?.id : 0,
    });
  }, [deleteCareSubjectRecord, deleteApi]);

  const _status: "idling" | "processing" | "finished" = useMemo(() => {
    if (status === QueryStatus.pending) {
      return "processing";
    }
    if (status === QueryStatus.fulfilled) {
      return "finished";
    }
    return "idling";
  }, [status]);

  const _error = useMemo(() => {
    if (error == null) {
      return undefined;
    }
    return Error(t("common.error.failed_deletion"));
  }, [error, t]);

  return {
    deleteCareSubjectRecord,
    saveDeleteCareSubjectRecord,
    showDeleteDialog,
    hideDeleteDialog,
    status: _status,
    error: _error,
  };
};

export const CareSubjectDeleteModal = ({
  deleteCareSubjectRecord,
  saveDeleteCareSubjectRecord,
  handleClose,
  status,
  error,
}: Props) => {
  const { t } = useTranslation();
  const locale = useLocale();

  const textFieldRef = useRef<HTMLInputElement>(null);
  const handleAnimationEnd = useCallback(() => {
    const { current } = textFieldRef;
    if (current == null) {
      return;
    }
    current.focus();
    current.selectionStart = current.value.length;
    current.selectionEnd = current.value.length;
  }, [textFieldRef]);

  const registering = useMemo(() => status === "processing", [status]);
  const registered = useMemo(() => status === "finished", [status]);

  if (!deleteCareSubjectRecord) {
    return null;
  }

  const { name, kana, room_name, gender, birth_date } = deleteCareSubjectRecord;
  return (
    <CommonDialog
      dialogOpen={!!deleteCareSubjectRecord}
      dialogAnimationEnd={handleAnimationEnd}
      title={t("module.care_subject.delete_care_subject")}
      acceptButtonName={t("common.button.delete")}
      cancelButtonName={t("common.button.cancel")}
      onAccept={saveDeleteCareSubjectRecord}
      onCancel={handleClose}
      isChecked={true}
      isProcessed={registering}
      isCompleted={registered}
      completeMessage={t("module.care_subject.deleted_care_subject")}
    >
      <DialogContent>
        <Box
          alignItems="center"
          justifyContent="center"
          sx={{
            margin: "0 30px",
          }}
        >
          <Grid item xs={11}>
            <Typography display="flex" justifyContent="left">
              {t("module.care_subject.confirm_deletion_care_subject")}
            </Typography>
          </Grid>
        </Box>
        <Box
          alignItems="center"
          justifyContent="center"
          sx={{
            margin: "20px 30px",
            border: "1px solid #ccc",
            borderRadius: "10px",
            padding: "30px",
          }}
        >
          <Grid container alignItems="center" rowGap={3}>
            <Grid item xs={4}>
              <Typography textAlign="right" mr={3}>
                {t("common.care_subject.care_subject_name")}：
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography textAlign="right" mr={3}>
                {t("common.care_subject.kana")}：
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{kana}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography textAlign="right" mr={3}>
                {t("common.care_subject.room_name")}：
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{room_name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography textAlign="right" mr={3}>
                {t("common.care_subject.gender")}：
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>{t(getGenderLabel(gender))}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography textAlign="right" mr={3}>
                {t("common.care_subject.birthday")}：
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography>
                {birth_date ? format(new Date(birth_date), Constants.DATE_FORMAT_BIRTH_DATE, { locale }) : ""}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {error && (
          <Alert style={{ width: "auto", minWidth: "auto" }} severity="error">
            {error.message}
          </Alert>
        )}
      </DialogContent>
    </CommonDialog>
  );
};
