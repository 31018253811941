import { useCallback, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { format } from "date-fns";
import ja from "date-fns/locale/ja";
import { Grid, Typography, MenuItem, Box } from "@mui/material";
import { CommonButton } from "src/modules/parts/CommonButton";
import { CareSubjectCreateOrModifyModal, useCreateOrModifyCareSubject } from "src/modules/dialog/CareSubjectAddModal";
import { CareSubjectDeleteModal, useDeleteCareSubject } from "src/modules/dialog/CareSubjectDeleteModal";
import { Constants, getGenderLabel, calculateAge } from "src/constants/commonConstants";
import { CommonPagination } from "src/modules/parts/CommonPagination";
import { ModelsCareSubject, ModelsGroup, useListCareSubjectQuery } from "src/store/helppadApi";
import { toCareSubjectPageLink } from "../CareSubject/toCareSubjectPageLink";
import { ListTable } from "../../modules/table/ListTable";
import { TableRowMoreDropdown } from "src/modules/table/parts/TableRowMoreDropdown";
import {
  CareSubjectIntegrationSettingDialog,
  useCareSubjectIntegrationSettingDialog,
} from "src/modules/dialog/integrationApp/CareSubjectIntegrationSettingDialog";
import { useBlueOceanIntegrationSetting } from "src/utils/useBlueOceanIntegrationSetting";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";

export const CareSubjectsSetting = () => {
  const { t } = useTranslation();
  // 現状、BlueOceanと連携している場合のみ、連携情報の設定が可能
  const [blueOceanIntegrationSetting] = useBlueOceanIntegrationSetting();
  const enableIntegrationSetting = useMemo(() => blueOceanIntegrationSetting != null, [blueOceanIntegrationSetting]);

  const [currentPage, setCurrentPage] = useState(1);
  const data = useListCareSubjectQuery({ page: currentPage, size: 10 }, Constants.POLLING_INTERVAL).data;
  const location = useLocation();
  const fromPath = location.pathname.slice(1);

  const {
    modifyCareSubjectRecord,
    onModifyCareSubjectRecord,
    saveModifyCareSubjectRecord,
    showCreateDialog,
    showModifyDialog,
    hideDialog,
    status: createOrModifyStatus,
    error: createOrModifyError,
  } = useCreateOrModifyCareSubject();

  const {
    deleteCareSubjectRecord,
    saveDeleteCareSubjectRecord,
    showDeleteDialog,
    hideDeleteDialog,
    status: deleteStatus,
    error: deleteError,
  } = useDeleteCareSubject();

  const careSubjectIntegrationSettingDialogState = useCareSubjectIntegrationSettingDialog();

  return (
    <Grid sx={{ background: "#F9F9F9" }} display={"flex"} p={4} flexDirection={"column"} height={"100%"} width={"100%"}>
      <Grid pb={2} display={"flex"} justifyContent={"flex-end"}>
        <CareSubjectCreateOrModifyModal
          modifyCareSubjectRecord={modifyCareSubjectRecord}
          onModifyCareSubjectRecord={onModifyCareSubjectRecord}
          saveModifyCareSubjectRecord={saveModifyCareSubjectRecord}
          onClose={hideDialog}
          status={createOrModifyStatus}
          error={createOrModifyError}
        />
        <CareSubjectDeleteModal
          deleteCareSubjectRecord={deleteCareSubjectRecord}
          saveDeleteCareSubjectRecord={saveDeleteCareSubjectRecord}
          handleClose={() => hideDeleteDialog()}
          status={deleteStatus}
          error={deleteError}
        />
        <CommonButton label={t("setting.care_subjects_setting.create_care_subject")} onClick={showCreateDialog} />
      </Grid>
      <CareSubjectIntegrationSettingDialog {...careSubjectIntegrationSettingDialogState} />

      <ListTable>
        <ListTable.Head>
          <ListTable.Head.Cell width={"18%"}>{t("common.care_subject.room_name")}</ListTable.Head.Cell>
          <ListTable.Head.Cell width={"20%"}>
            {t("setting.care_subjects_setting.care_subject_name")}
          </ListTable.Head.Cell>
          <ListTable.Head.Cell width={"20%"}>{t("common.care_subject.kana")}</ListTable.Head.Cell>
          <ListTable.Head.Cell width={"15%"}>{t("common.group.group_name")}</ListTable.Head.Cell>
          <ListTable.Head.Cell width={"7%"}>{t("common.care_subject.gender")}</ListTable.Head.Cell>
          <ListTable.Head.Cell width={"6%"}>{t("common.care_subject.age")}</ListTable.Head.Cell>
          <ListTable.Head.Cell flex={1}>{t("common.care_subject.birthday")}</ListTable.Head.Cell>
        </ListTable.Head>
        <ListTable.Body>
          {data?.items?.map((careSubject: ModelsCareSubject) => (
            <ListTable.Body.Row key={careSubject.id}>
              <ListTable.Body.Cell width={"18%"}>{careSubject.room_name}</ListTable.Body.Cell>
              <ListTable.Body.Cell width={"20%"}>
                <NavLink style={{ color: "#F29501" }} to={toCareSubjectPageLink(careSubject.id, undefined, fromPath)}>
                  {careSubject.name}
                </NavLink>
                {/* 連携アイコン */}
                {careSubject.integration_count != null && careSubject.integration_count > 0 && (
                  <Box sx={{ ml: "0.25rem" }}>
                    <FontAwesomeIcon icon={faLink} color="#404040" />
                  </Box>
                )}
              </ListTable.Body.Cell>
              <ListTable.Body.Cell width={"20%"}>{careSubject.kana}</ListTable.Body.Cell>
              <ListTable.Body.Cell width={"15%"}>
                <Box>
                  {careSubject.groups?.map((group: ModelsGroup) => (
                    <Typography key={group.id}>{group.name}</Typography>
                  ))}
                </Box>
              </ListTable.Body.Cell>
              <ListTable.Body.Cell width={"7%"}>{t(getGenderLabel(careSubject.gender))}</ListTable.Body.Cell>
              <ListTable.Body.Cell width={"6%"}>{calculateAge(careSubject)}</ListTable.Body.Cell>
              <ListTable.Body.Cell flex={1}>
                {careSubject.birth_date
                  ? format(new Date(careSubject.birth_date), Constants.DATE_FORMAT_BIRTH_DATE, { locale: ja })
                  : ""}
              </ListTable.Body.Cell>
              <ListTable.Body.Cell pr={2}>
                <MoreOptionDropdown
                  careSubject={careSubject}
                  onClickEdit={showModifyDialog}
                  onClickDelete={showDeleteDialog}
                  onClickInspectionSetting={
                    enableIntegrationSetting ? careSubjectIntegrationSettingDialogState.showDialog : undefined
                  }
                />
              </ListTable.Body.Cell>
            </ListTable.Body.Row>
          ))}
        </ListTable.Body>
      </ListTable>
      <CommonPagination
        currentPage={currentPage}
        totalCount={data?.page_info?.total_pages}
        onChangePage={setCurrentPage}
      />
    </Grid>
  );
};

export const MoreOptionDropdown = ({
  careSubject,
  onClickEdit,
  onClickDelete,
  onClickInspectionSetting,
}: {
  careSubject: ModelsCareSubject;
  onClickEdit: (careSubject: ModelsCareSubject) => void;
  onClickDelete: (careSubject: ModelsCareSubject) => void;
  onClickInspectionSetting: ((careSubjectId: number) => void) | undefined; // 値が設定されている場合のみ、「連携情報の設定」項目を表示
}) => {
  const { t } = useTranslation();
  const handleClickEdit = useCallback(() => {
    onClickEdit(careSubject);
  }, [onClickEdit, careSubject]);

  const handleClickDelete = useCallback(() => {
    onClickDelete(careSubject);
  }, [onClickDelete, careSubject]);

  const handleClickInspectionSetting = useCallback(() => {
    if (careSubject.id == null) {
      return;
    }
    onClickInspectionSetting?.(careSubject.id);
  }, [onClickInspectionSetting, careSubject]);

  return (
    <TableRowMoreDropdown>
      <MenuItem onClick={handleClickEdit}>{t("setting.care_subjects_setting.edit_care_subject")}</MenuItem>
      <MenuItem onClick={handleClickDelete}>{t("setting.care_subjects_setting.delete_care_subject")}</MenuItem>
      {onClickInspectionSetting && (
        <MenuItem onClick={handleClickInspectionSetting}>
          {t("setting.care_subjects_setting.setup_integration")}
        </MenuItem>
      )}
    </TableRowMoreDropdown>
  );
};
