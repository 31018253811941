import { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Grid, Typography, Radio, RadioGroup, FormControlLabel, DialogContent } from "@mui/material";
import { CommonDialog } from "./CommonDialog";
import { Constants, Group, PushNotify, getPushNotifyLabel } from "src/constants/commonConstants";
import {
  usePatchGroupMutation,
  usePostGroupMutation,
  useSetNotificationTargetsByStaffIdMutation,
} from "src/store/enhancedApi";
import { ModelsGroup, useFindNotificationTargetsByStaffIdQuery } from "src/store/helppadApi";
import { useGlobalProps } from "src/store/GlobalProps";
import { CustomTextInput } from "./parts/CustomTextInput";
import { InputConstraintText } from "./parts/InputConstraintText";
import { useTranslation } from "react-i18next";

type Props = {
  modifyGroupRecord: Group | null;
  onModifyGroupRecord: (next: Partial<Group>) => void;
  saveModifyGroupRecord: () => void;
  handleClose: () => void;
  registering: boolean; // 登録処理中か
  registered: boolean; // 登録処理が完了したか
  error?: Error; // 登録処理で発生したエラー
};

export const initialState = {
  id: undefined,
  name: "",
  pushNotify: 1,
} as Group;

export const useModifyGroup = () => {
  const { staffId } = useGlobalProps();
  const notificationTargets = useFindNotificationTargetsByStaffIdQuery(
    { id: staffId! },
    Constants.POLLING_INTERVAL
  ).data;

  const notificationGroupIds = useMemo(() => {
    return [...(notificationTargets?.group_ids ?? [])];
  }, [notificationTargets]);

  const [modifyGroupRecord, setGroupRecord] = useState<Group | null>(null);
  const [modifyRegistering, setRegistering] = useState(false);
  const [modifyRegistered, setRegistered] = useState(false);

  const [patchApi /*{ isError, isSuccess }*/] = usePatchGroupMutation(); // TODO apiハンドリング
  const [postApi /*{ isError, isSuccess }*/] = usePostGroupMutation(); // TODO apiハンドリング
  const [notificationPatchApi /*{ isError, isSuccess }*/] = useSetNotificationTargetsByStaffIdMutation();

  const showModifyDialog = useCallback(({ id, name, pushNotify }: Group) => {
    setGroupRecord({
      id,
      name,
      pushNotify,
    });
  }, []);

  const hideModifyDialog = useCallback(() => {
    setGroupRecord(null);
    setRegistered(false);
  }, []);

  const onModifyGroupRecord = useCallback(
    (data: Partial<Group>) => {
      if (!modifyGroupRecord) throw new Error("never");
      setGroupRecord({
        ...modifyGroupRecord,
        ...data,
      });
    },
    [modifyGroupRecord]
  );

  const startRegistering = useCallback(() => {
    setRegistering(true);
  }, []);

  const finishRegistering = useCallback(() => {
    setRegistering(false);
  }, []);

  const onRegistered = useCallback(() => {
    setRegistered(true);
  }, []);

  const saveModifyGroupRecord = useCallback(async () => {
    startRegistering();
    if (modifyGroupRecord?.id) {
      patchApi({
        id: modifyGroupRecord?.id,
        modelsGroupPatchIn: {
          name: modifyGroupRecord.name,
        },
      });

      notificationGroupIds.push(modifyGroupRecord.id);
      notificationPatchApi({
        id: staffId!,
        modelsStaffPutNotificationTargetsIn: {
          group_ids: notificationGroupIds,
        },
      });
    } else {
      const res = await postApi({
        modelsGroupPostIn: {
          name: modifyGroupRecord?.name ? modifyGroupRecord?.name : "",
        },
      });
      const { data } = res as { data: ModelsGroup };
      if (modifyGroupRecord?.pushNotify && data.id) {
        notificationPatchApi({
          id: staffId!,
          modelsStaffPutNotificationTargetsIn: {
            group_ids: [...notificationGroupIds, data.id],
          },
        });
      }
    }
    finishRegistering();
    onRegistered();
  }, [
    staffId,
    modifyGroupRecord,
    notificationGroupIds,
    startRegistering,
    finishRegistering,
    onRegistered,
    patchApi,
    postApi,
    notificationPatchApi,
  ]);

  return {
    modifyGroupRecord,
    onModifyGroupRecord,
    saveModifyGroupRecord,
    showModifyDialog,
    hideModifyDialog,
    modifyRegistering,
    modifyRegistered,
  };
};

const useInputValidate = (open: boolean, inputs: Group | null, onUpdateInputs: (next: Partial<Group>) => void) => {
  // グループ名
  const [isValidGroupName, setIsValidGroupName] = useState<boolean | undefined>();
  const onChangeGroupName = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setIsValidGroupName(validateGroupName(event.target.value));
      onUpdateInputs({ name: event.target.value });
    },
    [onUpdateInputs]
  );

  const isAllValidate = useMemo(() => isValidGroupName, [isValidGroupName]);

  // ダイアログ表示時に初期値のバリデーションをかける
  // ダイアログの表示・非表示がこのコンポーネントからコントロール不能なので hide -> show を引っ掛ける
  const prevOpen = useRef<boolean | undefined>();
  useEffect(() => {
    // initialState の値を初期値として、新規追加 or 編集か判断している
    if (prevOpen.current === false && open === true && inputs != null) {
      const { name } = inputs;
      setIsValidGroupName(name == null || name === initialState.name ? undefined : validateGroupName(name));
    }
  }, [open, prevOpen, inputs]);
  useEffect(() => {
    prevOpen.current = open;
  }, [open]);

  return {
    isValidGroupName,
    onChangeGroupName,
    isAllValidate,
  };
};

export const GroupModifyModal = ({
  modifyGroupRecord,
  onModifyGroupRecord,
  saveModifyGroupRecord,
  handleClose,
  registering,
  registered,
}: Props) => {
  const { t } = useTranslation();
  const textFieldRef = useRef<HTMLInputElement>(null);
  const open = !!modifyGroupRecord;
  const validates = useInputValidate(open, modifyGroupRecord, onModifyGroupRecord);
  const handleAnimationEnd = useCallback(() => {
    const { current } = textFieldRef;
    if (current == null) {
      return;
    }
    current.focus();
    current.selectionStart = current.value.length;
    current.selectionEnd = current.value.length;
  }, [textFieldRef]);

  if (!modifyGroupRecord) return null;

  const { name, pushNotify } = modifyGroupRecord;
  let title: string;
  let inputButtonLabel: string;
  let completeMessage: string;
  if (modifyGroupRecord.id) {
    title = "module.group.edit_group";
    inputButtonLabel = "common.button.register";
    completeMessage = "module.group.edited_group";
  } else {
    title = "module.group.add_group";
    inputButtonLabel = "common.button.register";
    completeMessage = "module.group.added_group";
  }

  return (
    <CommonDialog
      dialogOpen={open}
      dialogAnimationEnd={handleAnimationEnd}
      title={t(title)}
      acceptButtonName={t(inputButtonLabel)}
      cancelButtonName={t("common.button.cancel")}
      onAccept={saveModifyGroupRecord}
      onCancel={handleClose}
      isChecked={validates.isAllValidate}
      isProcessed={registering}
      isCompleted={registered}
      completeContent={<ModifyGroupComplete group={modifyGroupRecord} />}
      completeMessage={t(completeMessage)}
    >
      <DialogContent>
        <Grid container alignItems="center" rowGap={3}>
          <Grid container alignItems="center">
            <Grid item xs={3}>
              <Typography textAlign="right" mr={3}>
                {t("common.group.group_name")}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <CustomTextInput
                name={"group_name"}
                disabled={registering}
                value={name}
                onChange={validates.onChangeGroupName}
                isValid={validates.isValidGroupName}
              />
            </Grid>
            <Grid item xs={3} />
            <Grid item xs={8}>
              <InputConstraintText>{t("module.group.group_name_max_length")}</InputConstraintText>
            </Grid>
          </Grid>
          <Grid item xs={3}>
            <Typography textAlign="right" mr={3}>
              {t("module.group.push_notification")}
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <RadioGroup
              value={pushNotify}
              onChange={(event) => onModifyGroupRecord({ pushNotify: Number(event.target.value) as PushNotify })}
              row
            >
              <FormControlLabel
                disabled={registering}
                value="1"
                control={<Radio />}
                label={t("module.group.with_push_notification")}
              />
              <FormControlLabel
                disabled={registering}
                value="0"
                control={<Radio />}
                label={t("module.group.no_push_notification")}
              />
            </RadioGroup>
          </Grid>
        </Grid>
      </DialogContent>
    </CommonDialog>
  );
};

const ModifyGroupComplete = (props: { group: Group }) => {
  const { t } = useTranslation();
  return (
    <Grid container alignItems="center" rowGap={3}>
      <Grid item xs={4}>
        <Typography textAlign="right" mr={3}>
          {t("common.group.group_name")}：
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{props.group.name}</Typography>
      </Grid>
      <Grid item xs={4}>
        <Typography textAlign="right" mr={3}>
          {t("module.group.push_notification")}：
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography>{t(getPushNotifyLabel(props.group.pushNotify))}</Typography>
      </Grid>
    </Grid>
  );
};

const validateGroupName = (value: string): boolean => {
  return 1 <= value.length && value.length <= 8;
};
