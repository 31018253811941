import { TFunction } from "i18next";

export const ExcretionSupportTypes = {
  Urine: 1, // 尿対応
  Feces: 2, // 便対応
  UrineAndFeces: 3, // 尿便対応
  None: 0, // 排泄なし
} as const;

// 排泄対応種別
export type ExcretionSupportType = typeof ExcretionSupportTypes[keyof typeof ExcretionSupportTypes];

export const isUrineCategory = (type: ExcretionSupportType | undefined | null): boolean =>
  type == null
    ? false
    : ([ExcretionSupportTypes.Urine, ExcretionSupportTypes.UrineAndFeces] as ExcretionSupportType[]).indexOf(type) !==
      -1;

export const isFecesCategory = (type: ExcretionSupportType | undefined | null): boolean =>
  type == null
    ? false
    : ([ExcretionSupportTypes.Feces, ExcretionSupportTypes.UrineAndFeces] as ExcretionSupportType[]).indexOf(type) !==
      -1;

export const toExcretionSupportTypeName = (type: ExcretionSupportType | undefined, t: TFunction): string => {
  switch (type) {
    case ExcretionSupportTypes.Urine:
      return t("common.excretion.urine_support");
    case ExcretionSupportTypes.Feces:
      return t("common.excretion.feces_support");
    case ExcretionSupportTypes.UrineAndFeces:
      return t("common.excretion.urine_and_feces_support");
    case ExcretionSupportTypes.None:
      return t("common.excretion.excretion_none");
  }
  return "---";
};
