import { baseSplitApi as api } from "./baseApi";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    aggregation: build.query<AggregationApiResponse, AggregationApiArg>({
      query: (queryArg) => ({
        url: `/aggregation`,
        params: {
          aggregation_type: queryArg.aggregationType,
          end: queryArg.end,
          start: queryArg.start,
          target: queryArg.target,
        },
      }),
    }),
    listAlertLog: build.query<ListAlertLogApiResponse, ListAlertLogApiArg>({
      query: (queryArg) => ({
        url: `/alert_logs`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
          care_subject_id: queryArg.careSubjectId,
          device_id: queryArg.deviceId,
          duration: queryArg.duration,
          end_at: queryArg.endAt,
          is_active: queryArg.isActive,
        },
      }),
    }),
    getAlertLog: build.query<GetAlertLogApiResponse, GetAlertLogApiArg>({
      query: (queryArg) => ({ url: `/alert_logs/${queryArg.id}` }),
    }),
    listCareSubject: build.query<ListCareSubjectApiResponse, ListCareSubjectApiArg>({
      query: (queryArg) => ({
        url: `/care_subjects`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
        },
      }),
    }),
    postCareSubject: build.mutation<PostCareSubjectApiResponse, PostCareSubjectApiArg>({
      query: (queryArg) => ({
        url: `/care_subjects`,
        method: "POST",
        body: queryArg.modelsCareSubjectPostIn,
      }),
    }),
    deleteCareSubject: build.mutation<DeleteCareSubjectApiResponse, DeleteCareSubjectApiArg>({
      query: (queryArg) => ({
        url: `/care_subjects/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getCareSubject: build.query<GetCareSubjectApiResponse, GetCareSubjectApiArg>({
      query: (queryArg) => ({ url: `/care_subjects/${queryArg.id}` }),
    }),
    patchCareSubject: build.mutation<PatchCareSubjectApiResponse, PatchCareSubjectApiArg>({
      query: (queryArg) => ({
        url: `/care_subjects/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.modelsCareSubjectPatchIn,
      }),
    }),
    findCommentLogsByCareSubjectId: build.query<
      FindCommentLogsByCareSubjectIdApiResponse,
      FindCommentLogsByCareSubjectIdApiArg
    >({
      query: (queryArg) => ({
        url: `/care_subjects/${queryArg.id}/comment_logs`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
        },
      }),
    }),
    updateDeviceOfCareSubject: build.mutation<UpdateDeviceOfCareSubjectApiResponse, UpdateDeviceOfCareSubjectApiArg>({
      query: (queryArg) => ({
        url: `/care_subjects/${queryArg.id}/device`,
        method: "PATCH",
        body: queryArg.modelsCareSubjectPatchDeviceIn,
      }),
    }),
    updateGroupsOfCareSubject: build.mutation<UpdateGroupsOfCareSubjectApiResponse, UpdateGroupsOfCareSubjectApiArg>({
      query: (queryArg) => ({
        url: `/care_subjects/${queryArg.id}/groups`,
        method: "PATCH",
        body: queryArg.modelsCareSubjectPutGroupsIn,
      }),
    }),
    setGroupsOfCareSubject: build.mutation<SetGroupsOfCareSubjectApiResponse, SetGroupsOfCareSubjectApiArg>({
      query: (queryArg) => ({
        url: `/care_subjects/${queryArg.id}/groups`,
        method: "PUT",
        body: queryArg.modelsCareSubjectPutGroupsIn,
      }),
    }),
    findIntegrationCareSubjectsByCareSubjectId: build.query<
      FindIntegrationCareSubjectsByCareSubjectIdApiResponse,
      FindIntegrationCareSubjectsByCareSubjectIdApiArg
    >({
      query: (queryArg) => ({
        url: `/care_subjects/${queryArg.id}/integration_care_subjects`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
        },
      }),
    }),
    deleteCareSubjectPhoto: build.mutation<DeleteCareSubjectPhotoApiResponse, DeleteCareSubjectPhotoApiArg>({
      query: (queryArg) => ({
        url: `/care_subjects/${queryArg.id}/photo`,
        method: "DELETE",
      }),
    }),
    putCareSubjectPhoto: build.mutation<PutCareSubjectPhotoApiResponse, PutCareSubjectPhotoApiArg>({
      query: (queryArg) => ({
        url: `/care_subjects/${queryArg.id}/photo`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    listCommentLog: build.query<ListCommentLogApiResponse, ListCommentLogApiArg>({
      query: (queryArg) => ({
        url: `/comment_logs`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
          duration: queryArg.duration,
          end_at: queryArg.endAt,
          thread_id: queryArg.threadId,
        },
      }),
    }),
    postCommentLog: build.mutation<PostCommentLogApiResponse, PostCommentLogApiArg>({
      query: (queryArg) => ({
        url: `/comment_logs`,
        method: "POST",
        body: queryArg.modelsCommentLogPostIn,
      }),
    }),
    deleteCommentLog: build.mutation<DeleteCommentLogApiResponse, DeleteCommentLogApiArg>({
      query: (queryArg) => ({
        url: `/comment_logs/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getCommentLog: build.query<GetCommentLogApiResponse, GetCommentLogApiArg>({
      query: (queryArg) => ({ url: `/comment_logs/${queryArg.id}` }),
    }),
    patchCommentLog: build.mutation<PatchCommentLogApiResponse, PatchCommentLogApiArg>({
      query: (queryArg) => ({
        url: `/comment_logs/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.modelsCommentLogPatchIn,
      }),
    }),
    listCommentThread: build.query<ListCommentThreadApiResponse, ListCommentThreadApiArg>({
      query: (queryArg) => ({
        url: `/comment_threads`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
          care_subject_id: queryArg.careSubjectId,
          duration: queryArg.duration,
          end_at: queryArg.endAt,
        },
      }),
    }),
    postCommentThread: build.mutation<PostCommentThreadApiResponse, PostCommentThreadApiArg>({
      query: (queryArg) => ({
        url: `/comment_threads`,
        method: "POST",
        body: queryArg.modelsCommentThreadPostIn,
      }),
    }),
    deleteCommentThread: build.mutation<DeleteCommentThreadApiResponse, DeleteCommentThreadApiArg>({
      query: (queryArg) => ({
        url: `/comment_threads/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getCommentThread: build.query<GetCommentThreadApiResponse, GetCommentThreadApiArg>({
      query: (queryArg) => ({ url: `/comment_threads/${queryArg.id}` }),
    }),
    patchCommentThread: build.mutation<PatchCommentThreadApiResponse, PatchCommentThreadApiArg>({
      query: (queryArg) => ({
        url: `/comment_threads/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.modelsCommentThreadPatchIn,
      }),
    }),
    listDetectionLog: build.query<ListDetectionLogApiResponse, ListDetectionLogApiArg>({
      query: (queryArg) => ({
        url: `/detection_logs`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
          care_subject_id: queryArg.careSubjectId,
          device_id: queryArg.deviceId,
          duration: queryArg.duration,
          end_at: queryArg.endAt,
          is_latest: queryArg.isLatest,
        },
      }),
    }),
    getDetectionLog: build.query<GetDetectionLogApiResponse, GetDetectionLogApiArg>({
      query: (queryArg) => ({ url: `/detection_logs/${queryArg.id}` }),
    }),
    listDevice: build.query<ListDeviceApiResponse, ListDeviceApiArg>({
      query: (queryArg) => ({
        url: `/devices`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
        },
      }),
    }),
    getDevice: build.query<GetDeviceApiResponse, GetDeviceApiArg>({
      query: (queryArg) => ({ url: `/devices/${queryArg.id}` }),
    }),
    listExcretionResult: build.query<ListExcretionResultApiResponse, ListExcretionResultApiArg>({
      query: (queryArg) => ({
        url: `/excretion_results`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
          care_subject_id: queryArg.careSubjectId,
          duration: queryArg.duration,
          end_at: queryArg.endAt,
        },
      }),
    }),
    postExcretionResult: build.mutation<PostExcretionResultApiResponse, PostExcretionResultApiArg>({
      query: (queryArg) => ({
        url: `/excretion_results`,
        method: "POST",
        body: queryArg.modelsExcretionResultPostIn,
      }),
    }),
    deleteExcretionResult: build.mutation<DeleteExcretionResultApiResponse, DeleteExcretionResultApiArg>({
      query: (queryArg) => ({
        url: `/excretion_results/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getExcretionResult: build.query<GetExcretionResultApiResponse, GetExcretionResultApiArg>({
      query: (queryArg) => ({ url: `/excretion_results/${queryArg.id}` }),
    }),
    patchExcretionResult: build.mutation<PatchExcretionResultApiResponse, PatchExcretionResultApiArg>({
      query: (queryArg) => ({
        url: `/excretion_results/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.modelsExcretionResultPatchIn,
      }),
    }),
    getFacility: build.query<GetFacilityApiResponse, GetFacilityApiArg>({
      query: () => ({ url: `/facility` }),
    }),
    listGroupSubject: build.query<ListGroupSubjectApiResponse, ListGroupSubjectApiArg>({
      query: (queryArg) => ({
        url: `/group_subjects`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
        },
      }),
    }),
    postGroupSubject: build.mutation<PostGroupSubjectApiResponse, PostGroupSubjectApiArg>({
      query: (queryArg) => ({
        url: `/group_subjects`,
        method: "POST",
        body: queryArg.modelsGroupSubjectPostIn,
      }),
    }),
    deleteGroupSubject: build.mutation<DeleteGroupSubjectApiResponse, DeleteGroupSubjectApiArg>({
      query: (queryArg) => ({
        url: `/group_subjects/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getGroupSubject: build.query<GetGroupSubjectApiResponse, GetGroupSubjectApiArg>({
      query: (queryArg) => ({ url: `/group_subjects/${queryArg.id}` }),
    }),
    listGroup: build.query<ListGroupApiResponse, ListGroupApiArg>({
      query: (queryArg) => ({
        url: `/groups`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
        },
      }),
    }),
    postGroup: build.mutation<PostGroupApiResponse, PostGroupApiArg>({
      query: (queryArg) => ({
        url: `/groups`,
        method: "POST",
        body: queryArg.modelsGroupPostIn,
      }),
    }),
    deleteGroup: build.mutation<DeleteGroupApiResponse, DeleteGroupApiArg>({
      query: (queryArg) => ({
        url: `/groups/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getGroup: build.query<GetGroupApiResponse, GetGroupApiArg>({
      query: (queryArg) => ({ url: `/groups/${queryArg.id}` }),
    }),
    patchGroup: build.mutation<PatchGroupApiResponse, PatchGroupApiArg>({
      query: (queryArg) => ({
        url: `/groups/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.modelsGroupPatchIn,
      }),
    }),
    setCareSubjectsOfGroup: build.mutation<SetCareSubjectsOfGroupApiResponse, SetCareSubjectsOfGroupApiArg>({
      query: (queryArg) => ({
        url: `/groups/${queryArg.id}/care_subjects`,
        method: "PUT",
        body: queryArg.modelsGroupPutCareSubjectsIn,
      }),
    }),
    listHistoryLog: build.query<ListHistoryLogApiResponse, ListHistoryLogApiArg>({
      query: (queryArg) => ({
        url: `/history_logs`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
          action: queryArg.action,
          care_subject_id: queryArg.careSubjectId,
          duration: queryArg.duration,
          end_at: queryArg.endAt,
          entity_id: queryArg.entityId,
          staff_id: queryArg.staffId,
          table_name: queryArg.tableName,
        },
      }),
    }),
    deleteHistoryLog: build.mutation<DeleteHistoryLogApiResponse, DeleteHistoryLogApiArg>({
      query: (queryArg) => ({
        url: `/history_logs/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getHistoryLog: build.query<GetHistoryLogApiResponse, GetHistoryLogApiArg>({
      query: (queryArg) => ({ url: `/history_logs/${queryArg.id}` }),
    }),
    listIntegrationApp: build.query<ListIntegrationAppApiResponse, ListIntegrationAppApiArg>({
      query: (queryArg) => ({
        url: `/integration_apps`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
        },
      }),
    }),
    getIntegrationApp: build.query<GetIntegrationAppApiResponse, GetIntegrationAppApiArg>({
      query: (queryArg) => ({ url: `/integration_apps/${queryArg.id}` }),
    }),
    listIntegrationAppsDefinition: build.query<
      ListIntegrationAppsDefinitionApiResponse,
      ListIntegrationAppsDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/integration_apps_definitions`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
        },
      }),
    }),
    postIntegrationAppsDefinition: build.mutation<
      PostIntegrationAppsDefinitionApiResponse,
      PostIntegrationAppsDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/integration_apps_definitions`,
        method: "POST",
        body: queryArg.modelsIntegrationAppsDefinitionPostIn,
      }),
    }),
    deleteIntegrationAppsDefinition: build.mutation<
      DeleteIntegrationAppsDefinitionApiResponse,
      DeleteIntegrationAppsDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/integration_apps_definitions/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getIntegrationAppsDefinition: build.query<
      GetIntegrationAppsDefinitionApiResponse,
      GetIntegrationAppsDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/integration_apps_definitions/${queryArg.id}`,
      }),
    }),
    patchIntegrationAppsDefinition: build.mutation<
      PatchIntegrationAppsDefinitionApiResponse,
      PatchIntegrationAppsDefinitionApiArg
    >({
      query: (queryArg) => ({
        url: `/integration_apps_definitions/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.modelsIntegrationAppsDefinitionPatchIn,
      }),
    }),
    listIntegrationCareSubjects: build.query<ListIntegrationCareSubjectsApiResponse, ListIntegrationCareSubjectsApiArg>(
      {
        query: (queryArg) => ({
          url: `/integration_care_subjects`,
          params: {
            page: queryArg.page,
            size: queryArg.size,
            sort_desc: queryArg.sortDesc,
            sort_key: queryArg.sortKey,
          },
        }),
      }
    ),
    postIntegrationCareSubjects: build.mutation<
      PostIntegrationCareSubjectsApiResponse,
      PostIntegrationCareSubjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/integration_care_subjects`,
        method: "POST",
        body: queryArg.modelsIntegrationCareSubjectsPostIn,
      }),
    }),
    deleteIntegrationCareSubjects: build.mutation<
      DeleteIntegrationCareSubjectsApiResponse,
      DeleteIntegrationCareSubjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/integration_care_subjects/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getIntegrationCareSubjects: build.query<GetIntegrationCareSubjectsApiResponse, GetIntegrationCareSubjectsApiArg>({
      query: (queryArg) => ({
        url: `/integration_care_subjects/${queryArg.id}`,
      }),
    }),
    patchIntegrationCareSubjects: build.mutation<
      PatchIntegrationCareSubjectsApiResponse,
      PatchIntegrationCareSubjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/integration_care_subjects/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.modelsIntegrationCareSubjectsPatchIn,
      }),
    }),
    login: build.mutation<LoginApiResponse, LoginApiArg>({
      query: (queryArg) => ({
        url: `/login`,
        method: "POST",
        body: queryArg.modelsLoginIn,
      }),
    }),
    logout: build.mutation<LogoutApiResponse, LogoutApiArg>({
      query: (queryArg) => ({
        url: `/logout/${queryArg.deviceToken}`,
        method: "DELETE",
      }),
    }),
    listPermission: build.query<ListPermissionApiResponse, ListPermissionApiArg>({
      query: () => ({ url: `/permissions` }),
    }),
    listRolePermission: build.query<ListRolePermissionApiResponse, ListRolePermissionApiArg>({
      query: (queryArg) => ({
        url: `/role_permissions`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
        },
      }),
    }),
    getRolePermission: build.query<GetRolePermissionApiResponse, GetRolePermissionApiArg>({
      query: (queryArg) => ({ url: `/role_permissions/${queryArg.id}` }),
    }),
    listRole: build.query<ListRoleApiResponse, ListRoleApiArg>({
      query: (queryArg) => ({
        url: `/roles`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
        },
      }),
    }),
    getRole: build.query<GetRoleApiResponse, GetRoleApiArg>({
      query: (queryArg) => ({ url: `/roles/${queryArg.id}` }),
    }),
    listStaff: build.query<ListStaffApiResponse, ListStaffApiArg>({
      query: (queryArg) => ({
        url: `/staffs`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
        },
      }),
    }),
    postStaff: build.mutation<PostStaffApiResponse, PostStaffApiArg>({
      query: (queryArg) => ({
        url: `/staffs`,
        method: "POST",
        body: queryArg.modelsStaffPostIn,
      }),
    }),
    deleteStaff: build.mutation<DeleteStaffApiResponse, DeleteStaffApiArg>({
      query: (queryArg) => ({
        url: `/staffs/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getStaff: build.query<GetStaffApiResponse, GetStaffApiArg>({
      query: (queryArg) => ({ url: `/staffs/${queryArg.id}` }),
    }),
    patchStaff: build.mutation<PatchStaffApiResponse, PatchStaffApiArg>({
      query: (queryArg) => ({
        url: `/staffs/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.modelsStaffPatchIn,
      }),
    }),
    findNotificationTargetsByStaffId: build.query<
      FindNotificationTargetsByStaffIdApiResponse,
      FindNotificationTargetsByStaffIdApiArg
    >({
      query: (queryArg) => ({
        url: `/staffs/${queryArg.id}/notification_targets`,
      }),
    }),
    setNotificationTargetsByStaffId: build.mutation<
      SetNotificationTargetsByStaffIdApiResponse,
      SetNotificationTargetsByStaffIdApiArg
    >({
      query: (queryArg) => ({
        url: `/staffs/${queryArg.id}/notification_targets`,
        method: "PUT",
        body: queryArg.modelsStaffPutNotificationTargetsIn,
      }),
    }),
    deleteStaffPhoto: build.mutation<DeleteStaffPhotoApiResponse, DeleteStaffPhotoApiArg>({
      query: (queryArg) => ({
        url: `/staffs/${queryArg.id}/photo`,
        method: "DELETE",
      }),
    }),
    putStaffPhoto: build.mutation<PutStaffPhotoApiResponse, PutStaffPhotoApiArg>({
      query: (queryArg) => ({
        url: `/staffs/${queryArg.id}/photo`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    listTerms: build.query<ListTermsApiResponse, ListTermsApiArg>({
      query: (queryArg) => ({
        url: `/terms`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
          duration: queryArg.duration,
          end_at: queryArg.endAt,
        },
      }),
    }),
    getTerms: build.query<GetTermsApiResponse, GetTermsApiArg>({
      query: (queryArg) => ({ url: `/terms/${queryArg.id}` }),
    }),
    listTermsAgreementLog: build.query<ListTermsAgreementLogApiResponse, ListTermsAgreementLogApiArg>({
      query: (queryArg) => ({
        url: `/terms_agreement_logs`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          sort_desc: queryArg.sortDesc,
          sort_key: queryArg.sortKey,
        },
      }),
    }),
    postTermsAgreementLog: build.mutation<PostTermsAgreementLogApiResponse, PostTermsAgreementLogApiArg>({
      query: (queryArg) => ({
        url: `/terms_agreement_logs`,
        method: "POST",
        body: queryArg.modelsTermsAgreementLogPostIn,
      }),
    }),
    deleteTermsAgreementLog: build.mutation<DeleteTermsAgreementLogApiResponse, DeleteTermsAgreementLogApiArg>({
      query: (queryArg) => ({
        url: `/terms_agreement_logs/${queryArg.id}`,
        method: "DELETE",
      }),
    }),
    getTermsAgreementLog: build.query<GetTermsAgreementLogApiResponse, GetTermsAgreementLogApiArg>({
      query: (queryArg) => ({ url: `/terms_agreement_logs/${queryArg.id}` }),
    }),
    patchTermsAgreementLog: build.mutation<PatchTermsAgreementLogApiResponse, PatchTermsAgreementLogApiArg>({
      query: (queryArg) => ({
        url: `/terms_agreement_logs/${queryArg.id}`,
        method: "PATCH",
        body: queryArg.modelsTermsAgreementLogPatchIn,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as helppadApi };
export type AggregationApiResponse = unknown;
export type AggregationApiArg = {
  aggregationType: "hourly";
  end: string;
  start: string;
  target: "detection" | "alert" | "excretion" | "leaked";
};
export type ListAlertLogApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsAlertLog;
export type ListAlertLogApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
  careSubjectId?: number[];
  deviceId?: number[];
  duration?: number;
  endAt?: number;
  isActive?: boolean;
};
export type GetAlertLogApiResponse = /** status 200 OK */ ModelsAlertLog;
export type GetAlertLogApiArg = {
  /** AlertLog ID */
  id: number;
};
export type ListCareSubjectApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsCareSubject;
export type ListCareSubjectApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
};
export type PostCareSubjectApiResponse = /** status 201 Created */ ModelsCareSubject;
export type PostCareSubjectApiArg = {
  /** CareSubject */
  modelsCareSubjectPostIn: ModelsCareSubjectPostIn;
};
export type DeleteCareSubjectApiResponse = /** status 204 No Content */ undefined;
export type DeleteCareSubjectApiArg = {
  /** CareSubject ID */
  id: number;
};
export type GetCareSubjectApiResponse = /** status 200 OK */ ModelsCareSubject;
export type GetCareSubjectApiArg = {
  /** CareSubject ID */
  id: number;
};
export type PatchCareSubjectApiResponse = /** status 200 OK */ ModelsCareSubject;
export type PatchCareSubjectApiArg = {
  /** CareSubject ID */
  id: number;
  /** CareSubject */
  modelsCareSubjectPatchIn: ModelsCareSubjectPatchIn;
};
export type FindCommentLogsByCareSubjectIdApiResponse =
  /** status 200 OK */ Helppad2AppApiModelsListOutModelsCommentLog;
export type FindCommentLogsByCareSubjectIdApiArg = {
  /** Care Subject ID */
  id: number;
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
};
export type UpdateDeviceOfCareSubjectApiResponse = /** status 200 OK */ ModelsCareSubjectPatchDeviceIn;
export type UpdateDeviceOfCareSubjectApiArg = {
  /** Care Subject ID */
  id: number;
  /** DeviceID, passing 0 unpairs device from care_subject */
  modelsCareSubjectPatchDeviceIn: ModelsCareSubjectPatchDeviceIn;
};
export type UpdateGroupsOfCareSubjectApiResponse = /** status 200 OK */ ModelsCareSubjectPutGroupsIn;
export type UpdateGroupsOfCareSubjectApiArg = {
  /** Care Subject ID */
  id: number;
  /** GroupIDs, passing empty array deletes all groups from the care subject */
  modelsCareSubjectPutGroupsIn: ModelsCareSubjectPutGroupsIn;
};
export type SetGroupsOfCareSubjectApiResponse = /** status 200 OK */ ModelsCareSubjectPutGroupsIn;
export type SetGroupsOfCareSubjectApiArg = {
  /** Care Subject ID */
  id: number;
  /** GroupIDs, passing empty array deletes all groups from the care subject */
  modelsCareSubjectPutGroupsIn: ModelsCareSubjectPutGroupsIn;
};
export type FindIntegrationCareSubjectsByCareSubjectIdApiResponse =
  /** status 200 OK */ Helppad2AppApiModelsListOutModelsIntegrationCareSubjects;
export type FindIntegrationCareSubjectsByCareSubjectIdApiArg = {
  /** Care Subject ID */
  id: number;
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
};
export type DeleteCareSubjectPhotoApiResponse = /** status 204 No Content */ undefined;
export type DeleteCareSubjectPhotoApiArg = {
  /** CareSubject ID */
  id: number;
};
export type PutCareSubjectPhotoApiResponse = /** status 200 OK */ HandlersPhotoPhotoUploadOut;
export type PutCareSubjectPhotoApiArg = {
  /** CareSubject ID */
  id: number;
  body: {
    photo_file: Blob;
  };
};
export type ListCommentLogApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsCommentLog;
export type ListCommentLogApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
  duration?: number;
  endAt?: number;
  threadId?: number[];
};
export type PostCommentLogApiResponse = /** status 201 Created */ ModelsCommentLog;
export type PostCommentLogApiArg = {
  /** CommentLog */
  modelsCommentLogPostIn: ModelsCommentLogPostIn;
};
export type DeleteCommentLogApiResponse = /** status 204 No Content */ undefined;
export type DeleteCommentLogApiArg = {
  /** CommentLog ID */
  id: number;
};
export type GetCommentLogApiResponse = /** status 200 OK */ ModelsCommentLog;
export type GetCommentLogApiArg = {
  /** CommentLog ID */
  id: number;
};
export type PatchCommentLogApiResponse = /** status 200 OK */ ModelsCommentLog;
export type PatchCommentLogApiArg = {
  /** CommentLog ID */
  id: number;
  /** CommentLog */
  modelsCommentLogPatchIn: ModelsCommentLogPatchIn;
};
export type ListCommentThreadApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsCommentThread;
export type ListCommentThreadApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
  careSubjectId?: number[];
  duration?: number;
  endAt?: number;
};
export type PostCommentThreadApiResponse = /** status 201 Created */ ModelsCommentThread;
export type PostCommentThreadApiArg = {
  /** CommentThread */
  modelsCommentThreadPostIn: ModelsCommentThreadPostIn;
};
export type DeleteCommentThreadApiResponse = /** status 204 No Content */ undefined;
export type DeleteCommentThreadApiArg = {
  /** CommentThread ID */
  id: number;
};
export type GetCommentThreadApiResponse = /** status 200 OK */ ModelsCommentThread;
export type GetCommentThreadApiArg = {
  /** CommentThread ID */
  id: number;
};
export type PatchCommentThreadApiResponse = /** status 200 OK */ ModelsCommentThread;
export type PatchCommentThreadApiArg = {
  /** CommentThread ID */
  id: number;
  /** CommentThread */
  modelsCommentThreadPatchIn: ModelsCommentThreadPatchIn;
};
export type ListDetectionLogApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsDetectionLog;
export type ListDetectionLogApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
  careSubjectId?: number[];
  deviceId?: number[];
  duration?: number;
  endAt?: number;
  isLatest?: boolean;
};
export type GetDetectionLogApiResponse = /** status 200 OK */ ModelsDetectionLog;
export type GetDetectionLogApiArg = {
  /** DetectionLog ID */
  id: number;
};
export type ListDeviceApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsDevice;
export type ListDeviceApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
};
export type GetDeviceApiResponse = /** status 200 OK */ ModelsDevice;
export type GetDeviceApiArg = {
  /** Device ID */
  id: number;
};
export type ListExcretionResultApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsExcretionResult;
export type ListExcretionResultApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
  careSubjectId?: number[];
  duration?: number;
  endAt?: number;
};
export type PostExcretionResultApiResponse = /** status 201 Created */ ModelsExcretionResult;
export type PostExcretionResultApiArg = {
  /** ExcretionResult */
  modelsExcretionResultPostIn: ModelsExcretionResultPostIn;
};
export type DeleteExcretionResultApiResponse = /** status 204 No Content */ undefined;
export type DeleteExcretionResultApiArg = {
  /** ExcretionResult ID */
  id: number;
};
export type GetExcretionResultApiResponse = /** status 200 OK */ ModelsExcretionResult;
export type GetExcretionResultApiArg = {
  /** ExcretionResult ID */
  id: number;
};
export type PatchExcretionResultApiResponse = /** status 200 OK */ ModelsExcretionResult;
export type PatchExcretionResultApiArg = {
  /** ExcretionResult ID */
  id: number;
  /** ExcretionResult */
  modelsExcretionResultPatchIn: ModelsExcretionResultPatchIn;
};
export type GetFacilityApiResponse = /** status 200 OK */ ModelsFacility;
export type GetFacilityApiArg = void;
export type ListGroupSubjectApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsGroupSubject;
export type ListGroupSubjectApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
};
export type PostGroupSubjectApiResponse = /** status 201 Created */ ModelsGroupSubject;
export type PostGroupSubjectApiArg = {
  /** GroupSubject */
  modelsGroupSubjectPostIn: ModelsGroupSubjectPostIn;
};
export type DeleteGroupSubjectApiResponse = /** status 204 No Content */ undefined;
export type DeleteGroupSubjectApiArg = {
  /** GroupSubject ID */
  id: number;
};
export type GetGroupSubjectApiResponse = /** status 200 OK */ ModelsGroupSubject;
export type GetGroupSubjectApiArg = {
  /** GroupSubject ID */
  id: number;
};
export type ListGroupApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsGroup;
export type ListGroupApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
};
export type PostGroupApiResponse = /** status 201 Created */ ModelsGroup;
export type PostGroupApiArg = {
  /** Group */
  modelsGroupPostIn: ModelsGroupPostIn;
};
export type DeleteGroupApiResponse = /** status 204 No Content */ undefined;
export type DeleteGroupApiArg = {
  /** Group ID */
  id: number;
};
export type GetGroupApiResponse = /** status 200 OK */ ModelsGroup;
export type GetGroupApiArg = {
  /** Group ID */
  id: number;
};
export type PatchGroupApiResponse = /** status 200 OK */ ModelsGroup;
export type PatchGroupApiArg = {
  /** Group ID */
  id: number;
  /** Group */
  modelsGroupPatchIn: ModelsGroupPatchIn;
};
export type SetCareSubjectsOfGroupApiResponse = /** status 200 OK */ ModelsGroupPutCareSubjectsIn;
export type SetCareSubjectsOfGroupApiArg = {
  /** Group ID */
  id: number;
  /** CareSubjectIDs, passing empty array deletes all care subjects from the group */
  modelsGroupPutCareSubjectsIn: ModelsGroupPutCareSubjectsIn;
};
export type ListHistoryLogApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsHistoryLog;
export type ListHistoryLogApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
  action?: string[];
  careSubjectId?: number[];
  duration?: number;
  endAt?: number;
  entityId?: number[];
  staffId?: number[];
  tableName?: string[];
};
export type DeleteHistoryLogApiResponse = /** status 204 No Content */ undefined;
export type DeleteHistoryLogApiArg = {
  /** HistoryLog ID */
  id: number;
};
export type GetHistoryLogApiResponse = /** status 200 OK */ ModelsHistoryLog;
export type GetHistoryLogApiArg = {
  /** HistoryLog ID */
  id: number;
};
export type ListIntegrationAppApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsIntegrationApp;
export type ListIntegrationAppApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
};
export type GetIntegrationAppApiResponse = /** status 200 OK */ ModelsIntegrationApp;
export type GetIntegrationAppApiArg = {
  /** IntegrationApp ID */
  id: number;
};
export type ListIntegrationAppsDefinitionApiResponse =
  /** status 200 OK */ Helppad2AppApiModelsListOutModelsIntegrationAppsDefinition;
export type ListIntegrationAppsDefinitionApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
};
export type PostIntegrationAppsDefinitionApiResponse = /** status 201 Created */ ModelsIntegrationAppsDefinition;
export type PostIntegrationAppsDefinitionApiArg = {
  /** IntegrationAppsDefinition */
  modelsIntegrationAppsDefinitionPostIn: ModelsIntegrationAppsDefinitionPostIn;
};
export type DeleteIntegrationAppsDefinitionApiResponse = /** status 204 No Content */ undefined;
export type DeleteIntegrationAppsDefinitionApiArg = {
  /** IntegrationAppsDefinition ID */
  id: number;
};
export type GetIntegrationAppsDefinitionApiResponse = /** status 200 OK */ ModelsIntegrationAppsDefinition;
export type GetIntegrationAppsDefinitionApiArg = {
  /** IntegrationAppsDefinition ID */
  id: number;
};
export type PatchIntegrationAppsDefinitionApiResponse = /** status 200 OK */ ModelsIntegrationAppsDefinition;
export type PatchIntegrationAppsDefinitionApiArg = {
  /** IntegrationAppsDefinition ID */
  id: number;
  /** IntegrationAppsDefinition */
  modelsIntegrationAppsDefinitionPatchIn: ModelsIntegrationAppsDefinitionPatchIn;
};
export type ListIntegrationCareSubjectsApiResponse =
  /** status 200 OK */ Helppad2AppApiModelsListOutModelsIntegrationCareSubjects;
export type ListIntegrationCareSubjectsApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
};
export type PostIntegrationCareSubjectsApiResponse = /** status 201 Created */ ModelsIntegrationCareSubjects;
export type PostIntegrationCareSubjectsApiArg = {
  /** IntegrationCareSubjects */
  modelsIntegrationCareSubjectsPostIn: ModelsIntegrationCareSubjectsPostIn;
};
export type DeleteIntegrationCareSubjectsApiResponse = /** status 204 No Content */ undefined;
export type DeleteIntegrationCareSubjectsApiArg = {
  /** IntegrationCareSubjects ID */
  id: number;
};
export type GetIntegrationCareSubjectsApiResponse = /** status 200 OK */ ModelsIntegrationCareSubjects;
export type GetIntegrationCareSubjectsApiArg = {
  /** IntegrationCareSubjects ID */
  id: number;
};
export type PatchIntegrationCareSubjectsApiResponse = /** status 200 OK */ ModelsIntegrationCareSubjects;
export type PatchIntegrationCareSubjectsApiArg = {
  /** IntegrationCareSubjects ID */
  id: number;
  /** IntegrationCareSubjects */
  modelsIntegrationCareSubjectsPatchIn: ModelsIntegrationCareSubjectsPatchIn;
};
export type LoginApiResponse = /** status 200 OK */ ModelsLoginOut;
export type LoginApiArg = {
  /** Login Info */
  modelsLoginIn: ModelsLoginIn;
};
export type LogoutApiResponse = /** status 204 No Content */ undefined;
export type LogoutApiArg = {
  /** Device Token */
  deviceToken: string;
};
export type ListPermissionApiResponse = /** status 200 OK */ ModelsPermission[];
export type ListPermissionApiArg = void;
export type ListRolePermissionApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsRolePermission;
export type ListRolePermissionApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
};
export type GetRolePermissionApiResponse = /** status 200 OK */ ModelsRolePermission;
export type GetRolePermissionApiArg = {
  /** RolePermission ID */
  id: number;
};
export type ListRoleApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsRole;
export type ListRoleApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
};
export type GetRoleApiResponse = /** status 200 OK */ ModelsRole;
export type GetRoleApiArg = {
  /** Role ID */
  id: number;
};
export type ListStaffApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsStaff;
export type ListStaffApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
};
export type PostStaffApiResponse = /** status 201 Created */ ModelsStaff;
export type PostStaffApiArg = {
  /** Staff */
  modelsStaffPostIn: ModelsStaffPostIn;
};
export type DeleteStaffApiResponse = /** status 204 No Content */ undefined;
export type DeleteStaffApiArg = {
  /** Staff ID */
  id: number;
};
export type GetStaffApiResponse = /** status 200 OK */ ModelsStaff;
export type GetStaffApiArg = {
  /** Staff ID */
  id: number;
};
export type PatchStaffApiResponse = /** status 200 OK */ ModelsStaff;
export type PatchStaffApiArg = {
  /** Staff ID */
  id: number;
  /** Staff */
  modelsStaffPatchIn: ModelsStaffPatchIn;
};
export type FindNotificationTargetsByStaffIdApiResponse = /** status 200 OK */ ModelsStaffGetNotificationTargetsOut;
export type FindNotificationTargetsByStaffIdApiArg = {
  /** Staff ID */
  id: number;
};
export type SetNotificationTargetsByStaffIdApiResponse = /** status 200 OK */ ModelsStaffGetNotificationTargetsOut;
export type SetNotificationTargetsByStaffIdApiArg = {
  /** Staff ID */
  id: number;
  /** GroupIDs, passing empty array deletes all groups from the notification targets */
  modelsStaffPutNotificationTargetsIn: ModelsStaffPutNotificationTargetsIn;
};
export type DeleteStaffPhotoApiResponse = /** status 204 No Content */ undefined;
export type DeleteStaffPhotoApiArg = {
  /** Staff ID */
  id: number;
};
export type PutStaffPhotoApiResponse = /** status 200 OK */ HandlersPhotoPhotoUploadOut;
export type PutStaffPhotoApiArg = {
  /** Staff ID */
  id: number;
  body: {
    photo_file: Blob;
  };
};
export type ListTermsApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsTerms;
export type ListTermsApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
  duration?: number;
  endAt?: number;
};
export type GetTermsApiResponse = /** status 200 OK */ ModelsTerms;
export type GetTermsApiArg = {
  /** Terms ID */
  id: number;
};
export type ListTermsAgreementLogApiResponse = /** status 200 OK */ Helppad2AppApiModelsListOutModelsTermsAgreementLog;
export type ListTermsAgreementLogApiArg = {
  page?: number;
  size?: number;
  sortDesc?: boolean;
  sortKey?: string;
};
export type PostTermsAgreementLogApiResponse = /** status 201 Created */ ModelsTermsAgreementLog;
export type PostTermsAgreementLogApiArg = {
  /** TermsAgreementLog */
  modelsTermsAgreementLogPostIn: ModelsTermsAgreementLogPostIn;
};
export type DeleteTermsAgreementLogApiResponse = /** status 204 No Content */ undefined;
export type DeleteTermsAgreementLogApiArg = {
  /** TermsAgreementLog ID */
  id: number;
};
export type GetTermsAgreementLogApiResponse = /** status 200 OK */ ModelsTermsAgreementLog;
export type GetTermsAgreementLogApiArg = {
  /** TermsAgreementLog ID */
  id: number;
};
export type PatchTermsAgreementLogApiResponse = /** status 200 OK */ ModelsTermsAgreementLog;
export type PatchTermsAgreementLogApiArg = {
  /** TermsAgreementLog ID */
  id: number;
  /** TermsAgreementLog */
  modelsTermsAgreementLogPatchIn: ModelsTermsAgreementLogPatchIn;
};
export type ModelsAlertLog = {
  care_subject_id?: number;
  created_at?: string;
  detail?: number;
  device_id?: number;
  facility_id?: number;
  feces_alert_threshold?: number;
  feces_caution_threshold?: number;
  feces_count?: number;
  id?: number;
  updated_at?: string;
  urine_alert_threshold?: number;
  urine_caution_threshold?: number;
  urine_count?: number;
};
export type ModelsPager = {
  page?: number;
  size?: number;
  total_pages?: number;
  total_rows?: number;
};
export type Helppad2AppApiModelsListOutModelsAlertLog = {
  items?: ModelsAlertLog[];
  page_info?: ModelsPager;
};
export type ModelsErrorResponse = {
  message?: string;
};
export type ModelsGender = 0 | 1 | 2;
export type ModelsGroup = {
  created_at?: string;
  facility_id?: number;
  id?: number;
  name?: string;
  updated_at?: string;
};
export type ModelsPhoto = {
  created_at?: string;
  facility_id?: number;
  id?: number;
  updated_at?: string;
  url?: string;
};
export type ModelsCareSubject = {
  active_alert_log?: ModelsAlertLog;
  active_alert_log_id?: number;
  birth_date?: string;
  created_at?: string;
  description?: string;
  device_error?: number;
  device_id?: number;
  device_paired_at?: string;
  existed_at?: string;
  facility_id?: number;
  feces_alert_threshold?: number;
  feces_caution_threshold?: number;
  first_alerted_at?: string;
  gender?: ModelsGender;
  groups?: ModelsGroup[];
  id?: number;
  integration_count?: number;
  kana?: string;
  name?: string;
  photo?: ModelsPhoto;
  room_name?: string;
  updated_at?: string;
  urine_alert_threshold?: number;
  urine_caution_threshold?: number;
};
export type Helppad2AppApiModelsListOutModelsCareSubject = {
  items?: ModelsCareSubject[];
  page_info?: ModelsPager;
};
export type ModelsCareSubjectPostIn = {
  birth_date?: string;
  description?: string;
  feces_alert_threshold: number;
  feces_caution_threshold: number;
  gender?: ModelsGender;
  input_person?: string;
  kana?: string;
  name: string;
  room_name?: string;
  urine_alert_threshold: number;
  urine_caution_threshold: number;
};
export type ModelsCareSubjectPatchIn = {
  birth_date?: string;
  description?: string;
  feces_alert_threshold?: number;
  feces_caution_threshold?: number;
  gender?: ModelsGender;
  input_person?: string;
  kana?: string;
  name?: string;
  room_name?: string;
  urine_alert_threshold?: number;
  urine_caution_threshold?: number;
};
export type ModelsCommentLog = {
  created_at?: string;
  facility_id?: number;
  id?: number;
  input_person?: string;
  message?: string;
  staff_id?: number;
  thread_id?: number;
  updated_at?: string;
};
export type Helppad2AppApiModelsListOutModelsCommentLog = {
  items?: ModelsCommentLog[];
  page_info?: ModelsPager;
};
export type ModelsCareSubjectPatchDeviceIn = {
  device_id?: number;
};
export type ModelsCareSubjectPutGroupsIn = {
  group_ids?: number[];
};
export type ModelsIntegrationApp = {
  created_at?: string;
  facility_id?: number;
  id?: number;
  is_editable?: boolean;
  name?: string;
  updated_at?: string;
};
export type ModelsIntegrationCareSubjects = {
  care_subject_id?: number;
  created_at?: string;
  facility_id?: number;
  id?: number;
  integration_app?: ModelsIntegrationApp;
  integration_app_id?: number;
  integration_code?: string;
  updated_at?: string;
};
export type Helppad2AppApiModelsListOutModelsIntegrationCareSubjects = {
  items?: ModelsIntegrationCareSubjects[];
  page_info?: ModelsPager;
};
export type HandlersPhotoPhotoUploadOut = {
  url?: string;
};
export type ModelsCommentLogPostIn = {
  input_person: string;
  message: string;
  staff_id: number;
  thread_id: number;
};
export type ModelsCommentLogPatchIn = {
  message: string;
};
export type ModelsCommentThread = {
  care_subject_id?: number;
  created_at?: string;
  facility_id?: number;
  id?: number;
  memo?: string;
  title?: string;
  updated_at?: string;
};
export type Helppad2AppApiModelsListOutModelsCommentThread = {
  items?: ModelsCommentThread[];
  page_info?: ModelsPager;
};
export type ModelsCommentThreadPostIn = {
  care_subject_id: number;
  memo?: string;
  title: string;
};
export type ModelsCommentThreadPatchIn = {
  memo?: string;
  title: string;
};
export type ModelsDetectionLog = {
  care_subject_id?: number;
  created_at?: string;
  detect_detail?: number;
  device_id?: number;
  facility_id?: number;
  id?: number;
  updated_at?: string;
};
export type Helppad2AppApiModelsListOutModelsDetectionLog = {
  items?: ModelsDetectionLog[];
  page_info?: ModelsPager;
};
export type ModelsDevice = {
  created_at?: string;
  facility_id?: number;
  id?: number;
  sensor_id?: string;
  sim_number?: string;
  updated_at?: string;
};
export type Helppad2AppApiModelsListOutModelsDevice = {
  items?: ModelsDevice[];
  page_info?: ModelsPager;
};
export type ModelsExcretionResult = {
  alert_log?: ModelsAlertLog;
  alert_log_id?: number;
  care_subject_id?: number;
  created_at?: string;
  device_id?: number;
  excretion_place?: number;
  excretion_type?: number;
  facility_id?: number;
  feces_amount?: number;
  feces_firmness?: number;
  first_alerted_at?: string;
  id?: number;
  input_person?: string;
  is_leaked?: boolean;
  karte?: string;
  staff_id?: number;
  supported_at?: string;
  supported_place?: number;
  updated_at?: string;
  urine_amount?: number;
};
export type Helppad2AppApiModelsListOutModelsExcretionResult = {
  items?: ModelsExcretionResult[];
  page_info?: ModelsPager;
};
export type ModelsExcretionResultPostIn = {
  alert_log_id?: number;
  care_subject_id: number;
  device_id?: number;
  excretion_place?: number;
  excretion_type?: number;
  feces_amount?: number;
  feces_firmness?: number;
  input_person: string;
  is_leaked?: boolean;
  karte?: string;
  staff_id: number;
  supported_at: string;
  supported_place?: number;
  urine_amount?: number;
};
export type ModelsExcretionResultPatchIn = {
  created_at?: string;
  excretion_place?: number;
  excretion_type?: number;
  feces_amount?: number;
  feces_firmness?: number;
  input_person?: string;
  is_leaked?: boolean;
  karte?: string;
  staff_id?: number;
  supported_at?: string;
  supported_place?: number;
  urine_amount?: number;
};
export type ModelsFacility = {
  address?: string;
  alert_expiration_term?: number;
  created_at?: string;
  detection_expiration_term?: number;
  email?: string;
  id?: number;
  login_id?: string;
  name?: string;
  phone_number?: string;
  postal_code?: string;
  updated_at?: string;
};
export type ModelsGroupSubject = {
  care_subject_id?: number;
  created_at?: string;
  facility_id?: number;
  group_id?: number;
  id?: number;
  updated_at?: string;
};
export type Helppad2AppApiModelsListOutModelsGroupSubject = {
  items?: ModelsGroupSubject[];
  page_info?: ModelsPager;
};
export type ModelsGroupSubjectPostIn = {
  care_subject_id: number;
  group_id: number;
};
export type Helppad2AppApiModelsListOutModelsGroup = {
  items?: ModelsGroup[];
  page_info?: ModelsPager;
};
export type ModelsGroupPostIn = {
  name: string;
};
export type ModelsGroupPatchIn = {
  name?: string;
};
export type ModelsGroupPutCareSubjectsIn = {
  care_subject_ids?: number[];
};
export type ModelsHistoryLog = {
  action?: string;
  care_subject_id?: number;
  changes?: string;
  created_at?: string;
  entity_id?: number;
  facility_id?: number;
  id?: number;
  input_person?: string;
  staff_id?: number;
  table_name?: string;
  updated_at?: string;
};
export type Helppad2AppApiModelsListOutModelsHistoryLog = {
  items?: ModelsHistoryLog[];
  page_info?: ModelsPager;
};
export type Helppad2AppApiModelsListOutModelsIntegrationApp = {
  items?: ModelsIntegrationApp[];
  page_info?: ModelsPager;
};
export type ModelsIntegrationAppsDefinition = {
  created_at?: string;
  data_type?: string;
  facility_id?: number;
  id?: number;
  integration_app?: ModelsIntegrationApp;
  integration_app_id?: number;
  name?: string;
  status?: string;
  updated_at?: string;
  value?: string;
};
export type Helppad2AppApiModelsListOutModelsIntegrationAppsDefinition = {
  items?: ModelsIntegrationAppsDefinition[];
  page_info?: ModelsPager;
};
export type ModelsIntegrationAppsDefinitionPostIn = {
  data_type: string;
  integration_app_id: number;
  name: string;
  value: string;
};
export type ModelsIntegrationAppsDefinitionPatchIn = {
  data_type?: string;
  integration_app_id?: number;
  name?: string;
  value?: string;
};
export type ModelsIntegrationCareSubjectsPostIn = {
  care_subject_id: number;
  integration_app_id: number;
  integration_code: string;
};
export type ModelsIntegrationCareSubjectsPatchIn = {
  care_subject_id: number;
  integration_app_id: number;
  integration_code: string;
};
export type ModelsLoginOut = {
  facility_id?: number;
  staff_id?: number;
  token?: string;
};
export type ModelsLoginIn = {
  device_token?: string;
  facility_login_id: string;
  language?: string;
  staff_login_id: string;
  staff_password: string;
};
export type ModelsPermission = {
  created_at?: string;
  display_name?: string;
  id?: number;
  name?: string;
  updated_at?: string;
};
export type ModelsRolePermission = {
  created_at?: string;
  facility_id?: number;
  id?: number;
  permission_id?: number;
  role_id?: number;
  updated_at?: string;
};
export type Helppad2AppApiModelsListOutModelsRolePermission = {
  items?: ModelsRolePermission[];
  page_info?: ModelsPager;
};
export type ModelsRole = {
  created_at?: string;
  facility_id?: number;
  id?: number;
  is_master?: boolean;
  name?: string;
  updated_at?: string;
};
export type Helppad2AppApiModelsListOutModelsRole = {
  items?: ModelsRole[];
  page_info?: ModelsPager;
};
export type ModelsStaff = {
  created_at?: string;
  display_name?: string;
  facility_id?: number;
  id?: number;
  login_id?: string;
  name?: string;
  photo?: ModelsPhoto;
  role?: ModelsRole;
  role_id?: number;
  show_alert?: boolean;
  updated_at?: string;
};
export type Helppad2AppApiModelsListOutModelsStaff = {
  items?: ModelsStaff[];
  page_info?: ModelsPager;
};
export type ModelsStaffPostIn = {
  display_name: string;
  login_id: string;
  name: string;
  password: string;
  role_id: number;
  show_alert?: boolean;
};
export type ModelsStaffPatchIn = {
  display_name?: string;
  name?: string;
  password?: string;
  role_id?: number;
  show_alert?: boolean;
};
export type ModelsStaffGetNotificationTargetsOut = {
  group_ids?: number[];
};
export type ModelsStaffPutNotificationTargetsIn = {
  group_ids?: number[];
};
export type ModelsTerms = {
  created_at?: string;
  facility_id?: number;
  filepath?: string;
  id?: number;
  info?: string;
  terms_created_at?: string;
  updated_at?: string;
};
export type Helppad2AppApiModelsListOutModelsTerms = {
  items?: ModelsTerms[];
  page_info?: ModelsPager;
};
export type ModelsTermsAgreementLog = {
  created_at?: string;
  facility_id?: number;
  id?: number;
  staff_id?: number;
  terms_id?: number;
  updated_at?: string;
};
export type Helppad2AppApiModelsListOutModelsTermsAgreementLog = {
  items?: ModelsTermsAgreementLog[];
  page_info?: ModelsPager;
};
export type ModelsTermsAgreementLogPostIn = {
  staff_id: number;
  terms_id: number;
};
export type ModelsTermsAgreementLogPatchIn = {
  staff_id: number;
  terms_id: number;
};
export const {
  useAggregationQuery,
  useListAlertLogQuery,
  useGetAlertLogQuery,
  useListCareSubjectQuery,
  usePostCareSubjectMutation,
  useDeleteCareSubjectMutation,
  useGetCareSubjectQuery,
  usePatchCareSubjectMutation,
  useFindCommentLogsByCareSubjectIdQuery,
  useUpdateDeviceOfCareSubjectMutation,
  useUpdateGroupsOfCareSubjectMutation,
  useSetGroupsOfCareSubjectMutation,
  useFindIntegrationCareSubjectsByCareSubjectIdQuery,
  useDeleteCareSubjectPhotoMutation,
  usePutCareSubjectPhotoMutation,
  useListCommentLogQuery,
  usePostCommentLogMutation,
  useDeleteCommentLogMutation,
  useGetCommentLogQuery,
  usePatchCommentLogMutation,
  useListCommentThreadQuery,
  usePostCommentThreadMutation,
  useDeleteCommentThreadMutation,
  useGetCommentThreadQuery,
  usePatchCommentThreadMutation,
  useListDetectionLogQuery,
  useGetDetectionLogQuery,
  useListDeviceQuery,
  useGetDeviceQuery,
  useListExcretionResultQuery,
  usePostExcretionResultMutation,
  useDeleteExcretionResultMutation,
  useGetExcretionResultQuery,
  usePatchExcretionResultMutation,
  useGetFacilityQuery,
  useListGroupSubjectQuery,
  usePostGroupSubjectMutation,
  useDeleteGroupSubjectMutation,
  useGetGroupSubjectQuery,
  useListGroupQuery,
  usePostGroupMutation,
  useDeleteGroupMutation,
  useGetGroupQuery,
  usePatchGroupMutation,
  useSetCareSubjectsOfGroupMutation,
  useListHistoryLogQuery,
  useDeleteHistoryLogMutation,
  useGetHistoryLogQuery,
  useListIntegrationAppQuery,
  useGetIntegrationAppQuery,
  useListIntegrationAppsDefinitionQuery,
  usePostIntegrationAppsDefinitionMutation,
  useDeleteIntegrationAppsDefinitionMutation,
  useGetIntegrationAppsDefinitionQuery,
  usePatchIntegrationAppsDefinitionMutation,
  useListIntegrationCareSubjectsQuery,
  usePostIntegrationCareSubjectsMutation,
  useDeleteIntegrationCareSubjectsMutation,
  useGetIntegrationCareSubjectsQuery,
  usePatchIntegrationCareSubjectsMutation,
  useLoginMutation,
  useLogoutMutation,
  useListPermissionQuery,
  useListRolePermissionQuery,
  useGetRolePermissionQuery,
  useListRoleQuery,
  useGetRoleQuery,
  useListStaffQuery,
  usePostStaffMutation,
  useDeleteStaffMutation,
  useGetStaffQuery,
  usePatchStaffMutation,
  useFindNotificationTargetsByStaffIdQuery,
  useSetNotificationTargetsByStaffIdMutation,
  useDeleteStaffPhotoMutation,
  usePutStaffPhotoMutation,
  useListTermsQuery,
  useGetTermsQuery,
  useListTermsAgreementLogQuery,
  usePostTermsAgreementLogMutation,
  useDeleteTermsAgreementLogMutation,
  useGetTermsAgreementLogQuery,
  usePatchTermsAgreementLogMutation,
} = injectedRtkApi;
