import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from "@mui/material";
import { styled } from "@mui/system";
import { ReactNode, useMemo } from "react";
import { FilterValue } from "./Toolbar";
import { useNavigate, useLocation } from "react-router-dom";
import { UNASSIGNED_GROUP, UNASSIGNED_GROUP_ID, groupColor } from "src/constants/groupColor";
import { Constants } from "src/constants/commonConstants";
import { useListCareSubjectQuery, ModelsCareSubject, ModelsGroup } from "src/store/helppadApi";
import { formatDate, parseFirstAlertedAt, toTimeDiffString } from "src/utils/dateUtil";
import { LatestCommentLog } from "./LatestCommentLog";
import { toCareSubjectPageLink } from "../CareSubject/toCareSubjectPageLink";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";

type GroupedCareSubject = {
  group: ModelsGroup;
  careSubject: ModelsCareSubject;
};

const columns: {
  i18nKey: string;
  align?: "left";
  colSpan?: number;
  overflowEllipsis?: boolean;
  backgroundColor?: (cs: GroupedCareSubject) => string | undefined;
  selector: (cs: GroupedCareSubject, t: TFunction) => ReactNode | ReactNode[];
}[] = [
  {
    i18nKey: "common.group.group",
    align: "left",
    backgroundColor: (cs) => (cs.group ? groupColor(cs.group.id!) : undefined),
    selector: (cs, t) => (cs.group?.id === UNASSIGNED_GROUP_ID ? t("common.group.unassigned") : cs.group?.name),
  },
  {
    i18nKey: "common.care_subject.room_name",
    align: "left",
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs, _) => cs.careSubject.room_name,
  },
  {
    i18nKey: "common.care_subject.care_subject_name",
    align: "left",
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs, _) => cs.careSubject.name,
  },
  {
    i18nKey: "common.care_subject.age",
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs, _) => new Date().getFullYear() - new Date(cs.careSubject.birth_date!).getFullYear(),
  },
  {
    i18nKey: "common.care_subject.gender",
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs, t) =>
      cs.careSubject.gender === 1
        ? t("common.care_subject.male")
        : cs.careSubject.gender === 2
        ? t("common.care_subject.female")
        : t("common.care_subject.other"),
  },
  {
    i18nKey: "care_subjects.care_subjects_table.alert",
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs, _) => {
      if (!cs.careSubject.first_alerted_at) {
        return "-";
      }
      return formatDate(cs.careSubject.first_alerted_at as string);
    },
  },
  {
    i18nKey: "care_subjects.care_subjects_table.elapsed_time",
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs, t) => toTimeDiffString(new Date(), parseFirstAlertedAt(cs.careSubject.first_alerted_at), t),
  },
  {
    i18nKey: "care_subjects.care_subjects_table.detect_count",
    colSpan: 2,
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs, t) => {
      if (!cs.careSubject.active_alert_log) {
        return [`${t("common.excretion.urine")} 0`, `${t("common.excretion.feces")} 0`];
      }
      return [
        `${t("common.excretion.urine")} ${cs.careSubject.active_alert_log.urine_count}`,
        `${t("common.excretion.feces")} ${cs.careSubject.active_alert_log.feces_count}`,
      ];
    },
  },
  {
    i18nKey: "care_subjects.care_subjects_table.latest_comment",
    align: "left",
    overflowEllipsis: true,
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs, _) => {
      return <LatestCommentLog id={cs.careSubject.id!} />;
    },
  },
  {
    i18nKey: "care_subjects.care_subjects_table.view_error",
    backgroundColor: (cs) => rowColor(cs),
    selector: (cs, _) => {
      return cs.careSubject.device_error !== 0 ? cs.careSubject.device_error : "";
    },
  },
];

type Props = {
  filter: FilterValue;
};

export const CareSubjectsTable = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const fromPath = location.pathname.slice(1);
  const careSubjects = useListCareSubjectQuery({}, Constants.POLLING_INTERVAL).data?.items;
  const groupedCareSubjects = useMemo(() => {
    if (careSubjects == null) {
      return;
    }

    return (
      careSubjects
        .flatMap((careSubject) => {
          const { groups } = careSubject;
          if (groups == null) {
            return [];
          }

          if (groups.length === 0) {
            // 絞り込みが1つでも有効なら未所属の入居者は表示しない
            if (props.filter.group.length > 0) {
              return [];
            }
            // グループに1つも属していないのなら「未所属」というグループに属させる
            return { careSubject, group: UNASSIGNED_GROUP };
          }

          return groups
            .filter((group) => {
              const isGroupIncluded = props.filter.group.length === 0 || props.filter.group.includes(group.id!);
              const hasActiveAlertLog =
                careSubject.active_alert_log?.detail === 1 ||
                careSubject.active_alert_log?.detail === 2 ||
                careSubject.device_error;
              return isGroupIncluded && (!props.filter.alertOnly || hasActiveAlertLog);
            })
            .map((group) => {
              return { careSubject, group };
            });
        })
        .sort((a, b) =>
          a.careSubject.room_name === b.careSubject.room_name
            ? 0
            : a.careSubject.room_name! > b.careSubject.room_name!
            ? 1
            : -1
        )
        .sort((a, b) =>
          a.group.id === UNASSIGNED_GROUP_ID
            ? 1
            : b.group.id === UNASSIGNED_GROUP_ID
            ? -1
            : (a.group.id ?? 0) - (b.group.id ?? 0)
        ) || []
    );
  }, [careSubjects, props.filter.alertOnly, props.filter.group]);

  return (
    <TableContainer component={Paper} sx={{ minWidth: "940px" }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell
                key={column.i18nKey}
                colSpan={column.colSpan || 1}
                align="center"
                sx={{
                  whiteSpace: "nowrap",
                  [`&.${tableCellClasses.head}`]: {
                    backgroundColor: "#606060",
                    color: "#fff",
                    padding: "12px 16px",
                  },
                  "&:not(:first-of-type)": {
                    borderLeft: "solid 1px #fff",
                  },
                }}
              >
                {t(column.i18nKey)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {groupedCareSubjects?.map((item) => (
            <StyledTableRow
              key={`${item?.careSubject?.id}-${item?.group?.id}`}
              sx={{ height: "50px" }}
              onClick={() => {
                navigate(toCareSubjectPageLink(item?.careSubject?.id, item?.group?.id, fromPath));
              }}
            >
              {columns.flatMap((column) => {
                const content = column.selector(item!, t);
                return (Array.isArray(content) ? content : [content]).map((c, i) => (
                  <TableCell
                    align={column.align || "center"}
                    key={column.i18nKey + i}
                    sx={{
                      "&": {
                        padding: "12px 16px",
                      },
                      whiteSpace: "nowrap",
                      "&:not(:first-of-type)": {
                        borderLeft: "solid 1px #d9d9d9",
                      },
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      ...(column.overflowEllipsis ? { maxWidth: 0 } : { width: "1px" }),
                      ...(column.backgroundColor ? { backgroundColor: column.backgroundColor(item!) } : {}),
                    }}
                  >
                    {c}
                  </TableCell>
                ));
              })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const StyledTableRow = styled(TableRow)`
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`;

const rowColor = (cs: GroupedCareSubject) => {
  switch (cs.careSubject.active_alert_log?.detail) {
    case 1:
      return "#FCFFDD";
    case 2:
      return "#FFF0F0";
    default:
      return "#FFFFFF";
  }
};
