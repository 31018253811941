import { useCallback, useMemo, useState } from "react";
import { IntegrationAppDialogParams } from "./InputIntegrationAppDataDialog";
import { CommonDialog } from "../CommonDialog";
import { Alert, Grid } from "@mui/material";
import { IntegrationAppDataTable } from "./IntegrationAppDataTable";
import { useDeleteIntegrationAppsDefinitionMutation } from "src/store/enhancedApi";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  status: RequestStatus;
  error?: Error;
  onDelete: () => void;
  onCancel: () => void;
  params: IntegrationAppDialogParams | undefined;
};

type RequestStatus = "idling" | "processing" | "finished";

export const useIntegrationAppDeleteDialog = () => {
  const [deleteApi] = useDeleteIntegrationAppsDefinitionMutation();
  const [open, setOpen] = useState(false);
  const [params, setParams] = useState<(IntegrationAppDialogParams & { id: number }) | undefined>();

  const [requestState, setRequestState] = useState<{ processing: Boolean; finished: boolean; error?: Error }>({
    processing: false,
    finished: false,
  });

  const status: RequestStatus = useMemo(() => {
    if (requestState.processing) {
      return "processing";
    }
    if (requestState.finished) {
      return "finished";
    }
    return "idling";
  }, [requestState]);

  const handleDelete = useCallback(async () => {
    if (params == null) {
      return;
    }

    setRequestState((state) => ({ ...state, processing: true }));
    try {
      await deleteApi({ id: params.id });
      setRequestState((state) => ({ ...state, processing: false, finished: true, error: undefined }));
    } catch (error: unknown) {
      setRequestState((state) => ({
        ...state,
        processing: false,
        error: error instanceof Error ? error : new Error("Unknown error."),
      }));
    }
  }, [params, deleteApi]);

  const showDialog = useCallback((id: number, params: IntegrationAppDialogParams) => {
    setParams({ ...params, id });
    setRequestState({ processing: false, finished: false });
    setOpen(true);
  }, []);

  const hideDialog = useCallback(() => {
    setOpen(false);
  }, []);

  return {
    open,
    status,
    error: requestState.error,
    onDelete: handleDelete,
    showDialog,
    hideDialog,
    params,
  };
};

export const IntegrationAppDeleteDialog = (props: Props) => {
  const { t } = useTranslation();

  return (
    <CommonDialog
      dialogOpen={props.open}
      title={t("module.integration_app.delete_integration_app")}
      acceptButtonName={t("common.button.delete")}
      onAccept={props.onDelete}
      cancelButtonName={t("common.button.cancel")}
      onCancel={props.onCancel}
      isChecked={true}
      isProcessed={props.status === "processing"}
      isCompleted={props.status === "finished"}
      completeMessage={t("module.integration_app.deleted_integration_app")}
    >
      <Grid p={3}>
        <Grid mb={2}>{t("module.integration_app.confirm_deletion_integration")}</Grid>
        <Grid p={3} sx={{ border: "1px solid #D9D9D9", borderRadius: "0.5rem" }}>
          {props.params != null && (
            <IntegrationAppDataTable
              name={props.params.name}
              systemType={props.params.systemType}
              dataType={props.params.dataType}
            />
          )}
        </Grid>
      </Grid>
      {props.error && (
        <Alert sx={{ mt: 2 }} severity="error">
          {props.error.message}
        </Alert>
      )}
    </CommonDialog>
  );
};
