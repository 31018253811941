import { Box, DialogContent, TextField } from "@mui/material";
import { ChangeEvent, useCallback, useRef, useState, Fragment } from "react";
import { CommonDialog } from "./CommonDialog";
import { Constants } from "src/constants/commonConstants";
import { DataRow } from "./parts/DataRow";
import { useTranslation } from "react-i18next";

interface Memo {
  commentId: number;
  userName: string; // ユーザ名
  message: string; // メモ文言
}

type EditDealingDialogProps = {
  memo: Memo | null;
  onChangeMemoText: (next: string) => void; // メモ文言変更時
  onConfirm: () => void; // 変更するボタンが押された際の振る舞い
  onCancel: () => void; // 変更するボタンが押されずに閉じられた際の振る舞い(≒ キャンセル)
  registering: boolean; // 登録処理中か
  registered: boolean; // 登録処理が完了したか
  error?: Error; // 登録処理で発生したエラー
};

export const useChangeMemoDialog = (
  onSaveSucceed?: () => void,
  updateCommentLog?: (args: { id: number; message: string }) => void
) => {
  const [memo, setMemo] = useState<Memo | null>(null);
  const [registering, setRegistering] = useState(false);
  const [registered, setRegistered] = useState(false);

  const showDialog = useCallback((commentId: number, userName: string, message: string) => {
    setMemo({
      commentId,
      userName,
      message,
    });
  }, []);

  const hideDialog = useCallback(() => {
    setMemo(null);
    setRegistered(false);
  }, []);

  const onChangeMemoText = useCallback(
    (text: string) => {
      if (!memo) throw new Error("never");
      setMemo({
        ...memo,
        message: text,
      });
    },
    [memo]
  );

  const saveChangeMemo = useCallback(async () => {
    if (!memo) throw new Error("never");
    setRegistering(true);
    updateCommentLog && updateCommentLog({ id: memo.commentId, message: memo.message });
    setRegistering(false);
    setRegistered(true);
    onSaveSucceed && (await onSaveSucceed());
  }, [memo, onSaveSucceed, updateCommentLog]);

  return {
    memo,
    registering,
    registered,
    showDialog,
    hideDialog,
    onChangeMemoText,
    saveChangeMemo,
  };
};

/**
 * メモの変更ダイアログ
 *
 * memoText の長さが最大文字数を上回るときは「変更する」ボタンは disable にします。
 */
export const ChangeMemoDialog = ({
  memo,
  onChangeMemoText,
  onConfirm,
  onCancel,
  registering,
  registered,
}: EditDealingDialogProps) => {
  const { t } = useTranslation();

  const handleChangeMemoText = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChangeMemoText(event.target.value);
    },
    [onChangeMemoText]
  );

  // 自動的にダイアログ表示後に入力欄にフォーカスを当ててカーソルを末尾に移動させる(TODO: 不要なら消す)
  const textFieldRef = useRef<HTMLInputElement>(null);
  const handleAnimationEnd = useCallback(() => {
    const { current } = textFieldRef;
    if (current == null) {
      return;
    }
    current.focus();
    current.selectionStart = current.value.length;
    current.selectionEnd = current.value.length;
  }, [textFieldRef]);

  if (!memo) return null;

  const { userName, message } = memo;

  return (
    <CommonDialog
      dialogOpen={!!memo}
      dialogAnimationEnd={handleAnimationEnd}
      title={t("module.memo.edit_memo")}
      acceptButtonName={t("common.button.change")}
      cancelButtonName={t("common.button.cancel")}
      onAccept={onConfirm}
      onCancel={onCancel}
      isChecked={!(message.length > Constants.MAX_MEMO_LENGTH)}
      isProcessed={registering}
      isCompleted={registered}
      completeContent={<ChangeMemoComplete userName={userName} message={message} />}
      completeMessage={t("module.memo.edited_memo")}
    >
      <DialogContent>
        <Box>
          {/* ユーザ名(変更不可) */}
          <Box>
            {t("module.memo.staff_name")}：{userName}
          </Box>
          {/* メモの内容 */}
          <Box>
            <TextField
              sx={{ width: "100%" }}
              inputRef={textFieldRef}
              autoFocus={true}
              variant={"outlined"}
              multiline={true}
              rows={5}
              value={message}
              onChange={handleChangeMemoText}
            />
          </Box>
          {/* 入力されてる文字の長さ(TODO: デザイン次第だが、TextField内に持っていく可能性がある) */}
          <Box sx={{ textAlign: "right", color: message.length > Constants.MAX_MEMO_LENGTH ? "red" : undefined }}>
            {message.length}/{Constants.MAX_MEMO_LENGTH}
          </Box>
        </Box>
      </DialogContent>
    </CommonDialog>
  );
};

const ChangeMemoComplete = (props: { userName: string; message: string }) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <DataRow label={t("module.memo.staff_name")} value={props.userName} />
      <DataRow label={t("module.memo.memo")} value={props.message} />
    </Fragment>
  );
};
